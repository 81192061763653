import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import { Link } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";

const tableaudio = {
  width: "140%",
  height: "50px",
  borderRadius: "5px",
  // backgroundColor: "#f0f0f0", 
  padding: "4px",
};

const NotCallClassification = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [callData, setCallData] = useState([])
  const [filterSerchName, setFilterSerchName] = useState([])
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false)



  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  // const handleSelectChange = (event) => {
  //   setSelectedRows(event.target.value);
  //   console.log(`Selected number of rows: ${event.target.value}`);
  //   // You can add additional logic to handle the row change
  // };

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };
  // show button end

  const getCallCallasification = () => {
    setLoading(true)
    simpleGetCallWithToken(ApiConfig.GET_CALL_CLASSIFICATION)
      .then((res) => {
        console.log("Call List Data>>>", res)
        setCallData(res?.register_user)
        setLoading(false)

      })
      .catch((err) => {
        setLoading(false)

        console.log("call List Data>>>", err)
      })
  }


  useEffect(() => {
    getCallCallasification()
  }, [])

  useEffect(() => {
    // Otherwise, use location_list

    const filteredData = callData?.filter((item) =>
      item?.caller_name?.toLowerCase().includes(searchValue.toLowerCase())
    );

    console.log("Filtered Data>>", filteredData);
    setFilterSerchName(filteredData); // Set the filtered data in the state

  }, [callData, searchValue,]);

  const crmInboundCallsData = [
    {
      name: "+919309482204",
      callDate: "05-06-2024",
      callStartTime: "4:40 p.m.",
      callEndTime: "4:40 p.m.",
      agentName: "---",
      callStatus: "Not Answered",
      callRecording: "",
    },
    {
      name: "+918765432109",
      callDate: "04-06-2024",
      callStartTime: "3:00 p.m.",
      callEndTime: "3:05 p.m.",
      agentName: "Agent 2",
      callStatus: "Answered",
      callRecording: "",
    },
    {
      name: "+917654321098",
      callDate: "03-06-2024",
      callStartTime: "1:30 p.m.",
      callEndTime: "1:40 p.m.",
      agentName: "Agent 3",
      callStatus: "Answered",
      callRecording: "",
    },
    {
      name: "+916543210987",
      callDate: "03-06-2024",
      callStartTime: "11:00 a.m.",
      callEndTime: "11:10 a.m.",
      agentName: "Agent 4",
      callStatus: "Missed",
      callRecording: "",
    },
    {
      name: "+915432109876",
      callDate: "02-06-2024",
      callStartTime: "10:00 a.m.",
      callEndTime: "10:05 a.m.",
      agentName: "Agent 5",
      callStatus: "Answered",
      callRecording: "",
    },
    {
      name: "+914321098765",
      callDate: "02-06-2024",
      callStartTime: "9:45 a.m.",
      callEndTime: "9:50 a.m.",
      agentName: "Agent 6",
      callStatus: "Answered",
      callRecording: "",
    },
    {
      name: "+913210987654",
      callDate: "01-06-2024",
      callStartTime: "5:00 p.m.",
      callEndTime: "5:00 p.m.",
      agentName: "---",
      callStatus: "Not Answered",
      callRecording: "",
    },
    {
      name: "+912109876543",
      callDate: "01-06-2024",
      callStartTime: "4:30 p.m.",
      callEndTime: "4:32 p.m.",
      agentName: "---",
      callStatus: "Not Answered",
      callRecording: "",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Not Call Classification</h6>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text"
                placeholder="Search here"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setCurrentPage(0);
                }}
              />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setUsersPerPage(e.target.value);
                  setCurrentPage(0);
                }}
              >
                <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25" selected>
                    25
                  </option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="booksIcon" style={{ textAlign: "center" }}>
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">Name / Phone Number</th>
                <th scope="col">Call Date</th>
                <th scope="col">Call Start Time</th>
                <th scope="col">Call End Time</th>
                <th scope="col">Agent Name</th>
                <th scope="col">Call Status</th>
                <th scope="col">MAKE CALL</th>
                <th scope="col">Call Recording</th>
                <th scope="col">Add User</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <Loader />
                  </td>
                </tr>
              ) : (
                filterSerchName.length > 0 &&
                filterSerchName?.slice(startIndex, endIndex)?.map((data, index) => (
                  <tr key={index} className="table-row-custom">
                    <td>{data.caller_name}</td>
                    <td>{data.call_date}</td>
                    <td>{data.call_start_time}</td>
                    <td>{data.call_end_time}</td>
                    <td>{data.agentName}</td>
                    <td>{data.call_status}</td>
                    <td>
                      <button className="custoBtnForCrmCall">Make Call</button>
                    </td>
                    <td style={{ marginRight: "10px" }}>
                      {data.call_recording_url ? (
                        <audio controls style={tableaudio}>
                          <source src={data.call_recording_url} type="audio/mpeg" />
                        </audio>
                      ) : (
                        "Not Found"
                      )}
                    </td>
                    <td>
                      <button className="custoBtnForCrmCall">
                        <Link to="/RegisterUserDetails" className="custoBtnForCrmCallLink">
                          User Register
                        </Link>
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>

          </table>
          <Pagination
            data={callData} 
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={filterSerchName}
          />
        </div>
      </div>
    </div>
  );
};

export default NotCallClassification;
