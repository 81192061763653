import ApiConfig from "../api/ApiConfig";
import { multipartPostCall } from "../api/ApiServices";



const textField = ['text', 'number', 'email', 'password', 'textarea']
function DynamicField(props) {


    const {
        type = 'text',
        label,
        placeholder,
        required,
        keyName,
        value = '',
        handleAddTask,
        taskId,
        isValided,
        validations
    } = props

    console.log(keyName);


    const uplaodFile = (file) => {

        console.log(file);

        const formData = new FormData();
        formData.append("Files", file);

        multipartPostCall(ApiConfig.UPLOAD_FILE + taskId + '/upload', formData).then((res) => {
            console.log(res?.data);
            handleAddTask(keyName, res?.data[0]?.filePath)
        })

    }



    return <div className="mt-2 mb-2">
        {
            textField.includes(type) && <div>
                <label htmlFor="" className="text-grey">
                    {label}
                </label>
                <input
                    placeholder={placeholder}
                    type={type}
                    className="form-control tasKCategory1"
                    required={required}
                    value={value}
                    onChange={(e) => handleAddTask(keyName, e.target.value)}
                />
                <p className="text-danger">{(required === 'Required' && isValided) && validations[keyName + '']}</p>
            </div>

        }

        {
            type == 'datepicker' && <div>
                <label htmlFor="" className="text-grey">
                    {label}
                </label>
                <input
                    placeholder={placeholder}
                    type={'date'}
                    className="form-control tasKCategory1"
                    required={required}
                    value={value}
                    onChange={(e) => handleAddTask(keyName, e.target.value)}
                />
                <p className="text-danger">{(required === 'Required' && isValided) && validations[keyName + '']}</p>
            </div>
        }



        {
            type == 'checkbox' && <div>
                <input type="checkbox"
                // checked={actionData?.isReasonRequired}
                // onChange={(e) => handleChangeAction('isReasonRequired', e.target.checked)}
                />
                <label htmlFor="" className="text-grey me-5 ml-1">
                    Is Reason Required ?
                </label>
            </div>
        }

        {
            type == 'selection' && <div>
                <label htmlFor="" className="text-grey">
                    {label}
                </label>
                <select
                    class="form-select tasKCategory1"
                    aria-label="Default select example"

                >
                    <option selected value={''} disabled>Select Step Status</option>

                </select>
            </div>
        }

        {
            type == 'attachment' &&
            <div >
                <label htmlFor="">{label}</label> <br />
                <input type="file"
                    style={{ marginTop: '3px' }}
                    onChange={(e) => uplaodFile(e.target.files[0])}
                />
                <p className="text-danger">{(required === 'Required' && isValided) && validations[keyName + '']}</p>
            </div>
        }

        {
            (type == 'approve' || type == 'reject' || type == 'assign') && <div style={{ marginTop: '30px' }}> <button
                className={type == 'reject' ? 'btn-red' : 'btn-green'}
                style={{ width: '100%' }}
            >{label}</button>
            </div>
        }


        {/* 
        <p className="text-danger">{isAddActionValided && !actionData?.name && 'Please Add Action Name'}</p> */}
    </div>

}

export default DynamicField