import React, { useContext, useState, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import Nav from "react-bootstrap/Nav";
import { Avatar } from "primereact/avatar";
import { Button, Dropdown } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import { json, Link } from "react-router-dom";
import ic_filter_chat from "../../assets/images/chat/ic_filter_chat.svg";
import ic_plus_msg from "../../assets/images/chat/ic_plus_msg.svg";
import ic_dropdown from "../../assets/images/chat/ic_dropdown.svg";
import profile_img from "../../assets/images/profile_img.svg";
import Beatloader from "../../sharedComponent/Beatloader";
import user_pic2 from "../../assets/images/profile_img.svg";
import "../../assets/css/main.scss";
import sidebar from "../../sharedComponent/Sidebar";
import ic_online from "../../assets/images/chat/ic_online.svg";
import ic_busy from "../../assets/images/chat/ic_busy.svg";
import ic_gray from "../../assets/images/chat/ic_gray.svg";
import ic_away from "../../assets/images/chat/ic_away.svg";
import ic_come_back from "../../assets/images/chat/ic_come_back.svg";
import ic_on_call from "../../assets/images/chat/ic_on_call.svg";
import ic_minus_red from "../../assets/images/chat/ic_minus_red.svg";
import ic_offline from "../../assets/images/chat/ic_offline.svg";
import arrow_right from "../../assets/images/chat/arrow_right.svg";
import Accordion from "react-bootstrap/Accordion";
import ChatBox from "./ChatBox";
import EmptyChatBox from "./EmptyChatBox";
import { useEffect } from "react";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import { tempArr } from "../../sharedComponent/tempData";
import ChatImage from "../chatImage";

const Chat = () => {
  const {
    sidebar,
    socket,
    setLoading,
    // getRecentChats,
    setRecentChats,
    setPinnedChats,
    setConversations,
    setNewMessages,
    setPage,
    activeChat,
    setActiveChat,
    userData,
    currentSChannel,
    setCurrentSChannel,
    pinnedChats,
    recentChats,
    setNoMorePages,
    Dark,
    recentChatLdr,
    setRecentChatLdr,
    msgdelete,
    setmsgdelete
  } = useContext(AppContext);

  const [filteredChats, setFilteredChats] = useState([]);
  const getRecentChats = () => {
    setRecentChatLdr(true);
    socket && socket.emit("get-private-chat-list");
  };

  const [schannelName, setSchannelName] = useState(
    activeChat && activeChat.Team_Channel_Description
      ? activeChat.Team_Channel_Description
      : ""
  );
  useEffect(() => { 
    if(msgdelete === true){
      getAllRecentChats();
    }
    getAllRecentChats();
    // setActiveChat({})
    // setCurrentSChannel(null)
  }, [socket,msgdelete]);

  const getAllRecentChats = async () => {
    await getRecentChats();
    socket &&
      socket.on("get-private-chat-list", (data) => {
        setPinnedChats(data.pinned);
        setmsgdelete(false)
        setRecentChats(data.recent);
        setFilteredChats(data.recent);
        setRecentChatLdr(false);
      });

    return () => {
      if (socket) {
        socket.off("get-private-chat-list");
      }
    };
  };

  const [showResults, setShowResults] = useState(false);
  const onClick = () => setShowResults(!showResults);
  const [selectedList, setSelectedList] = useState([]);
  const joinRoom = (schannel_id) => {
    socket.emit("join-room", {
      schannel_id: schannel_id,
    });
  };

  useEffect(() => {
    if (!socket) return;
    const updateChannelName = (data) => {
      const { schannel_id, Team_Channel_Description } = data;
      setRecentChats((prevRecentChats) =>
        prevRecentChats.map((singleChat) =>
          singleChat.Team_Channel_Id == schannel_id
            ? { ...singleChat, Team_Channel_Description }
            : singleChat
        )
      );

      if (activeChat) {
        setActiveChat((prevActiveChat) => ({
          ...prevActiveChat,
          Team_Channel_Description,
        }));
      }
    };

    socket.on("update-schannel-name", updateChannelName);

    return () => {
      socket.off("update-schannel-name", updateChannelName);
    };
  }, [socket, activeChat]);

  const createChatRoom = () => {
    setSchannelName("");
    const body = JSON.stringify({
      members: selectedList.map((item) => item.id),
    });
    PostCallWithErrorResponse(ApiConfig.CREATE_CHATROOM, body)
      .then((res) => {
        setLoading(false);
        const { status, schannel_id } = res.json;

        if (status) {
          setCurrentSChannel(schannel_id);

          socket.emit("join-room", {
            schannel_id,
          });

          setShowResults(false);
          setPage(0);
          setNoMorePages(false);

          setActiveChat({ pin: false, ...res.json });
          getRecentChats();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setConversations([]);
    setNewMessages([]);
    setPage(0);
  }, [currentSChannel]);

  useEffect(() => {
    socket &&
      socket.on("change-status", (data) => {
        setActiveChat({
          ...activeChat,
          em:
            activeChat.em &&
            activeChat.em.map((user) => {
              if (
                user.EmployeeId == data.userid ||
                user.User_Id == data.userid
              ) {
                return {
                  ...user,
                  user_status: data.user_status,
                  last_seen: data.last_seen,
                };
              }
              return user;
            }),
        });

        recentChats &&
          setRecentChats(
            recentChats.map((chat) => {
              chat.em.forEach((user) => {
                if (user.EmployeeId == data.userid) {
                  user.user_status = data.user_status;
                  user.last_seen = data.last_seen;
                }
              });
              return chat;
            })
          );
        setPinnedChats(
          pinnedChats &&
            pinnedChats?.map((chat) => {
              chat.em.forEach((user) => {
                if (user.EmployeeId == data.userid) {
                  user.user_status = data.user_status;
                }
              });
              return chat;
            })
        );
      });

    return () => {
      if (socket) {
        socket.off("change-status");
      }
    };
  }, [activeChat, socket]);

  const [isSearching, setisSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  //For Search Recent
  useEffect(() => {
    const empId = userData && Number(userData?.EmpId);

    const recentChatsdata =
      filteredChats &&
      filteredChats.filter((item) => {
        if (item.Team_Channel_Description) {
          if (item.Team_Channel_Description === "private") {
            return item.em.some(
              (employee) =>
                employee.EmployeeId !== empId &&
                (employee.FirstName + " " + employee.LastName)
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())
            );
          } else {
            return (
              item.Team_Channel_Description &&
              item?.Team_Channel_Description?.toLowerCase().includes(
                searchValue.toLowerCase()
              )
            );
          }
        } else {
          return item.em.some(
            (employee) =>
              employee.EmployeeId !== empId &&
              (employee.FirstName + " " + employee.LastName)
                .toLowerCase()
                .includes(searchValue.toLowerCase())
          );
        }
      });

    setRecentChats(recentChatsdata);
  }, [searchValue]);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="main-chat">
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <div className="row d-flex flex-wrap justify-content-around">
              <div className="col-lg-3 left-chat-section p-0">
                {isSearching ? (
                  <div className="search-input-wrapper">
                    <input
                      autoFocus
                      type="text"
                      placeholder="Search"
                      onBlur={() => {
                        setisSearching(false);
                      }}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    {Dark === "lightMode" ? (
                      <img src={searchicon} alt="" style={{ left: "90%" }} />
                    ) : (
                      <img src={ic_search_dark} alt="" />
                    )}
                  </div>
                ) : (
                  <div className="left-heading-chat">
                    <div className="left-head">
                      <label style={{ color: "var(--black_111111_LightMode)" }}>
                        Chat
                      </label>
                    </div>
                    <div className="right-icon">
                      <Link to="#">
                        <img
                          src={ic_filter_chat}
                          alt=""
                          onClick={() => setisSearching(true)}
                        />
                      </Link>
                      <Link to="#" onClick={onClick} className="ms-4">
                        <img src={ic_plus_msg} alt="" />
                      </Link>
                    </div>
                  </div>
                )}

                <div className="chat-lineseparater"></div>

                <div className="chat-users-accordion-wrapper">
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="pinned-section">
                          <div className="left">
                            <label
                              style={{ color: "var(--gray_05_LightMode)" }}
                              htmlFor=""
                            >
                              Pinned
                            </label>
                          </div>
                          <div className="right">
                            <Link to="#">
                              <img src={ic_dropdown} alt="" className="pe-1" />
                            </Link>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Nav variant="pills" className="flex-column">
                          {pinnedChats && pinnedChats.length ? (
                            pinnedChats.map((chat, index) => {
                              let allusers =
                                chat &&
                                chat?.em
                                  .sort((a, b) => a.EmployeeId - b.EmployeeId)
                                  .filter(
                                    (user) => user.EmployeeId != userData.EmpId
                                  );
                              let user =
                                chat &&
                                chat?.em
                                  .sort((a, b) => a.EmployeeId - b.EmployeeId)
                                  .filter(
                                    (user) => user.EmployeeId != userData.EmpId
                                  )[0];

                              var parameter_Start_index =
                                chat &&
                                chat.last_seen &&
                                chat.last_seen.indexOf(".");
                              var last_seen =
                                chat &&
                                chat.last_seen &&
                                chat.last_seen.substring(
                                  0,
                                  parameter_Start_index
                                ) + ".209Z";
                              return (
                                <Nav.Item
                                  key={"pinned" + index}
                                  onClick={() => {
                                    setShowResults(false);

                                    setPage(0);
                                    setNoMorePages(false);
                                    setActiveChat({ pin: true, ...chat });
                                    setCurrentSChannel(
                                      chat && chat?.Team_Channel_Id
                                    );
                                    joinRoom(chat && chat?.Team_Channel_Id);
                                    // updatepinnedchatUnreadCount(
                                    //   chat && chat?.Team_Channel_Id
                                    // )
                                  }}
                                >
                                  <Nav.Link
                                    eventKey={chat.Team_Channel_Id}
                                    active={
                                      chat?.Team_Channel_Id ===
                                        activeChat?.Team_Channel_Id &&
                                      activeChat?.Team_Channel_Id
                                        ? true
                                        : false
                                    }
                                    className={
                                      chat?.Team_Channel_Id ===
                                        activeChat?.Team_Channel_Id &&
                                      activeChat?.Team_Channel_Id &&
                                      "active"
                                    }
                                  >
                                    <div className="user-chat-tab">
                                      <div className="left-profile-pic">
                                        {allusers.length > 1 ? (
                                          <div class="circle">
                                            <div
                                              class="quarter top-left"
                                              style={{
                                                backgroundColor: "#F2D8D9",
                                                borderTopLeftRadius: "23px",
                                              }}
                                            >
                                              <div
                                                class="initial"
                                                style={{
                                                  marginLeft: 6,
                                                  color: "#643C3F",
                                                  fontSize: 14,
                                                  marginTop: 2,
                                                }}
                                              >
                                                {allusers[0]
                                                  ? allusers[0].FirstName[0]
                                                  : ""}
                                              </div>
                                            </div>
                                            <div
                                              class="quarter top-right"
                                              style={{
                                                backgroundColor: "#DBE4D3",
                                                borderTopRightRadius: "23px",
                                              }}
                                            >
                                              <div
                                                class="initial"
                                                style={{
                                                  marginLeft: 2,
                                                  color: "#3E4439",
                                                  fontSize: 14,
                                                }}
                                              >
                                                {allusers[1]
                                                  ? allusers[1].FirstName[0]
                                                  : ""}
                                              </div>
                                            </div>
                                            <div
                                              class="quarter bottom-left"
                                              style={{
                                                backgroundColor: "#C8D3E4",
                                                borderBottomLeftRadius: "23px",
                                              }}
                                            >
                                              <div
                                                class="initial"
                                                style={{
                                                  marginLeft: 6,
                                                  color: "#394C74",
                                                  fontSize: 14,
                                                  marginBottom: 2,
                                                }}
                                              >
                                                {allusers[2]
                                                  ? allusers[2].FirstName[0]
                                                  : ""}
                                              </div>
                                            </div>
                                            <div
                                              class="quarter bottom-right"
                                              style={{
                                                backgroundColor: "#F8E9CD",
                                                borderBottomRightRadius: "23px",
                                              }}
                                            >
                                              <div class="initial">+</div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div>
                                            <Link>
                                              <ChatImage
                                                name={user?.FirstName}
                                                lastName={user?.LastName}
                                                PhotoPath={user?.PhotoPath}
                                              />
                                              {/* <img
                                                src={
                                                  user && user.PhotoPath
                                                    ? ApiConfig.BASE_URL_FOR_IMAGES +
                                                      user.PhotoPath
                                                    : profile_img
                                                }
                                                alt=""
                                                onError={(e) =>
                                                  (e.target.src = profile_img)
                                                }
                                              /> */}
                                            </Link>
                                            <span className="indication-img">
                                              <img
                                                src={
                                                  user &&
                                                  (user.user_status == "online"
                                                    ? ic_online
                                                    : user.user_status ==
                                                        "busy" ||
                                                      user.user_status ==
                                                        "do not desturb"
                                                    ? ic_busy
                                                    : user.user_status == "away"
                                                    ? ic_away
                                                    : ic_gray)
                                                }
                                                alt=""
                                              />
                                            </span>
                                          </div>
                                        )}
                                      </div>

                                      <div className="right-name-date ">
                                        <div className="top-section ">
                                          <div className="name ml-4">
                                            <label htmlFor="">
                                              {chat.Team_Channel_Description &&
                                              chat.Team_Channel_Description !=
                                                undefined &&
                                              chat.Team_Channel_Description !=
                                                null &&
                                              chat.Team_Channel_Description !=
                                                "private"
                                                ? chat.Team_Channel_Description
                                                : chat.em.length == 2
                                                ? user &&
                                                  user.FirstName +
                                                    " " +
                                                    user.LastName
                                                : user &&
                                                  user.FirstName +
                                                    " " +
                                                    user.LastName +
                                                    " " +
                                                    (chat.em.length - 1 > 1 &&
                                                      "   " +
                                                        chat.em.length -
                                                        2 +
                                                        " others")}
                                            </label>
                                            {/* <div className="lastSeen">
                                                <label className="text1">Last Seen : </label>
                                                <label className="text1">{moment(last_seen).fromNow()}</label>
                                              </div> */}
                                          </div>
                                          <div></div>
                                          <div className="date">
                                            <span class="badge mx-2">
                                              {chat.unread_count > 0
                                                ? chat.unread_count
                                                : ""}
                                            </span>
                                          </div>
                                        </div>

                                        <div className="bottom-section ml-4">
                                          <label htmlFor="">
                                            {chat.lastmessage &&
                                            chat.lastmessage.length > 50
                                              ? chat.lastmessage.slice(0, 25) +
                                                ".."
                                              : chat.lastmessage}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              );
                            })
                          ) : (
                            <p className="ml-5 text-secondary">
                              No Pinned Chats found
                            </p>
                          )}
                        </Nav>
                      </Accordion.Body>
                    </Accordion.Item>
                    <div className="chat-lineseparater"></div>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="pinned-section">
                          <div className="left">
                            <label
                              style={{ color: "var(--gray_05_LightMode)" }}
                              htmlFor=""
                            >
                              Recent
                            </label>
                          </div>
                          <div className="right">
                            <Link to="#">
                              <img src={ic_dropdown} alt="" className="pe-1" />
                            </Link>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Nav variant="pills" className="flex-column">
                          {recentChatLdr ? (
                            <Beatloader />
                          ) : recentChats && recentChats.length ? (
                            recentChats.map((chat, index) => {
                              let allusers = chat.em
                                .sort((a, b) => a.EmployeeId - b.EmployeeId)
                                .filter(
                                  (user) => user.EmployeeId != userData.EmpId
                                );
                              let user = chat.em
                                .sort((a, b) => a.EmployeeId - b.EmployeeId)
                                .filter(
                                  (user) => user.EmployeeId != userData.EmpId
                                )[0];

                              var parameter_Start_index =
                                chat.last_seen && chat.last_seen.indexOf(".");
                              var last_seen =
                                chat.last_seen &&
                                chat.last_seen.substring(
                                  0,
                                  parameter_Start_index
                                ) + ".209Z";
                              return (
                                <Nav.Item
                                  key={"recent" + index}
                                  onClick={() => {
                                    setShowResults(false);
                                    setPage(0);
                                    setNoMorePages(false);
                                    setActiveChat({ pin: false, ...chat });
                                    setCurrentSChannel(chat.Team_Channel_Id);
                                    setSchannelName(
                                      chat?.Team_Channel_Description
                                        ? chat?.Team_Channel_Description
                                        : ""
                                    );
                                    joinRoom(chat.Team_Channel_Id);
                                    // updaterecentchatUnreadCount(
                                    //   chat.Team_Channel_Id
                                    // )
                                    setRecentChats(filteredChats);
                                  }}
                                >
                                  <Nav.Link
                                    eventKey={chat?.Team_Channel_Id}
                                    active={
                                      chat?.Team_Channel_Id ===
                                        activeChat?.Team_Channel_Id &&
                                      activeChat?.Team_Channel_Id
                                        ? true
                                        : false
                                    }
                                    className={
                                      chat?.Team_Channel_Id ===
                                        activeChat?.Team_Channel_Id &&
                                      activeChat?.Team_Channel_Id &&
                                      "active"
                                    }
                                  >
                                    <div className="user-chat-tab">
                                      <div className="left-profile-pic">
                                        {allusers.length > 1 ? (
                                          <div class="circle">
                                            <div
                                              class="quarter top-left"
                                              style={{
                                                backgroundColor: "#F2D8D9",
                                                borderTopLeftRadius: "23px",
                                              }}
                                            >
                                              <div
                                                class="initial"
                                                style={{
                                                  marginLeft: 8,
                                                  color: "#643C3F",
                                                  fontSize: 14,
                                                  marginTop: 2,
                                                }}
                                              >
                                                {allusers[0]
                                                  ? allusers[0].FirstName[0]
                                                  : ""}
                                              </div>
                                            </div>
                                            <div
                                              class="quarter top-right"
                                              style={{
                                                backgroundColor: "#DBE4D3",
                                                borderTopRightRadius: "23px",
                                              }}
                                            >
                                              <div
                                                class="initial"
                                                style={{
                                                  marginLeft: 2,
                                                  color: "#3E4439",
                                                  fontSize: 14,
                                                }}
                                              >
                                                {allusers[1]
                                                  ? allusers[1].FirstName[0]
                                                  : ""}
                                              </div>
                                            </div>
                                            <div
                                              class="quarter bottom-left"
                                              style={{
                                                backgroundColor: "#C8D3E4",
                                                borderBottomLeftRadius: "23px",
                                              }}
                                            >
                                              <div
                                                class="initial"
                                                style={{
                                                  marginLeft: 6,
                                                  color: "#394C74",
                                                  fontSize: 14,
                                                  marginBottom: 2,
                                                }}
                                              >
                                                {allusers[2]
                                                  ? allusers[2].FirstName[0]
                                                  : ""}
                                              </div>
                                            </div>
                                            <div
                                              class="quarter bottom-right"
                                              style={{
                                                backgroundColor: "#F8E9CD",
                                                borderBottomRightRadius: "23px",
                                              }}
                                            >
                                              <div class="initial">+</div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div>
                                            <Link>
                                              <ChatImage
                                                name={user?.FirstName}
                                                lastName={user?.LastName}
                                                PhotoPath={user?.PhotoPath}
                                              />
                                              {/* {!user?.PhotoPath ? (
                                                <div className="circle">
                                                  <span
                                                    className="ml-2 mt-1 "
                                                    style={{
                                                      fontSize: "20px",
                                                      fontWeight: "bolder",
                                                    }}
                                                  >
                                                    {user?.FirstName[0]}
                                                  </span>
                                                </div>
                                              ) : (
                                                <img
                                                  src={
                                                    user && user.PhotoPath
                                                      ? ApiConfig.BASE_URL_FOR_IMAGES +
                                                        user.PhotoPath
                                                      : profile_img
                                                  }
                                                  alt=""
                                                  onError={(e) =>
                                                    (e.target.src = profile_img)
                                                  }
                                                />
                                              )} */}
                                            </Link>
                                            <span className="indication-img">
                                              <img
                                                src={
                                                  user &&
                                                  (user.user_status == "online"
                                                    ? ic_online
                                                    : user.user_status ==
                                                        "busy" ||
                                                      user.user_status ==
                                                        "do not desturb"
                                                    ? ic_busy
                                                    : user.user_status == "away"
                                                    ? ic_away
                                                    : ic_gray)
                                                }
                                                alt=""
                                              />
                                            </span>
                                          </div>
                                        )}
                                      </div>

                                      <div className="right-name-date ">
                                        <div
                                          className="top-section "
                                          style={{ cursor: "pointer" }}
                                        >
                                          <div className="name ml-4">
                                            <label
                                              htmlFor=""
                                              style={{ cursor: "pointer" }}
                                            >
                                              {chat.Team_Channel_Description &&
                                              chat.Team_Channel_Description !=
                                                undefined &&
                                              chat.Team_Channel_Description !=
                                                null &&
                                              chat.Team_Channel_Description !=
                                                "private"
                                                ? chat.Team_Channel_Description
                                                : chat.em.length == 2
                                                ? user &&
                                                  user.FirstName +
                                                    " " +
                                                    user.LastName
                                                : user &&
                                                  user.FirstName +
                                                    " " +
                                                    user.LastName +
                                                    " " +
                                                    (chat.em.length - 1 > 1 &&
                                                      "   " +
                                                        chat.em.length -
                                                        1 +
                                                        " & others")}
                                            </label>
                                            {/* {user && user.user_status == "offline" && user.last_seen &&
                                                <div className="lastSeen">
                                                  <label className="text1">Last Seen : </label>
                                                  <label className="text1">{moment(last_seen).fromNow()}</label>
                                                </div>
                                              } */}
                                          </div>
                                          <div></div>
                                          <div className="date">
                                            <span class="badge mx-2">
                                              {chat.unread_count > 0
                                                ? chat.unread_count
                                                : ""}
                                            </span>
                                          </div>
                                        </div>

                                        <div className="bottom-section ml-4">
                                          <label htmlFor="">
                                            {chat.lastmessage}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              );
                            })
                          ) : (
                            <p className="ml-5 text-secondary">
                              No Recent Chats found
                            </p>
                          )}
                        </Nav>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="chat-lineseparater pt-3"></div>
                </div>
              </div>
              <ChatBox
                showResults={showResults}
                setShowResults={setShowResults}
                createChatRoom={createChatRoom}
                setSelectedList={setSelectedList}
                selectedList={selectedList}
                schannelName={schannelName}
                setSchannelName={setSchannelName}
                filteredChats={filteredChats}
                setRecentChats={setRecentChats}
                updatePinUnpinChat={getAllRecentChats}
              />
              {/* <ChatBox /> */}
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default Chat;
