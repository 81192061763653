import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { PostCallWithErrorResponse, simpleGetCallWithToken } from "../../api/ApiServices";
import { ToastMsg } from "../TostMsg";
import { Navigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import AgentList from "./AgentList";

export default function UserdetailsAgent() {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
    const [agentList,SetAgentList] = useState([])

    const [addPriority,setAddpriority] = useState({
      priority: '',
      agent:"",
    })

    const getcrmCallData = () => {
      // setLoading(true)
      simpleGetCallWithToken(ApiConfig.GET_AGENT_lIST_DATA)
        .then((res) => {
          console.log("Call List Data>>>", res)
        
          SetAgentList(res)
        })
        .catch((err) => {
          // setLoading(false)
  
          console.log("call List Data>>>", err)
        })
    }

    useEffect(()=>{
      getcrmCallData()
    },[])
    const onMakeCall = ()=>{

      const body={
        agent:addPriority.agent,
        agent_priority:addPriority.priority
      }
      console.log("make Call Body>>>",body.user_no)
      PostCallWithErrorResponse(ApiConfig.ADD_AGENT_PRIORITY,JSON.stringify(body))
      .then((res)=>{
        if(!res){
          ToastMsg("success",res)
          Navigate("/AgentCallSummary")
        }else{
          ToastMsg("error",res.error)
        }
        console.log("makeCall res>>>>",res)
      })
      .catch((err)=>{
        ToastMsg("error",err.error)
        console.log("makeCall err>>>>",err.error)
      })
  
    }

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role ">
          <h6 className="mt-2">User Details</h6>
        </div>

        <div className="WeeklyMain">
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Agent Priority :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <input
                  //   value={newPassword}
                  placeholder="Agent Priorit"
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    //   fontSize: "14px",
                    height: "38px",
                    borderRadius: "10px",
                  }}
                    onChange={(e) => {
                      setAddpriority({
                        ...addPriority,
                        priority: e.target.value,
                      });
                    }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Agent :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <select
                  className="tasKshowusser"
                  onChange={(e) => {
                    setAddpriority({
                      ...addPriority,
                      agent: e.target.value,
                    });
                  }}
                >
                  <option>Select Agent</option>
                  {
                    agentList?.map((item)=>(
<option value={item.name}>{`${item.number}(${item.name})`}</option>
                    ))
                  }
                  
                  {/* <option>983943939(Shobhisha)</option> */}
                </select>
              </div>
            </div>
          </div>

          <div className="align-items-center justify-content-center d-flex mt-5 mb-3">
            <button className="custoBtnForCrmCall">
              <a 
              className=" textcolor"
              //  href="AgentCallSummary"
               onClick={onMakeCall}
               >
                Add
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
