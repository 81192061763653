import React from "react";
import { ScaleLoader } from "react-spinners";

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex', justifyContent: 'center',
        alignItems: 'center',
        margin: "18%"
      }}
    >
      <ScaleLoader
        color={"#53B7E8"}
        className="spinner"
        // style={{
        //   display: "block",
        //   textAlign: "center",
        //   marginLeft: "80%",
        //   borderColor: "#b76b29",
        // }}
        //css={override}
        size={300}
      />
    </div>
  );
};

export default Loader;
