
import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/Role.scss";
import searchicon from "../../assets/images/search_icon.svg";
import editicon from "../../assets/images/bx_edit.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import close from "../../assets/images/close.svg";
import deleteicon from "../../assets/images/delete.svg";
import Modal from "react-bootstrap/Modal";
import { AppContext } from "../../context/AppContext";
import Pagination from "../../../src/sharedComponent/Pagination";
import CommonButton from "../ChatAdmin/button";
import { Accordion } from "react-bootstrap";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import { getDateddmmyyyy } from "../../common";
import DynamicField from "../../sharedComponent/dynamicField";


const WorkFlow = () => {

    const {
        sidebar,
        getMasterData,
        masterData,
        getTaskAllocationStatus,
        taskAllocationStatus,
        getTeamList,
        teamList
    } = useContext(AppContext);


    const initialWorkflow = {
        "id": 0,
        "name": "",
        "isRetrospective": false,
        "isSequential": false,
        "serviceIds": [],
        "subscriptionIds": [

        ],
        "periodId": 0,
        "beginDate": "",
        "endDate": "",
        "tasks": [
            {
                "name": "",
                "Id": 0,
                "ownerRoles": [

                ],
                "description": "",
                "displayTyperole": "",
                "displayTypeId": "",
                "Sequence": 0,
                teams: [],
                projects: [],
                "taskDatas": [

                ],
                "actions": [

                ],
                "systemProcedure": []
            }
        ]
    }

    const initialTask = {
        "id": 0,
        "name": "",
        "description": "",
        "taskDataTypeId": '',
        "taskDataType": "",
        "selectionData": [
            {
                "Name": "",
                "Value": ""
            }
        ],
        "validationData": [
            {
                "validationTypeId": '',
                "validationValue": ""
            }
        ],
        "taskValidation": '',
        "taskValidationvalue": "",
        "validationifany": "",
        "priorityOrder": '',
        "groupName": "",
        "taskDataId": '',
        "procedure": false
    }

    const initialAction = {
        "id": 0,
        "name": "",
        "sendToTask": "",
        "isReasonRequired": false,
        "activateNextStep": false,
        "taskStatusId": '',
        "taskStatusvalue": ""
    }



    const [taskSate, setTaskState] = useState(initialTask)
    const [actionData, setActionData] = useState(initialAction)
    const [state, setState] = useState(initialWorkflow)
    const [addDataForIndex, setAddDataForIndex] = useState('')
    const [isWorkflowCreating, setIsWorkflowCreating] = useState(false)
    const [isViewWorkFlow, setIsViewWorkFlow] = useState(false)

    // validation

    const [isTaskAddValided, setIsTaskAddValided] = useState(false)
    const [isAddActionValided, setIsActionValided] = useState(false)
    const [isValided, setIsValided] = useState(false)


    const [isOpenModel, setIsIsOpneModal] = useState(false)
    const [isAddFieldOpen, setIsAddFieldOpen] = useState(false)
    const [isAddActionOpen, setIsAdActionOpen] = useState(false)


    const [subscription, setSubscriptions] = useState([])
    const [projects, setProjects] = useState([])
    const [workflows, setWorkFlows] = useState([])
    const [isWorkFlowLoading, setIsWorkflowLoading] = useState(false)


    const [perPageData, setPerpgaeData] = useState(25)
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')

    const startIndex = currentPage * perPageData;
    const endIndex = startIndex + perPageData


    const closeformData = () => {

        setIsIsOpneModal(false)

        setIsValided(false)

        setState(initialWorkflow)

    }


    const getWorkFlowList = () => {

        setIsWorkflowLoading(true)

        simpleGetCallWithErrorResponse(ApiConfig.GET_WORKFLOWS).then((res) => {

            if (res?.json?.success) {
                setWorkFlows(res?.json?.data)
            }

            setIsWorkflowLoading(false)

        })

    }

    useEffect(() => {

        getWorkFlowList()
    }, [])


    const getSubsciptions = () => {


        if (subscription.length > 0) return

        simpleGetCallWithErrorResponse(ApiConfig.GET_PENDING_SUBSCRIPTIONS).then((res) => {

            if (res?.json?.success) {

                setSubscriptions(res?.json?.data)

            }

        })

    }




    const handleModalOpen = () => {

        getSubsciptions()

        getMasterData()

        getTeamList()
        getProjectList()

        setIsIsOpneModal(true)

    }

    const handleOpenAddAction = () => {
        getTaskAllocationStatus()
        setIsAdActionOpen(true)
    }


    const clearFormData = () => {

        setIsAdActionOpen(false)

        setIsAddFieldOpen(false)

        setTaskState(initialTask)

        setIsActionValided(false)

        setIsTaskAddValided(false)

        setActionData(initialAction)

    }

    const handleChange = (key, val, indx, secoundKey = '', isArray = false) => {

        if (secoundKey.length > 0) {

            setState(prev => {
                let updatedTasks = [...prev[key]];

                if (isArray) {
                    updatedTasks[addDataForIndex][secoundKey].push(val)
                } else {
                    updatedTasks[indx][secoundKey] = val
                }


                return {
                    ...prev,
                    [key]: updatedTasks
                };
            });

            setAddDataForIndex('')


        } else {
            setState(prev => ({
                ...prev,
                [key]: val
            }))
        }


    }

    const handleChangeTask = (key, val) => {

        setTaskState(prev => ({
            ...prev,
            [key]: val
        }))

    }

    const handleChangeAction = (key, val) => {

        setActionData(prev => ({
            ...prev,
            [key]: val
        }))

    }

    const handleAddField = () => {

        if (
            !taskSate?.name ||
            !taskSate?.description ||
            !taskSate?.taskDataTypeId ||
            !taskSate?.taskValidation ||
            !taskSate?.priorityOrder
        ) {

            setIsTaskAddValided(true)

        } else {

            taskSate.selectionData = [
                {
                    "Name": taskSate?.name,
                    "Value": taskSate?.taskDataType
                }
            ]

            taskSate.validationData = [
                {
                    "validationTypeId": taskSate?.taskValidation,
                    "validationValue": taskSate?.taskDataType
                }
            ]

            handleChange('tasks', taskSate, "", 'taskDatas', true)

            clearFormData()


        }

    }

    const addStep = () => {

        handleChange('tasks', [...state.tasks, {
            "name": "",
            "Id": 0,
            "ownerRoles": [

            ],
            "description": "",
            "displayTyperole": "",
            "displayTypeId": "",
            teams: [],
            projects: [],
            "Sequence": 0,
            "taskDatas": [

            ],
            "actions": [

            ],
            "systemProcedure": []
        }])

    }

    const handleAddAction = () => {

        if (
            !actionData?.name ||
            !actionData?.sendToTask ||
            !actionData?.taskStatusvalue
        ) {
            setIsActionValided(true)
        } else {

            handleChange('tasks', actionData, "", 'actions', true)

            clearFormData()

        }

    }


    const getProjectList = () => {

        if (projects.length > 0) return

        simpleGetCallWithErrorResponse(ApiConfig.GET_PROJECTNAMES_LIST).then(((res) => {

            if (res?.json?.Success) {
                setProjects(res?.json?.Data)
            }

        }))
    }

    const handleSubmit = () => {

        let isValid = true

        if (
            !state?.name ||
            !state?.beginDate ||
            !state?.endDate ||
            state?.subscriptionIds.length == 0
        ) {

            isValid = false


            setIsValided(true)

        }

        if (state.tasks == 0) {
            isValid = false;
            ToastMsg('error', 'Please enter At Least 1 Step')
        }


        state?.tasks?.forEach((task, index) => {

            console.log(task);

            if (!task?.name ||
                !task?.displayTypeId ||
                !task?.description ||
                (!task?.teams && !task?.ownerRoles && !task?.projects)
            ) {

                isValid = false;

                setIsValided(true)

            }


            if (task?.taskDatas?.length == 0) {

                let no = index + 1

                ToastMsg('error', 'Field Record Not Entered,Please Enter Proper Field Record In Step  ' + no)

                isValid = false

            }

            // if (task?.taskDatas?.length != task?.actions?.length) {

            //     isValid = false

            //     ToastMsg('error', 'Please Enter Action Equal To Fileds')

            // }

        })

        if (isValid) {

            setIsWorkflowCreating(true)

            const url = state?.id == 0 ? ApiConfig.CREATE_WORKFLOW : ApiConfig.UDPATE_WORKFLOW;

            let body = { ...state }

            // if (state.id != 0) {

            //     state.modifyTasks = state?.tasks?.filter((task) => task?.id != 0)

            //     //state.tasks = state?.tasks?.filter((task) => task?.id == 0)

            // }

            PostCallWithErrorResponse(url, JSON.stringify(body)).then((res) => {

                if (res?.json?.success) {

                    ToastMsg('success', res?.json?.message)

                    getWorkFlowList()

                    closeformData()

                } else {

                    ToastMsg('error', res?.json?.message)

                }

                setIsWorkflowCreating(false)

            })

        }

    }

    const removeStep = (index, id) => {


        state?.tasks?.splice(index, 1)

        handleChange('tasks', [...state?.tasks])




    }


    const removeActionOrField = (index, subIndex, key) => {

        state?.tasks[index][key].splice(subIndex, 1)

        handleChange('tasks', state?.tasks)

    }

    const getWorkflowById = (id) => {

        simpleGetCallWithErrorResponse(ApiConfig.GET_WORKFLOW_BY_ID + id).then((res) => {

            if (res?.json?.success) {

                setState(res?.json?.data)

            }

        })

    }

    return (
        <>
            <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
                <div

                >
                    <div className="role-content">
                        <div className="sub-header-role">
                            <h6 className="mt-2 role-text">Defined Workflow</h6>
                            <button className="create-btn-sub-header" style={{ cursor: 'pointer' }}
                                onClick={handleModalOpen}
                            >
                                Create
                            </button>
                        </div>
                        <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
                            <div className="role-table-header reponsivesrc">
                                <div className="search-input-wrapper">
                                    <input type="text" placeholder="Search"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <img src={searchicon} alt="" />
                                </div>
                                <div className="entries-wrapper">
                                    <p>Entries per page</p>
                                    <select
                                        class="form-select select-drop-icon remove-border"
                                        aria-label="Default select example"
                                        value={perPageData}
                                        onChange={(e) => setPerpgaeData(e.target.value)}
                                    >
                                        <option selected value={25}>25</option>
                                        <option value="50">50</option>
                                        <option value="75">75</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            isWorkFlowLoading ? <Loader /> :
                                workflows?.length == 0 ? <NoData /> :
                                    <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
                                        <table>
                                            <tr>
                                                <th>Sr. no</th>
                                                <th>Name</th>
                                                <th>BeginDate</th>
                                                <th>EndDate</th>
                                                <th>Action</th>
                                            </tr>
                                            {
                                                workflows?.filter((workflow) => search?.length ? workflow?.name?.toLowerCase().includes(search.toLowerCase()) : workflow)
                                                    .slice(startIndex, endIndex).map((workFlow, index) => {
                                                        return <tr className="table-row-custom">
                                                            <td>{startIndex + index + 1}</td>
                                                            <td className="send-quote-status">{workFlow?.name}</td>
                                                            <td>{getDateddmmyyyy(workFlow?.beginDate)}</td>
                                                            <td>{getDateddmmyyyy(workFlow?.endDate)}</td>
                                                            <tr>
                                                                <img
                                                                    src={editicon}
                                                                    alt=""
                                                                    onClick={() => {
                                                                        handleModalOpen()
                                                                        getWorkflowById(workFlow?.id)
                                                                    }}
                                                                    className="me-3"
                                                                />
                                                                <img src={eye_icon} alt=""
                                                                    onClick={() => {
                                                                        setIsViewWorkFlow(true)
                                                                        getWorkflowById(workFlow?.id)
                                                                    }} />
                                                            </tr>
                                                        </tr>
                                                    })
                                            }

                                        </table>
                                    </div>
                        }

                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-12">
                            <Pagination
                                data={workflows}
                                pageChangeHandler={(selected) => setCurrentPage(selected.selected)}
                                usersPerPage={perPageData}
                                currentPage={currentPage}
                                searchValue={search}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isOpenModel}
                className="create-workflow-main modal-lg"
                size="xl"
                style={isOpenModel ? { opacity: isAddActionOpen || isAddFieldOpen ? '0.7' : '1' } : null}

            >
                <Modal.Header>
                    <Modal.Title>
                        <label htmlFor="" className="create-role-text">
                            {state?.id == 0 ? 'Create' : "Update"} WorkFlow
                        </label>
                    </Modal.Title>
                    <img src={close} alt="" onClick={closeformData} />
                </Modal.Header>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>
                <Modal.Body>
                    <div
                        style={{ maxHeight: '580px', overflowX: 'scroll', scrollbarWidth: 'none' }}>
                        <div className="row create-workflow-topbody pb-4">
                            <div className="col-lg-4 mt-4">
                                <label htmlFor="" className="text-grey">
                                    Workflow Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control tasKCategory1"
                                    placeholder="Workflow Name"
                                    value={state?.name}
                                    onChange={(e) => handleChange('name', e.target.value)}
                                />
                                <p className="text-danger">{isValided && !state?.name && 'Please Enter Name'}</p>
                            </div>

                            <div className="col-lg-4  mt-6 d-flex align-items-center">
                                <input type="checkbox"
                                    checked={state?.isRetrospective}
                                    onChange={(e) => handleChange('isRetrospective', e.target.checked)}
                                />
                                <label htmlFor="" className="text-grey me-5 ml-1">
                                    Is retrospective ?
                                </label>
                            </div>
                            <div className="col-lg-4  mt-6 d-flex align-items-center">

                                <input type="checkbox"
                                    checked={state?.isSequential}
                                    onChange={(e) => handleChange('isSequential', e.target.checked)}
                                />
                                <label htmlFor="" className="text-grey me-5 ml-1">
                                    Is Sequential ?
                                </label>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <label htmlFor="" className="text-grey">
                                    Select Start Date
                                </label>
                                <input
                                    type="date"
                                    className="form-control tasKCategory1"
                                    placeholder="Workflow Name"
                                    value={state?.beginDate?.split('T')[0]}
                                    onChange={(e) => handleChange('beginDate', e.target.value)}
                                />
                                <p className="text-danger">{isValided && !state?.beginDate && 'Please Select Start Date '}</p>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <label htmlFor="" className="text-grey">
                                    Select End Date
                                </label>
                                <input
                                    type="date"
                                    className="form-control tasKCategory1"
                                    placeholder="Workflow Name"
                                    value={state?.endDate?.split('T')[0]}
                                    onChange={(e) => handleChange('endDate', e.target.value)}
                                />
                                <p className="text-danger">{isValided && !state?.endDate && 'Please Select End Date '}</p>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <label htmlFor="" className="text-grey">
                                    Subscription
                                </label>
                                <select
                                    class="form-select tasKCategory1"
                                    aria-label="Default select example"
                                    value={state?.subscriptionIds ? state?.subscriptionIds[0] : ''}
                                    onChange={(e) => handleChange('subscriptionIds', [Number(e.target.value)])}
                                >
                                    <option selected value={''} disabled>Select Subscription</option>
                                    {
                                        subscription?.map((sub) => {
                                            return <option value={sub?.id}>{sub?.name}</option>
                                        })
                                    }

                                </select>
                                <p className="text-danger">{isValided && (!state?.subscriptionIds || state?.subscriptionIds?.length === 0 || state.subscriptionIds[0] === '') && 'Please Select Subscription '}</p>
                            </div>
                        </div>
                        <Accordion defaultActiveKey={0}>
                            {
                                state?.tasks?.map((task, index) => {
                                    return <Accordion.Item eventKey={index} key={index} className="mt-2">
                                        <Accordion.Header>
                                            <div className="menus-items w-100 d-flex justify-content-between" style={{ backgroundColor: 'whiteSmoke', padding: '15px 15px 15px 15px' }}>
                                                <h5 className="">Step {index + 1} </h5>
                                                <img src={close} alt=""
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        removeStep(index, task?.id)
                                                    }}
                                                />
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="p-4">
                                                <div className="row create-workflow-topbody pb-4">
                                                    <div className="col-lg-12 row">
                                                        <div className="col-lg-5">
                                                            <label htmlFor="" className="text-grey">
                                                                Step Name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control tasKCategory1"
                                                                placeholder="New Step"
                                                                value={task.name}
                                                                onChange={(e) => handleChange('tasks', e.target.value, index, 'name')}
                                                            />
                                                            <p className="text-danger">{isValided && !task?.name && 'Please Enter Name'}</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3 mt-2">
                                                        <label htmlFor="" className="text-grey">
                                                            OwnerRoles
                                                        </label>
                                                        <select
                                                            class="form-select tasKCategory1"
                                                            aria-label="Default select example"
                                                            value={task?.ownerRoles[0]}
                                                            onChange={(e) => handleChange('tasks', [Number(e.target.value)], index, 'ownerRoles')}
                                                        >
                                                            <option selected value={''} >Select OwnerRoles</option>
                                                            {

                                                                masterData?.UserRoles?.map((role, index) => {
                                                                    return <option value={role?.id} key={index}>{role?.value}</option>
                                                                })
                                                            }

                                                        </select>
                                                        <p className="text-danger">{isValided && (!task?.ownerRoles[0] && !task?.teams[0] && !task.projects[0]) && 'Please Select Either OwnerRoles or Team or Project'}</p>
                                                    </div>

                                                    <div className="col-lg-1 text-center mt-4">
                                                        <span className="text-bold">OR</span>
                                                    </div>
                                                    <div className="col-lg-3 mt-2">

                                                        <label htmlFor="" className="text-grey">
                                                            Teams
                                                        </label>
                                                        <select
                                                            class="form-select tasKCategory1"
                                                            aria-label="Default select example"
                                                            value={task?.teams ? task?.teams[0] : ''}
                                                            onChange={(e) => handleChange('tasks', [Number(e.target.value)], index, 'teams')}
                                                        >
                                                            <option selected value={''} >Select Team</option>
                                                            {

                                                                teamList?.map((team, index) => {
                                                                    return <option value={team?.Id} key={index}>{team?.TeamName}</option>
                                                                })
                                                            }

                                                        </select>
                                                        <p className="text-danger">{isValided && (!task?.ownerRoles[0] && !task?.teams[0] && !task.projects[0]) && 'Please Select Either OwnerRoles or Team or Project'}</p>
                                                    </div>

                                                    <div className="col-lg-1 text-center mt-4">
                                                        <span className="text-bold">OR</span>
                                                    </div>
                                                    <div className="col-lg-3 mt-2">

                                                        <label htmlFor="" className="text-grey">
                                                            Project
                                                        </label>
                                                        <select
                                                            class="form-select tasKCategory1"
                                                            aria-label="Default select example"
                                                            value={task?.projects ? task?.projects[0] : ''}
                                                            onChange={(e) => handleChange('tasks', [Number(e.target.value)], index, 'projects')}
                                                        >
                                                            <option selected value={''} >Select Project</option>
                                                            {

                                                                projects?.map((project, index) => {
                                                                    return <option value={project?.Id} key={index}>{project?.ProjectName}</option>
                                                                })
                                                            }

                                                        </select>
                                                        <p className="text-danger">{isValided && (!task?.ownerRoles[0] && !task?.teams[0] && !task.projects[0]) && 'Please Select Either OwnerRoles or Team or Project'}</p>
                                                    </div>
                                                    <div className="col-lg-3 mt-3">
                                                        <label htmlFor="" className="text-grey">
                                                            Display Type Role
                                                        </label>
                                                        <select
                                                            class="form-select tasKCategory1"
                                                            aria-label="Default select example"
                                                            value={task?.displayTypeId}
                                                            onChange={(e) => {

                                                                handleChange('tasks', Number(e.target.value), index, 'displayTypeId')

                                                                let selectedName = masterData?.TaskDisplayTypes?.filter((i) => e.target.value == i?.id)

                                                                handleChange('tasks', selectedName[0]?.name, index, 'displayTyperole')


                                                            }}
                                                        >
                                                            <option selected value={''} disabled>Display Type Role</option>
                                                            {

                                                                masterData?.TaskDisplayTypes?.map((tasktype, index) => {
                                                                    return <option value={tasktype?.id} key={index}>{tasktype?.value}</option>
                                                                })
                                                            }

                                                        </select>
                                                        <p className="text-danger">{isValided && !task?.displayTypeId && 'Please Select Display Type Role'}</p>
                                                    </div>
                                                    <div className="col-lg-3 mt-3">
                                                        <label htmlFor="" className="text-grey">
                                                            Description
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control tasKCategory1"
                                                            placeholder="Description"
                                                            value={task?.description}
                                                            onChange={(e) => handleChange('tasks', e.target.value, index, 'description')}
                                                        />
                                                        <p className="text-danger">{isValided && !task?.description && 'Please Enter Description'}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                                                        onClick={() => {
                                                            setAddDataForIndex(index)
                                                            setIsAddFieldOpen(true)

                                                        }}
                                                    >+ Add Fields</button>
                                                </div>
                                                {
                                                    task?.taskDatas?.length > 0 ?
                                                        <div className="table-wrapper">
                                                            <table>
                                                                <tr >
                                                                    <th>Name</th>
                                                                    <th>Data Type</th>
                                                                    <th>Validation</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                {
                                                                    task?.taskDatas?.map((task, indx) => {
                                                                        return <tr className="table-row-custom" key={indx}>
                                                                            <td className="send-quote-status">{task?.name}</td>
                                                                            <td>{task?.taskDataType}</td>
                                                                            <td>{task?.taskValidationvalue}</td>
                                                                            <td>
                                                                                <img src={deleteicon} alt="" style={{ marginTop: '-4px' }}
                                                                                    onClick={() => removeActionOrField(index, indx, 'taskDatas')}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }

                                                            </table>
                                                        </div> : <div className="mt-3 " style={{ backgroundColor: '#ABC3B3' }}>
                                                            <h6 className="text-center p-2">Field Record Not Entered,Please Enter Proper Field Record</h6>
                                                        </div>
                                                }
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                                                        onClick={() => {
                                                            setAddDataForIndex(index)
                                                            handleOpenAddAction()

                                                        }}
                                                    >+ Add Action</button>
                                                </div>
                                                {
                                                    task?.actions?.length > 0 ?
                                                        <div className="table-wrapper">
                                                            <table>
                                                                <tr >
                                                                    <th>Action Name</th>
                                                                    <th>Go To Step</th>
                                                                    <th>Isreason Required</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                {
                                                                    task.actions?.map((action, indx) => {
                                                                        return <tr className="table-row-custom" key={indx}>
                                                                            <td>{action?.name}</td>
                                                                            <td className="send-quote-status">{action?.sendToTask}</td>
                                                                            <td>{action?.isReasonRequired ? 'Yes' : 'No'}</td>
                                                                            <td>

                                                                                <img src={deleteicon} alt=""
                                                                                    onClick={() => removeActionOrField(index, indx, 'actions')}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }

                                                            </table>
                                                        </div> : <div className="mt-3 " style={{ backgroundColor: '#ABC3B3' }}>
                                                            <h6 className="text-center p-2">Action Not Entered, Please Add Action</h6>
                                                        </div>
                                                }
                                            </div>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                })
                            }


                        </Accordion>
                        <div className="d-flex justify-content-end">
                            <button
                                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                                onClick={addStep}
                            >+ Add Step</button>
                        </div>
                    </div>

                </Modal.Body>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>
                <Modal.Footer>
                    <div>
                        <button className="modal-cancel-btn" onClick={closeformData}>
                            Close
                        </button>
                        <CommonButton
                            isLoading={isWorkflowCreating}
                            title={state?.id == 0 ? "Submit" : 'Update'}
                            handlerFunction={handleSubmit}
                        />
                    </div>
                </Modal.Footer>
            </Modal >
            <Modal
                show={isAddFieldOpen}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="create-workflow-main modal-lg " >
                <Modal.Header>
                    <Modal.Title>
                        <label htmlFor="" className="create-role-text">
                            Field Form
                        </label>
                    </Modal.Title>
                    <img src={close} alt="" onClick={clearFormData} />
                </Modal.Header>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>
                <Modal.Body>
                    <div className="row create-workflow-topbody pb-4">
                        <div className="col-lg-4">
                            <label htmlFor="" className="text-grey">
                                Field Name
                            </label>
                            <input
                                type="text"
                                className="form-control tasKCategory1"
                                placeholder="Field Name"
                                value={taskSate?.name}
                                onChange={(e) => handleChangeTask('name', e.target.value)}
                            />
                            <p className="text-danger">{isTaskAddValided && !taskSate?.name && 'Please Enter Field Name'}</p>
                        </div>
                        <div className="col-lg-4">
                            <label htmlFor="" className="text-grey">
                                Description
                            </label>
                            <input
                                type="text"
                                className="form-control tasKCategory1"
                                placeholder="Description"
                                value={taskSate?.description}
                                onChange={(e) => handleChangeTask('description', e.target.value)}
                            />
                            <p className="text-danger">{isTaskAddValided && !taskSate?.description && 'Please Enter Description'}</p>
                        </div>
                        <div className="col-lg-4">
                            <label htmlFor="" className="text-grey">
                                Field Type
                            </label>
                            <select
                                class="form-select tasKCategory1"
                                aria-label="Default select example"
                                value={taskSate?.taskDataTypeId}
                                onChange={(e) => {
                                    handleChangeTask('taskDataTypeId', Number(e.target.value))

                                    let selected = masterData?.TaskDataTypes?.filter((i) => i?.id == e.target.value)

                                    handleChangeTask('taskDataType', selected[0].value)

                                }}
                            >
                                <option selected value={''} disabled>Select Field Type</option>

                                {
                                    masterData?.TaskDataTypes?.map((type, index) => {
                                        return <option value={type.id} key={index}>{type?.value}</option>
                                    })
                                }

                            </select>
                            <p className="text-danger">{isTaskAddValided && !taskSate?.taskDataTypeId && 'Please Select Task Field Type'}</p>
                        </div>
                        <div className="col-lg-4 mt-4">
                            <label htmlFor="" className="text-grey">
                                Validation
                            </label>
                            <select
                                class="form-select tasKCategory1"
                                aria-label="Default select example"
                                value={taskSate?.taskValidation}
                                onChange={(e) => {
                                    handleChangeTask('taskValidation', Number(e.target.value))

                                    let selected = masterData?.TaskValidationTypes?.filter((i) => i?.id == e.target.value)

                                    handleChangeTask('taskValidationvalue', selected[0].value)
                                }}
                            >
                                <option selected value={''} disabled>Select Validation</option>

                                {
                                    masterData?.TaskValidationTypes?.map((validationType, index) => {
                                        return <option value={validationType.id} key={index}>{validationType?.value}</option>
                                    })
                                }

                            </select>
                            <p className="text-danger">{isTaskAddValided && !taskSate?.taskValidation && 'Please Select Task Validation'}</p>
                        </div>
                        <div className="col-lg-4 mt-4">
                            <label htmlFor="" className="text-grey">
                                Validation If Any
                            </label>
                            <input
                                type="text"
                                className="form-control tasKCategory1"
                                placeholder="Validation If Any"
                                value={taskSate?.validationifany}
                                onChange={(e) => handleChangeTask('validationifany', e.target.value)}
                            />
                            <p className="text-danger"></p>
                        </div>
                        <div className="col-lg-4 mt-4">
                            <label htmlFor="" className="text-grey">
                                Priority Order
                            </label>
                            <input
                                type="number"
                                className="form-control tasKCategory1"
                                placeholder="Enter Priority Order"
                                value={taskSate?.priorityOrder}
                                onChange={(e) => handleChangeTask('priorityOrder', e.target.value)}
                            />
                            <p className="text-danger">{isTaskAddValided && !taskSate?.priorityOrder && 'Please Enter Priority No'}</p>
                        </div>
                    </div>
                </Modal.Body>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>
                <Modal.Footer>
                    <div>
                        <button className="modal-cancel-btn"
                            onClick={clearFormData}
                        >
                            Close
                        </button>
                        <CommonButton
                            isLoading={false}
                            title={'Submit'}
                            handlerFunction={handleAddField}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal
                show={isAddActionOpen}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="create-workflow-main modal-lg " >
                <Modal.Header>
                    <Modal.Title>
                        <label htmlFor="" className="create-role-text">
                            Action Form
                        </label>
                    </Modal.Title>
                    <img src={close} alt="" onClick={clearFormData} />
                </Modal.Header>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>
                <Modal.Body>
                    <div className="row create-workflow-topbody pb-4">

                        <div className="col-lg-4">
                            <label htmlFor="" className="text-grey">
                                Name
                            </label>
                            <input
                                type="text"
                                className="form-control tasKCategory1"
                                placeholder="Enter Name"
                                value={actionData?.name}
                                onChange={(e) => handleChangeAction('name', e.target.value)}
                            />
                            <p className="text-danger">{isAddActionValided && !actionData?.name && 'Please Add Action Name'}</p>
                        </div>

                        <div className="col-lg-4">
                            <label htmlFor="" className="text-grey">
                                Step
                            </label>
                            <select
                                class="form-select tasKCategory1"
                                aria-label="Default select example"
                                value={actionData?.sendToTask}
                                onChange={(e) => handleChangeAction('sendToTask', e.target.value)}
                            >
                                <option selected value={''} disabled>Select Step</option>

                                {

                                    state?.tasks?.map((stpe, indx) => {
                                        return indx <= addDataForIndex && <option value={stpe.name}>{stpe.name}</option>
                                    })
                                }

                            </select>
                            <p className="text-danger">{isAddActionValided && !actionData?.sendToTask && 'Please Select Step'}</p>
                        </div>

                        <div className="col-lg-4">
                            <label htmlFor="" className="text-grey">
                                Step Status
                            </label>
                            <select
                                class="form-select tasKCategory1"
                                aria-label="Default select example"
                                value={actionData?.taskStatusId}
                                onChange={(e) => {

                                    handleChangeAction('taskStatusId', Number(e.target.value))

                                    let name = taskAllocationStatus?.filter((task) => task?.id == e.target.value)

                                    handleChangeAction('taskStatusvalue', name[0].name)

                                }}
                            >
                                <option selected value={''} disabled>Select Step Status</option>
                                {
                                    taskAllocationStatus?.map((status, index) => {
                                        return <option value={status?.id} key={index}>{status?.name}</option>
                                    })
                                }

                            </select>
                            <p className="text-danger">{isAddActionValided && !actionData?.taskStatusId && 'Please Select Step'}</p>
                        </div>

                        <div className="col-lg-4  mt-4 d-flex align-items-center">
                            <input type="checkbox"
                                checked={actionData?.isReasonRequired}
                                onChange={(e) => handleChangeAction('isReasonRequired', e.target.checked)}
                            />
                            <label htmlFor="" className="text-grey me-5 ml-1">
                                Is Reason Required ?
                            </label>
                        </div>
                        <div className="col-lg-4  mt-4 d-flex align-items-center">
                            <input type="checkbox"
                                checked={actionData?.activateNextStep}
                                onChange={(e) => handleChangeAction('activateNextStep', e.target.checked)}
                            />
                            <label htmlFor="" className="text-grey me-5 ml-1">
                                Activate Next Step ?
                            </label>
                        </div>


                    </div>
                </Modal.Body>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>
                <Modal.Footer>
                    <div>
                        <button className="modal-cancel-btn"
                            onClick={clearFormData}
                        >
                            Close
                        </button>
                        <CommonButton
                            isLoading={false}
                            title={'Submit'}
                            handlerFunction={handleAddAction}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isViewWorkFlow}
                className="create-workflow-main modal-lg"
                size="xl">
                <Modal.Header>
                    <Modal.Title>
                        <label htmlFor="" className="create-role-text">
                            WorkFlow Details
                        </label>
                    </Modal.Title>
                    <img src={close} alt=""
                        onClick={() => {
                            setIsViewWorkFlow(false)
                            setState(initialWorkflow)
                        }}
                    />
                </Modal.Header>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>

                <Modal.Body>
                    <div style={{ maxHeight: '580px', overflowX: 'scroll', scrollbarWidth: 'none' }}>
                        <div className="row create-workflow-topbody p-4">
                            <div className="col-lg-6 d-flex justify-content-between">
                                <h5 className="text-muted">Workflow Name : </h5>
                                <h5 className="fw-bold">{state?.name}</h5>
                            </div>
                            <div className="col-lg-3 d-flex justify-content-between">
                                <h5 className="text-muted">Retrospective : </h5>
                                <h5 className="fw-bold">{state?.isRetrospective ? 'Yes' : "No"}</h5>
                            </div>
                            <div className="col-lg-3 d-flex justify-content-between">
                                <h5 className="text-muted">Sequential : </h5>
                                <h5 className="fw-bold">{state?.isSequential ? 'Yes' : "No"}</h5>
                            </div>
                            <div className="col-lg-4 mt-4 d-flex justify-content-between">
                                <h5 className="text-muted">Start Date : </h5>
                                <h5 className="fw-bold">{getDateddmmyyyy(state?.beginDate)}</h5>
                            </div>
                            <div className="col-lg-4 mt-4 d-flex justify-content-between">
                                <h5 className="text-muted">End Date : </h5>
                                <h5 className="fw-bold">{getDateddmmyyyy(state?.endDate)}</h5>
                            </div>
                        </div>

                        <div>
                            <h5 className="fw-bold">Steps </h5>
                        </div>
                        <div className="hr-line-modal">
                            <hr style={{ margin: "0" }} />
                        </div>
                        {
                            state?.tasks?.map((task, index) => {
                                return <div className="row mainFormSection box-shadow mt-3 mb-3 ml-4 mr-4 ">
                                    <div className="col-lg-4 d-flex justify-content-between">
                                        <h5 className="text-muted">Step Name : </h5>
                                        <h5 className="fw-bold">{task?.name}</h5>
                                    </div>
                                    <div className="col-lg-4 d-flex justify-content-between">
                                        <h5 className="text-muted">Owner Roles : </h5>
                                        <h5 className="fw-bold">{task?.ownerRolesName?.map((role) => {
                                            return role
                                        })}</h5>
                                    </div>

                                    <div className="col-lg-4  d-flex justify-content-between">
                                        <h5 className="text-muted">Display Type : </h5>
                                        <h5 className="fw-bold">{task?.displayType}</h5>
                                    </div>
                                    <div className="col-lg-4 mt-3 d-flex justify-content-between">
                                        <h5 className="text-muted">description : </h5>
                                        <h5 className="fw-bold">{task?.displayType}</h5>
                                    </div>
                                    <div>
                                        <h6 className="fw-bold mt-4">Fields :</h6>

                                        <div className="hr-line-modal">
                                            <hr style={{ margin: "0" }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 table-wrapper">
                                        <table>
                                            <tr >
                                                <th>Name</th>
                                                <th>Data Type</th>
                                                <th>Validation</th>
                                                <th>Description</th>
                                            </tr>
                                            {
                                                task?.taskDatas?.map((task, indx) => {
                                                    return <tr className="table-row-custom" key={indx}>
                                                        <td className="send-quote-status">{task?.name}</td>
                                                        <td>{task?.taskDataType}</td>
                                                        <td>{task?.validationData?.map((validation, index) => {
                                                            return <span>{validation?.validationValue},</span>
                                                        })}</td>
                                                        <td>{task?.description}</td>

                                                    </tr>
                                                })
                                            }

                                        </table>
                                    </div>
                                    <div>
                                        <h6 className="fw-bold mt-4">Actions :</h6>

                                        <div className="hr-line-modal">
                                            <hr style={{ margin: "0" }} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 table-wrapper">
                                        <table>
                                            <tr >
                                                <th>Name</th>
                                                <th>Step</th>
                                                <th>Activate Next Step</th>
                                                <th>Reason Required</th>
                                            </tr>
                                            {
                                                task?.actions?.map((action, indx) => {
                                                    return <tr className="table-row-custom" key={indx}>
                                                        <td className="send-quote-status">{task?.name}</td>
                                                        <td>{action?.sendToTask}</td>

                                                        <td>{task?.activateNextStep ? 'Yes' : "No"}</td>
                                                        <td>{task?.isReasonRequired ? 'Yes' : "No"}</td>
                                                    </tr>
                                                })
                                            }

                                        </table>
                                    </div>

                                </div>
                            })
                        }
                    </div>

                </Modal.Body>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>
                <Modal.Footer>
                    <div>
                        <button className="modal-cancel-btn"
                            onClick={() => {
                                setIsViewWorkFlow(false)
                                setState(initialWorkflow)
                            }}
                        >
                            Close
                        </button>

                    </div>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default WorkFlow