import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import "../../assets/css/PaymentReciept.scss";

function AccountsPaymentReciept() {
  const { sidebar } = useContext(AppContext);

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"}  id="cx-main">

    <div
     
    >
      <div className="row">
        <div className="d-flex justify-content-center">
          <div className="col-lg-9 content-small22">
            <div className="payment-reciept-header small-header22">
              <div>
                <p htmlFor="" className="advance-pay-reciept small-heading22">
                  Advance Payment Receipt
                </p>
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <p htmlFor="" className="reciept-number">
                    Receipt No.
                  </p>{" "}
                  <p htmlFor="" className="date-reciept">
                    XYZ123456
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p htmlFor="" className="reciept-number">
                    Date
                  </p>{" "}
                  <p htmlFor="" className="date-reciept">
                    29/09/2022
                  </p>
                </div>
              </div>
            </div>
            <div className="payment-reciept-wrapper">
              <div className="row">
                <div className="col-lg-6 mt-2">
                  <p>Client Name</p>
                  <label htmlFor="">Mr. John Doe</label>
                </div>
                <div className="col-lg-6 mt-2">
                  <p>Branch</p>
                  <label htmlFor="">Mr. John Doe</label>
                </div>
                <div className="col-lg-12 mt-4">
                  <p>Services</p>
                  <label htmlFor="">Designing and Deployment</label>
                </div>
                <div className="col-lg-6 mt-4">
                  <p>Condition for Advance</p>
                  <label htmlFor="">Designing and Deployment</label>
                </div>
                <div className="col-lg-6 mt-4">
                  <p>Reference</p>
                  <label htmlFor="">Designing and Deployment</label>
                </div>
                <div className="col-lg-6 mt-4">
                  <p>Amount</p>
                  <label htmlFor="">Designing and Deployment</label>
                </div>
                <div className="col-lg-6 mt-4">
                  <p>Amount in Words</p>
                  <label htmlFor="">Designing and Deployment</label>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-6 mt-5 ">
                  <p>Authorized By</p>
                  <label htmlFor="">Name and Signature/Stamp</label>
                  <div className="stamp-box small-box22"></div>
                </div>
              </div>
            </div>
            <div className="reciept-footer">
                
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default AccountsPaymentReciept;
