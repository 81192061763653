import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";

import Pagination from "../../../src/sharedComponent/Pagination";

function Subscription() {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClosed = () => setEdit(false);
  const handleEdit = () => setEdit(true);
  const navigate = useNavigate();
  const { sidebar } = useContext(AppContext);
  const [customerDataTable] = useState([
    {
      serial_no: "1",
      title: "Mr",
      first_name: "John",
      middle_name: "Colombia",
      last_name: "ITR TEST 1507 FY2021-2022",
      phone: "29/07/2022",
      email: "4/08/2022",
      status: "De-Active",
    },
    {
      serial_no: "1",
      title: "Mr",
      first_name: "John",
      middle_name: "Colombia",
      last_name: "ITR TEST 1507 FY2021-2022",
      phone: "29/07/2022",
      email: "4/08/2022",
      status: "Active",
    },
  ]);
  return (
    <div className=''>

      <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">

        <div className="role-content">
          <div className="sub-header-role">
            <h6 className="mt-2 role-text">Client Details</h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
            <div className="role-table-header reponsivesrc">
              <div className="search-input-wrapper">
                <input type="text" placeholder="Search" />
                <img src={searchicon} alt="" />
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  class="form-select select-drop-icon remove-border"
                  aria-label="Default select example"
                >
                  <option selected>10</option>
                  <option value="1">20</option>
                  <option value="2">10</option>
                  <option value="3">5</option>
                </select>
              </div>
              <div className="client-wrapper">
                <p>Client Name</p>
                <select
                  class="form-select select-drop-icon remove-border"
                  aria-label="Default select example"
                >
                  <option selected>John Wick</option>
                  <option value="1">John Carter</option>
                  <option value="2">Ethan Hunt</option>
                  <option value="3">Brad Pitt</option>
                </select>
              </div>
            </div>
            <div className="role-btns-wrapper servicemappingbtnexport">
              <button className="import-icon-img">
                <img src={importicon} alt="" />
              </button>
              <button>
                {" "}
                <img src={exporticon} alt="" />
              </button>
            </div>
          </div>
          <div className="table-wrapper">
            <table>
              <tr>
                <th>Sr. no</th>
                <th>Client Name</th>
                <th>Entity</th>
                <th>Branch</th>
                <th>Services</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Action</th>

              </tr>
              {customerDataTable.map((srnumber, index) => {
                return (
                  <tr key={"srnumber" + index} className="table-row-custom">
                    <td>{srnumber.serial_no}</td>
                    <td>{srnumber.title}</td>
                    <td>{srnumber.first_name}</td>
                    <td>{srnumber.middle_name}</td>

                    <td>{srnumber.last_name}</td>
                    <td>{srnumber.phone}</td>
                    <td>{srnumber.email}</td>
                    <td
                      className={
                        srnumber.status === "Active"
                          ? "green-active"
                          : "red-deactive"
                      }
                    >
                      {srnumber.status}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12">
            <Pagination />
          </div>
        </div>
      </div>
    </div>

  )
}

export default Subscription;