import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/Role.scss";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import editicon from "../../assets/images/bx_edit.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import deleteicon from "../../assets/images/delete.svg";
import Modal from "react-bootstrap/Modal";
import close from "../../assets/images/close.svg";
import { AppContext } from "../../context/AppContext";
import Pagination from "../../../src/sharedComponent/Pagination";
import { ToastMsg } from "../TostMsg";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import CommonButton from "../ChatAdmin/button";
import { json } from "react-router-dom";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";

const ServiceGroup = () => {


  const serviceDet = {
    "Id": 0,
    "Name": "",
  }

  const [serviceDetails, setServiceDetails] = useState(serviceDet)
  const [isLoadingAdd, setIsLoadingAdd] = useState(false)
  const [services, setServices] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleClose = () => {

    setServiceDetails(serviceDet)
    setShow(false)

  }
  const handleClosed = () => setEdit(false);

  const handleShow = () => setShow(true);
  const handleEdit = () => setEdit(true);

  const { sidebar } = useContext(AppContext);


  const getServiceList = () => {

    setIsLoading(true)

    simpleGetCallWithErrorResponse(ApiConfig.GET_SERVICE_LIST).then((res) => {

      if (res?.json?.success) {

        setServices(res?.json?.data)

      }
      setIsLoading(false)

    })

  }

  useEffect(() => {
    getServiceList()
  }, [])


  const handleSubmit = () => {

    if (!serviceDetails.Name) {
      ToastMsg('error', 'Please Enter Service Name')
      return
    }

    setIsLoadingAdd(true)

    const url = serviceDetails?.Id === 0 ? ApiConfig.CREATE_SERVICE_GROUP : ApiConfig.UPDATE_SERVICE_GROUP

    PostCallWithErrorResponse(url, JSON.stringify(serviceDetails)).then((res) => {


      if (res?.json?.success) {

        ToastMsg('success', res?.json?.message)

        getServiceList()

        handleClose()



      } else {

        ToastMsg('error', res?.json?.message)

      }

      setIsLoadingAdd(false)

    })

  }

  return (
    <>
      <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">

        <div
        >
          <div className="role-content">
            <div className="sub-header-role">
              <h6 className="mt-2 role-text">Service Group</h6>
              <button className="create-btn-sub-header" onClick={handleShow}>
                Create
              </button>
            </div>
            <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
              <div className="role-table-header reponsivesrc">
                <div className="search-input-wrapper">
                  <input type="text" placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img src={searchicon} alt="" />
                </div>
              </div>
              <div className="role-btns-wrapper">
                <button>
                  <img src={importicon} alt="" />
                </button>
                <button>
                  {" "}
                  <img src={exporticon} alt="" />
                </button>
              </div>
            </div>
            <div className="table-wrapper">
              {
                isLoading ? <Loader /> : services?.length === 0 ? <NoData /> :

                  <table>
                    <tr>
                      <th>Sr. no</th>
                      <th>Group Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>

                    {
                      services.filter((service) => search.length === 0 ? service : service.name.toLowerCase().includes(search.toLowerCase())).map((service, index) => {

                        return <tr className="table-row-custom" key={index} >
                          <td>{index + 1}</td>
                          <td>{service.name}</td>
                          <td className="green-active">Active</td>
                          <td>
                            <img src={editicon} alt="" onClick={() => {

                              setServiceDetails({
                                "Id": service?.id,
                                "Name": service?.name,
                              })

                              handleShow()
                            }} />
                          </td>
                        </tr>

                      })
                    }
                  </table>
              }
            </div>
          </div>

        </div>
      </div >


      {/* Create model  */}

      < Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        className="create-workflow-main"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-workflow-text">
              {serviceDetails?.Id == 0 ? "Create" : "Update"} Service Group
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClose} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body className="">
          <label htmlFor="" className="enter-role-text ">
            Service Group Name
          </label>
          <input type="text"
            className="form-control tasKCategory1"
            value={serviceDetails.Name}
            onChange={(e) => setServiceDetails(prev => ({
              ...prev,
              Name: e.target.value
            }))}
          />
          {/* <select
            class="form-select select-drop-icon-1 service-group-popup"
            aria-label="Default select example"
          >
            <option selected></option>
            <option value="0">Tax Accounting and Management</option>
            <option value="1">Audit</option>
            <option value="2">Financial Accounting</option>
            <option value="3">Management Accounting</option>
            <option value="4">Budget Analysis</option>
            <option value="5">Counselling Assistant</option>
          </select> */}
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn" onClick={handleClose}>Cancel</button>

            <CommonButton
              title={serviceDetails?.Id == 0 ? "Save" : "Update"}
              isLoading={isLoadingAdd}
              handlerFunction={handleSubmit}
            />
          </div>
        </Modal.Footer>
      </Modal >


    </>
  );
};

export default ServiceGroup;
