import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/Role.scss";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import editicon from "../../assets/images/bx_edit.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import rates from "../../assets/images/rates.svg";
import deleteicon from "../../assets/images/delete.svg";
import Modal from "react-bootstrap/Modal";
import close from "../../assets/images/close.svg";
import { AppContext } from "../../context/AppContext";
import Pagination from "../../../src/sharedComponent/Pagination";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import CommonButton from "../ChatAdmin/button";
import redCross from "../../assets/images/redCross.svg";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";

const Service = () => {
  const { sidebar } = useContext(AppContext);

  const initialService = {
    "Id": 0,
    "name": "",
    "groupNameId": '',
    "recurring": false,
    "negotiable": false,
    "allocatableToBranch": false,
    "recheckRequired": false,
    "serviceDes": [
      {
        "id": 0,
        "description": ""
      }
    ],
    "workflowId": 0,
    "addPeriods": [
      {
        "id": 0,
        "name": "",
        "dueDate": "",
        "expiryDate": "",
        "sequence": '',
        "validator": null,
        "asyncValidator": null,
        "dueDates": "",
        "expiryDates": ""
      }
    ],
    "RemovePeriods": [],
    "RemoveServiceDes": []
  }

  const [search, setSearch] = useState('')
  const [services, setServices] = useState([])
  const [curentPage, setCurentPage] = useState(1)
  const [state, setState] = useState(initialService)
  const [servicesGroups, setServicesGroups] = useState([])
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [priviwe, setpriviwe] = useState(false);
  const [rate, setRate] = useState(false);
  const [isValided, setIsValided] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingAoi, setIsLoadingApi] = useState(false)
  const [periods, setPeriods] = useState([])

  function validateDate(date) {
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])$/;
    return regex.test(date);
  }


  const handleChange = (key, val) => {

    setState(prev => ({
      ...prev,
      [key]: val
    }))

  }

  //group
  const getServiceList = () => {

    if (servicesGroups.length) return


    simpleGetCallWithErrorResponse(ApiConfig.GET_SERVICE_LIST).then((res) => {

      if (res?.json?.success) {

        setServicesGroups(res?.json?.data)

      }


    })

  }

  const getService = () => {


    setIsLoading(true)

    simpleGetCallWithErrorResponse(ApiConfig.GET_SERVICES_CLIENT).then((res) => {

      if (res?.json?.success) {

        setServices(res?.json?.data)

      }

      setIsLoading(false)

    })


  }

  useEffect(() => {
    getService()
  }, [])

  const handleNext = () => {

    let isValid = true

    if (!state?.name) {

      isValid = false

      setIsValided(true)

    }

    if (state?.groupNameId == 0) {
      isValid = false
      setIsValided(true)
    }

    if (!state?.negotiable && !state?.recheckRequired && !state?.recurring && !state?.allocatableToBranch) {

      isValid = false

      setIsValided(true)

    }

    if (isValid) {


      setCurentPage(2)

      setIsValided(false)

    }

  }

  const handleSubmit = () => {

    let isValid = true;

    state.addPeriods.forEach((period, index) => {



      if (
        !period.name ||
        !period.dueDate ||
        !period.expiryDate ||
        !period.sequence

      ) {
        isValid = false

        setIsValided(true)


      }

      if (period.dueDate || period.expiryDate) {

        if (!validateDate(period.dueDate) || !validateDate(period.expiryDate)) {
          isValid = false
          setIsValided(true)
        }

      }





    })

    if (isValid) {

      setIsLoadingApi(true)

      const url = state.Id == 0 ? ApiConfig.CREATE_SERVICE : ApiConfig.UPDATE_SERVICE
      PostCallWithErrorResponse(url, JSON.stringify(state)).then((res) => {


        if (res?.json?.success) {
          ToastMsg('success', res?.json?.message)

          handleClose()
          getService()
        } else {
          ToastMsg('error', res?.json?.message)
        }

        setIsLoadingApi(false)

      })
    }


  }


  const handleRate = () => setRate(true);
  const handleRateClose = () => setRate(false);

  const addPeriod = () => {

    setState(prev => ({
      ...prev,
      addPeriods: [...state.addPeriods, {
        "id": 0,
        "name": "",
        "dueDate": "",
        "expiryDate": "",
        "sequence": '',
        "validator": null,
        "asyncValidator": null,
        "dueDates": "",
        "expiryDates": "",
      }]
    }))

  }

  const removePeriods = (index) => {


    let periods = [...state.addPeriods];

    if ('isDeleted' in periods[index]) {

      periods[index].isDeleted = true

    } else {
      periods.splice(index, 1);
    }

    setState({ ...state, addPeriods: periods })

  }

  const handlePeroid = (key, val, index) => {

    const updatePeriods = [...state.addPeriods]

    updatePeriods[index] = {
      ...updatePeriods[index],
      [key]: val
    }

    setState(prev => ({
      ...prev,
      addPeriods: updatePeriods
    }))

  }


  const handleClose = () => {

    setShow(false)

    setCurentPage(1)

    setState(initialService)

    setIsValided(false)

  }
  const handleClosed = () => setEdit(false);
  const priveclose = () => setpriviwe(false);

  const handleShow = () => {

    getServiceList()

    setShow(true)

  }

  useEffect(() => { }, [state.addPeriods])


  const getSinglePeriod = (id) => {

    simpleGetCallWithErrorResponse(ApiConfig.GET_SINGEL_SERVICE + id).then((res) => {

      if (res?.json?.success) {

        setState(res?.json?.data)

        handleShow()

      }

    })

  }




  const handlePriviwe = () => setpriviwe(true);
  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div
      >
        <div className="role-content">
          <div className="sub-header-role">
            <h6 className="mt-2 role-text">Service </h6>
            <button className="create-btn-sub-header" onClick={handleShow}>
              Create
            </button>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
            <div className="role-table-header reponsivesrc">
              <div className="search-input-wrapper">
                <input type="text" placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <img src={searchicon} alt="" />
              </div>
            </div>
            <div className="role-btns-wrapper">
              <button>
                <img src={importicon} alt="" />
              </button>
              <button>
                {" "}
                <img src={exporticon} alt="" />
              </button>
            </div>
          </div>
          <div className="table-wrapper">
            {
              isLoading ? <Loader /> :
                services.length == 0 ?
                  <NoData /> :
                  <table>
                    <tr>
                      <th>Sr. no</th>
                      <th>Service Name</th>
                      <th>Service Group</th>
                      <th>Allocable to Branch</th>
                      <th>Negotiable</th>
                      <th>Recheck Required</th>
                      <th>Period</th>
                      <th>Recurring</th>
                      <th>Workflow</th>
                      <th>Rates</th>
                      <th>Action</th>
                    </tr>

                    {
                      services.filter((service) => {
                        return search.length ? service.name.toLowerCase().includes(search.toLowerCase()) : service
                      }).map((service, index) => {
                        return <tr className="table-row-custom" key={index}>
                          <td>{index + 1}</td>
                          <td>{service?.name}</td>
                          <td>{service?.groupName}</td>
                          <td>{service?.branchAllocatable ? "Yes" : 'No'}</td>
                          <td>{service?.negotiable ? "Yes" : 'No'} </td>
                          <td>{service?.recheckRequired ? "Yes" : 'No'} </td>
                          <td>
                            <img
                              src={eye_icon}
                              alt="Pri"
                              className="me-2"
                              onClick={() => {

                                setPeriods(service.periods)
                                handlePriviwe()
                              }}
                            />
                          </td>
                          <td>{service?.recurring ? "Yes" : 'No'}</td>
                          <td>{service?.workflowId}</td>
                          <td>
                            <img src={rates} alt="" onClick={handleRate} />
                          </td>
                          <td>
                            <img src={editicon} alt="" onClick={() => getSinglePeriod(service?.id)} />
                          </td>
                        </tr>
                      })
                    }

                  </table>
            }
          </div>
        </div>

      </div>


      <Modal
        aria-labelledby="contained-modal-title-vcenter "
        centered
        show={priviwe}
        className="create-workflow-main modal-lg"
      >
        <Modal.Header className=" create-workflow-topbody">
          <Modal.Title>
            <label htmlFor="" className="create-workflow-text">
              Periods
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={priveclose} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>

          <div className="table-wrapper">
            {
              periods.length == 0 ? <NoData /> : <table>
                <tr>
                  <th>Sr. no</th>
                  <th>Period Name</th>
                  <th>Due Date </th>
                  <th>Expiry Date </th>
                </tr>
                {
                  periods.map((period, index) => {
                    return <tr>
                      <th>{index + 1}</th>
                      <th>{period?.name}</th>
                      <th>{period?.dueDate}</th>
                      <th>{period?.expiryDate}</th>
                    </tr>
                  })
                }
              </table>

            }
          </div>

        </Modal.Body>

      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        className="create-workflow-main modal-lg"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-workflow-text">
              {state.Id == 0 ? "Create" : "Update"} Service Details
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClose} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="row create-workflow-topbody pb-4" style={{ maxHeight: '600px', overflow: 'scroll', scrollbarWidth: 'none' }}>
            {
              curentPage == 1 &&
              <>
                <div className="col-lg-6 mt-4">
                  <label htmlFor="" className="label-text">
                    Service Name
                  </label>
                  <input type="text"
                    className="form-control tasKCategory1"
                    value={state.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                  />
                  {
                    isValided && !state?.name && <div className="text-danger">Please Enter Name</div>
                  }

                </div>
                <div className="col-lg-6 mt-4">
                  <label htmlFor="" className="label-text">
                    Service Group
                  </label>
                  <select
                    class="form-select select-drop-icon"
                    aria-label="Default select example"
                    value={state.groupNameId}
                    onChange={(e) => handleChange('groupNameId', Number(e.target.value))}
                  >
                    <option selected>Select Service Group</option>
                    {
                      servicesGroups.map((group) => {
                        return <option value={group?.id}>{group?.name}</option>
                      })
                    }
                  </select>
                  {
                    isValided && state?.groupNameId == 0 && <div className="text-danger">Please Select Group</div>
                  }
                </div>
                <div className="col-lg-6 mt-4">
                  <label htmlFor="" className="label-text">
                    Service Description
                  </label>

                  <textarea name="" id="" placeholder="Service Description"
                    className="form-control tasKCategory1"
                    minLength={3}
                    style={{ height: '90px' }}
                    value={state?.serviceDes[0]?.description}
                    onChange={(e) => {
                      setState(prev => ({
                        ...prev,
                        serviceDes: [{
                          ...state?.serviceDes[0],
                          description: e.target.value
                        }]
                      }))
                    }}
                  ></textarea>
                </div>
                <div className="col-lg-6 mt-4">
                  <label htmlFor="" className="label-text">
                    Service Type
                  </label>
                  <div>
                    <div>
                      <input type="checkbox"
                        checked={state.allocatableToBranch}
                        onChange={(e) => handleChange('allocatableToBranch', e.target.checked)}
                      />
                      <span className="ml-4">Allocatable To Branch?</span>
                    </div>

                    <div>
                      <input type="checkbox"
                        checked={state.recheckRequired}
                        onChange={(e) => handleChange('recheckRequired', e.target.checked)} />
                      <span className="ml-4">Recheck Requird?</span>
                    </div>

                    <div>
                      <input type="checkbox"
                        checked={state.recurring}
                        onChange={(e) => handleChange('recurring', e.target.checked)} />
                      <span className="ml-4">Recurring?</span>
                    </div>

                    <div>
                      <input type="checkbox"
                        checked={state.negotiable}
                        onChange={(e) => handleChange('negotiable', e.target.checked)} />
                      <span className="ml-4">Negotiable?</span>
                    </div>
                    {
                      isValided && !state.negotiable && !state.recheckRequired && !state.recurring && !state.allocatableToBranch && <div className="text-danger">Please Select Any One</div>
                    }
                  </div>
                </div>
              </>

            }
            {
              curentPage == 2 && <>
                {
                  state.addPeriods.filter((service) => {
                    return !service.isDeleted && service
                  }).map((service, index) => {

                    return <>
                      {
                        index != 0 && <div className="col-md-12 d-flex justify-content-end mt-4">
                          <img
                            src={redCross}
                            alt=""
                            onClick={() => removePeriods(index)}
                          />
                        </div>
                      }
                      <div className="col-md-12">
                        <h4 className="mt-2 fw-bold"
                        >Period {index + 1}
                        </h4>
                      </div>
                      <div className="col-lg-6 mt-4">
                        <label htmlFor="" className="label-text">
                          Period Name
                        </label>
                        <input type="text"
                          className="form-control tasKCategory1"
                          value={service?.name}
                          onChange={(e) => handlePeroid('name', e.target.value, index)}
                        />
                        {
                          isValided && !service?.name && <div className="text-danger">Please Enter Name</div>
                        }

                      </div>
                      <div className="col-lg-6 mt-4">
                        <label htmlFor="" className="label-text">
                          Sequence
                        </label>
                        <input type="number"
                          className="form-control tasKCategory1"
                          value={service.sequence}
                          onChange={(e) => handlePeroid('sequence', Number(e.target.value), index)}
                        />
                        {
                          isValided && !service?.sequence && <div className="text-danger">Please Enter Name</div>
                        }

                      </div>
                      <div className="col-lg-6 mt-4">
                        <label htmlFor="" className="label-text">
                          Due Date
                        </label>
                        <input type="text"
                          className="form-control tasKCategory1"
                          placeholder="DD/MM"
                          value={service.dueDate}
                          onChange={(e) => {
                            handlePeroid('dueDate', e.target.value, index)



                          }}
                        />
                        {
                          isValided && !validateDate(service?.dueDate) && <div className="text-danger">Please Valid Date</div>
                        }

                      </div><div className="col-lg-6 mt-4">
                        <label htmlFor="" className="label-text">
                          Expiry Date
                        </label>
                        <input
                          type="text"
                          className="form-control tasKCategory1"
                          placeholder="DD/MM"
                          value={service.expiryDate}
                          onChange={(e) => {
                            handlePeroid('expiryDate', e.target.value, index)





                          }}
                        />
                        {
                          isValided && !validateDate(service?.expiryDate) && <div className="text-danger">Please Enter Valid Date</div>
                        }

                      </div>
                      {

                      }
                    </>

                  })
                }
                <div className="d-flex justify-content-end mt-4 mb-4"

                >
                  <button
                    className="impButton"
                    onClick={addPeriod}
                  >
                    Add New
                  </button>
                </div>
              </>
            }

          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn" onClick={handleClose}>Cancel</button>
            {
              curentPage == 2 &&
              <button className="AddQuestion_submit_btn mx-3" onClick={() => setCurentPage(1)}>Previous</button>
            }
            <CommonButton
              title={curentPage == 1 ? "Next" : state.Id == 0 ? 'Submit' : 'Update'}
              isLoading={isLoadingAoi}
              handlerFunction={curentPage == 1 ? handleNext : handleSubmit}
            />


          </div>
        </Modal.Footer>
      </Modal>

      {/* sdit model */}



      {/* {rate modal} */}
      <Modal

        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={rate}
        className="create-workflow-main modal-xl"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Rate Table
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleRateClose} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="table-wrapper">
            <table>
              <tr>
                <th>Sr. no</th>
                <th>Service Name</th>
                <th>Financial Year</th>
                <th>Base Fee</th>
                <th>Basis for Increase</th>
                <th>Base Threshold</th>
                <th>Step</th>
                <th>Increase per Step</th>
              </tr>
              <tr className="table-row-custom">
                <td>1</td>
                <td>Advice on FERA</td>
                <td>2022-2023</td>
                <td>500</td>
                <td>Business Turnover </td>
                <td>200</td>
                <td>2</td>
                <td>500</td>

              </tr>
              <tr className="table-row-custom">
                <td>2</td>
                <td>Corporate Law Consultancy </td>
                <td>2022-2023</td>
                <td>500</td>
                <td>No. of TransactionEntries </td>
                <td>500</td>
                <td>4</td>
                <td>200</td>

              </tr>
              <tr className="table-row-custom">
                <td>3</td>
                <td>Advice on FERA</td>
                <td>2022-2023</td>
                <td>500</td>
                <td>Business Turnover </td>
                <td>200</td>
                <td>2</td>
                <td>500</td>

              </tr>
              <tr className="table-row-custom">
                <td>4</td>
                <td>Corporate Law Consultancy</td>
                <td>2022-2023</td>
                <td>500</td>
                <td>Business Turnover </td>
                <td>200</td>
                <td>2</td>
                <td>500</td>

              </tr>
              <tr className="table-row-custom">
                <td>5</td>
                <td>Advice on FERA</td>
                <td>2022-2023</td>
                <td>500</td>
                <td>Business Turnover </td>
                <td>200</td>
                <td>2</td>
                <td>500</td>

              </tr>
              <tr className="table-row-custom">
                <td>6</td>
                <td>Corporate Law Consultancy</td>
                <td>2022-2023</td>
                <td>500</td>
                <td>Business Turnover </td>
                <td>200</td>
                <td>2</td>
                <td>500</td>

              </tr>
            </table>
          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn" onClick={handleRateClose}>Cancel</button>
            <button className="modal-create-btn middbtn">Save & Proceed</button>
          </div>
        </Modal.Footer>
      </Modal>
    </div >
  );
};

export default Service;
