import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";

const FrequentCaller = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  // show button
  const [frequentCall, setFrequentCallList] = useState([])
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterSerchName, setFilterSerchName] = useState([])
  const [loading, setLoading] = useState(false)


  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  // const handleSelectChange = (event) => {
  //   setSelectedRows(event.target.value);
  //   console.log(`Selected number of rows: ${event.target.value}`);
  //   // You can add additional logic to handle the row change
  // };
  // show button end

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const crmFrequentCallerData = [
    {
      phoneNumber: "+919309482204",
      counts: "6",
    },
    {
      phoneNumber: "+918765432109",
      counts: "5",
    },
    {
      phoneNumber: "+917654321098",
      counts: "4",
    },
    {
      phoneNumber: "+916543210987",
      counts: "2",
    },
    {
      phoneNumber: "+915432109876",
      counts: "3",
    },
    {
      name: "+914321098765",
      phoneNumber: "+918765432109",
      counts: "6",
    },
    {
      phoneNumber: "+914321098765",
      counts: "6",
    },
    {
      phoneNumber: "+912109876543",
      counts: "2",
    },
  ];


  const getAgentData = () => {
    setLoading(true)

    simpleGetCallWithToken(ApiConfig.GET_FREQUEN_CALLER_LIST)
      .then((response) => {
        console.log(response.results)
        setFrequentCallList(response.calls)
        setLoading(false)

      })
      .catch((error) => {
        console.log(error)
        setLoading(false)

      })
  }

  useEffect(() => {
    getAgentData()
  }, [])


  useEffect(() => {
    // Otherwise, use location_list

    const filteredData = frequentCall?.filter((item) =>
      item?.phone_no?.toLowerCase().includes(searchValue.toLowerCase())
    );

    console.log("Filtered Data>>", filteredData);
    setFilterSerchName(filteredData); // Set the filtered data in the state

  }, [frequentCall, searchValue,]);

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Frequent Caller</h6>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text"
                placeholder="Search here"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setCurrentPage(0);
                }}
              />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setUsersPerPage(e.target.value);
                  setCurrentPage(0);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value={25} selected>25</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            {
              permissions && permissions['View Frequent Caller Export'] &&

              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            }
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">PHONE NUMBER</th>
                <th scope="col">COUNTS</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <Loader />
                  </td>
                </tr>
              ) : (
                filterSerchName.length > 0 ? (
                  filterSerchName?.slice(startIndex, endIndex)?.map((data, index) => (
                    <tr key={index} className="table-row-custom">
                      <td>{data.phone_no}</td>
                      <td>{data.the_count}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="ErrorDataNotFound">
                    <td colSpan={9}>
                      <NoData />
                    </td>
                  </tr>
                )
              )
              }
            </tbody>
          </table>
          <Pagination
            data={frequentCall}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={filterSerchName}
          />
        </div>
      </div>
    </div>
  );
};

export default FrequentCaller;
