import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Pagination from "../../sharedComponent/Pagination";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";

const CallReport = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [currentPage, setCurrentPage] = useState(0);
      const [dataList, setDataList] = useState([])
      const [loading, setLoading] = useState(false)
  

      const pageChangeHandler = ({ selected }) => {
        setCurrentPage(selected);
      };
  // show button end

  const [activeTab, setActiveTab] = useState("Inbound");

  const inboundData = [
    {
      srNo: "1",
      type: "Agent",
      callCount: "19",
    },
    {
      srNo: "2",
      type: "App_User",
      callCount: "0",
    },
    {
      srNo: "3",
      type: "Candidate",
      callCount: "2412",
    },
    {
      srNo: "4",
      type: "Hire_Team_Lead",
      callCount: "1",
    },
    {
      srNo: "5",
      type: "Inquiry",
      callCount: "6572",
    },
    {
      srNo: "6",
      type: "Lead",
      callCount: "149",
    },
    {
      srNo: "7",
      type: "Prospect",
      callCount: "204",
    },
    {
      srNo: "8",
      type: "Spam",
      callCount: "3308",
    },
    {
      srNo: "9",
      type: "Un-classified",
      callCount: "497",
    },
  ];
  const outboundData = [
    {
      srNo: "1",
      type: "Candidate",
      callCount: "6112",
    },
    {
      srNo: "2",
      type: "User",
      callCount: "2057",
    },
    {
      srNo: "3",
      type: "Candidate",
      callCount: "4075",
    },
  ];

  
  const getAgentData = () => {
    setLoading(true)

    simpleGetCallWithToken(ApiConfig.CALL_REPORTS+activeTab)
      .then((response) => {
        console.log(response)
        setDataList(response)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getAgentData()
  }, [activeTab])

  const dataToDisplay = activeTab === "inbound" ? inboundData : outboundData;

  const totalCallCount = dataToDisplay.reduce(
    (total, item) => total + parseInt(item.callCount),
    0
  );

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role">
          <h6 className="mt-2">Call Report</h6>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary cus-mt">
          <div className="role-table-header accoridance">
            <div className="search-input-wrapper allAcordiance">
              <button
                className={`inBound-btn-sub-header ${
                  activeTab === "Inbound" ? "active" : ""
                }`}
                onClick={() => setActiveTab("Inbound")}
              >
                Inbound
              </button>
            </div>
            <div className="entries-wrapper myAccordiance">
              <button
                className={`outBound-btn-sub-header ${
                  activeTab === "Outbound" ? "active" : ""
                }`}
                onClick={() => setActiveTab("Outbound")}
              >
                Outbound
              </button>
            </div>
          </div>
          <div className="entries-wrapper myAccordiance">
            <p>
              <b className="me-2">Total Call Count:</b> {dataList?.all_count}
            </p>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">SR.NO</th>
                <th scope="col">TYPE</th>
                <th scope="col">CALL COUNT</th>
              </tr>
            </thead>
            <tbody>
              {
                loading?(
                  <tr>
                  <td colSpan="9" className="text-center">
                    <Loader />
                  </td>
                </tr>
                ):(
              dataList?.reports?.map((data, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{index + 1}</td>
                  <td>{data.name}</td>
                  <td>{data.count}</td>
                </tr>
              ))
            )
            }
              <tr className="table-row-custom">
                <td></td>
                <td></td>
                <td>Total: {dataList?.all_count}</td>
              </tr>
            </tbody>
          </table>
          {/* <Pagination 
                 data={dataList?.reports}
                 pageChangeHandler={pageChangeHandler}
                //  usersPerPage={usersPerPage}
                 currentPage={currentPage}
                //  searchValue={searchValue}
                 searchedDataPageCounts={dataList?.reports}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default CallReport;
