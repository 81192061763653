import React, { useContext, useState } from "react";
import "../../assets/css/Role.scss";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import editicon from "../../assets/images/bx_edit.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import close from "../../assets/images/close.svg";
import deleteicon from "../../assets/images/delete.svg";
import Modal from "react-bootstrap/Modal";
import { AppContext } from "../../context/AppContext";
import dropicon from "../../assets/images/circle_drop.svg";
import Pagination from "../../../src/sharedComponent/Pagination";


const QuotationStatus = () => {
    const { sidebar } = useContext(AppContext);
    

    return (
        <div className={sidebar ? "taskMain " : "cx-active taskMain"}  id="cx-main">
            <div
              
            >
                <div className="role-content">
                    <div className="sub-header-role">
                        <h6 className="mt-2 role-text">Quote Trial Audit</h6>
                    </div>
                    <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder">
                        <div className="role-table-header reponsivesrc">
                            <div className="search-input-wrapper">
                                <input type="text" placeholder="Search" />
                                <img src={searchicon} alt="" />
                            </div>
                            <div className="entries-wrapper">
                                <p>Entries per page</p>
                                <select
                                    class="form-select select-drop-icon remove-border"
                                    aria-label="Default select example"
                                >
                                    <option selected>10</option>
                                    <option value="1">20</option>
                                    <option value="2">10</option>
                                    <option value="3">5</option>
                                </select>
                            </div>
                        </div>
                        <div className="role-btns-wrapper">
                            <button>
                                <img src={importicon} alt="" />
                            </button>
                            <button>
                                {" "}
                                <img src={exporticon} alt="" />
                            </button>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <table>
                            <tr>
                                <th>Sr. no</th>
                                <th>Status</th>
                                <th>Consultant Name</th>
                                <th>Client Name</th>
                                <th>Date of Quote</th>
                                <th>Quote Amount</th>
                                <th>Quote Status</th>
                            </tr>
                            <tr className="table-row-custom">
                                <td>1</td>
                                <td className="send-quote-status">Send</td>
                                <td>Joan Dev</td>
                                <td>Jimmy Carter</td>
                                <td>11/03/2021</td>
                                <td>850</td>
                                <td className="red-deactive">Rejected</td>
                            </tr>
                            <tr className="table-row-custom">
                                <td>2</td>
                                <td className="send-quote-status">Send</td>

                                <td>Joan Dev</td>
                                <td>Jimmy Carter</td>
                                <td>05/03/2021</td>
                                <td>700</td>
                                <td className="red-deactive">Rejected</td>
                            </tr>
                            <tr className="table-row-custom">
                                <td>3</td>
                                <td className="send-quote-status">Send</td>

                                <td>Joan Dev</td>
                                <td>Jimmy Carter</td>
                                <td>28/06/2022</td>
                                <td>500</td>
                                <td className="green-active">Accepted</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <Pagination />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuotationStatus