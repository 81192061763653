import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import { Accordion } from "react-bootstrap";
import DynamicField from "../../sharedComponent/dynamicField";
import CommonButton from "../ChatAdmin/button";

function CompleteTaskDynamic() {
    const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
        useContext(AppContext);

    const { id, taskAllocationId } = useParams()

    const navigate = useNavigate()

    const [valudationMessage, setValidationMessage] = useState({})
    const [isLoading, setIsLoading] = useState(false)


    const initialPayload = {
        "taskId": '',
        "taskAllocationId": '',
        "data": [

            {
                "key": "resion",
                "value": ""
            },
            {
                "key": "action",
                "value": ""
            }
        ],
        "action": "",
        "resion": "",
        "isAdministrative": false
    }

    const [payload, setPayload] = useState(initialPayload)

    const [action, setAction] = useState()

    const [isValided, setIsvalided] = useState(false)

    const [taskDeatils, setTaskDetails] = useState({})
    const [dynamicFields, setDynamicsFields] = useState([])

    const getDynamicFields = () => {


        PostCallWithErrorResponse(ApiConfig.GET_DYNAMIC_FIELDS_FOR_TASK, JSON.stringify({
            "Id": Number(id),
            "IsAdministrative": false
        })).then((res) => {

            if (res?.json?.success) {
                setDynamicsFields(res?.json?.data)
            }

        })

    }

    const getTaskDetails = () => {

        simpleGetCallWithErrorResponse(ApiConfig.GET_TASK_DETAILS + id).then((res) => {

            if (res?.json?.success) {
                setTaskDetails(res?.json?.data)
            } else {
                ToastMsg('error', res?.json?.message)
            }

        })

    }

    useEffect(() => {

        getDynamicFields()

        getTaskDetails()

    }, [])

    const handleAddTask = (key, val) => {

        console.log(key, val);

        let indexOf = payload?.data?.findIndex((i, index) => {
            return i.key == key
        })

        if (indexOf != -1) {

            let updateData = [...payload?.data]

            updateData[indexOf].value = val + ''



            console.log(updateData);

            setPayload((prev) => ({
                ...prev,
                data: updateData
            }))


        } else {

            let updateData = [...payload?.data, {
                'key': key,
                value: val
            }]

            setPayload(prev => ({
                ...prev,
                data: updateData
            }))

        }

    }

    const handleAddAction = (id) => {

        let indexOf = payload?.data?.findIndex((i, index) => {
            return i.key == 'action'
        })
        let updateData = [...payload?.data]

        updateData[indexOf].value = id + ''

        let isValid = true;

        setPayload(prev => ({
            ...prev,
            'action': "" + id,
            data: updateData
        }))

    }



    const handleSubmit = () => {

        if (!payload?.action) {
            ToastMsg('error', 'please Select Action')
            return
        }




        let isValid = true;

        dynamicFields[0]?.taskDataToUIDtos?.forEach((i) => {



            if (i.validations != 'none') {

                if (i?.validations[0]?.name == "Required") {

                    let findStoreValue = payload?.data?.filter((val) => val.key == i?.id)


                    if (findStoreValue.length == 0 || findStoreValue[0]?.value?.length == 0) {
                        isValid = false
                        setIsvalided(true)
                        setValidationMessage(prev => ({
                            ...prev,
                            [i.id + '']: i?.description,
                        }))
                    } else {
                        setValidationMessage(prev => ({
                            ...prev,
                            [i.id + '']: "",
                        }))
                    }

                }

            }

        })

        if (isValid) {

            setIsLoading(true)

            payload.taskId = Number(taskAllocationId)

            payload.taskAllocationId = Number(id);



            PostCallWithErrorResponse(ApiConfig?.SUBMIT_DYNAMIC_TASK, JSON.stringify(payload)).then((res) => {

                if (res?.json?.success) {
                    ToastMsg('success', res?.json?.message)
                    navigate('/CompleteTask')
                } else {
                    ToastMsg('error', res?.json?.message)
                }

                setIsLoading(false)

            })

        }



    }

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >

                <div className="role-content">
                    <div className="sub-header-role ">
                        <h6 className="mt-2">Workflow Task Form</h6>
                    </div>

                    <div className='bg-white p-3 rounded-3 mt-4'>
                        <div className="mt-4 row mainFormSection box-shadow mr-4 p-4">
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Workflow Name : </h5>
                                <h5 className="fw-bold">{taskDeatils?.workFlowName}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Status : </h5>
                                <h5 className="fw-bold">{taskDeatils?.status}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Sequence No : </h5>
                                <h5 className="fw-bold">{taskDeatils?.sequenceNumber}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Date : </h5>
                                <h5 className="fw-bold">{taskDeatils?.date}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Description : </h5>
                                <h5 className="fw-bold">{taskDeatils?.description}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Display Type : </h5>
                                <h5 className="fw-bold">{taskDeatils?.displayType}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Subscription : </h5>
                                <h5 className="fw-bold">{taskDeatils?.subscription}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Service : </h5>
                                <h5 className="fw-bold">{taskDeatils?.service}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Quotation : </h5>
                                <h5 className="fw-bold">{taskDeatils?.quotation}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Branch Name : </h5>
                                <h5 className="fw-bold">{taskDeatils?.branchName}</h5>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Client Name  : </h5>
                                <h5 className="fw-bold">{taskDeatils?.client?.firstName + ' ' + taskDeatils?.client?.lastName}</h5>
                            </div><div className="col-lg-4 d-flex justify-content-between">
                                <h5 className="text-muted">Status : </h5>
                                <h5 className="fw-bold">{taskDeatils?.status}</h5>
                            </div>

                        </div>

                        <Accordion defaultActiveKey={0}>
                            {
                                dynamicFields?.map((task, index) => {

                                    return <Accordion.Item eventKey={index} key={index} className="mt-2">
                                        <Accordion.Header>
                                            <div className="menus-items w-100 d-flex justify-content-between" style={{ backgroundColor: 'whiteSmoke', padding: '15px 15px 15px 15px' }}>
                                                <h5 className=""> {task?.taskDataToUIDtos[0]?.taskName} </h5>

                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row m-4">
                                                {
                                                    task?.taskDataToUIDtos?.map((field) => {

                                                        return <div className="col-lg-4">
                                                            <DynamicField
                                                                label={field?.name}
                                                                placeholder={field?.description}
                                                                required={field?.validations[0]?.name}
                                                                type={field?.dataType?.toLowerCase()}
                                                                keyName={field?.id + ''}
                                                                handleAddTask={handleAddTask}
                                                                value={payload?.data?.filter((i) => i.key == field?.id)[0]?.value}
                                                                taskId={field?.taskId}
                                                                isValided={isValided}
                                                                validations={valudationMessage}
                                                            />
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            <div className="row m-4">
                                                {
                                                    task?.taskActions?.map((action, index) => {
                                                        return <div className="col-lg-3" > <button
                                                            className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                                                            onClick={() => {

                                                                handleAddAction(action?.id)


                                                            }}

                                                        >{action?.name}</button></div>
                                                    })
                                                }
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <CommonButton
                                                    title={'Submit'}
                                                    handlerFunction={handleSubmit}
                                                    isLoading={isLoading}
                                                />
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                })
                            }
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompleteTaskDynamic