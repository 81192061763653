import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { useParams } from "react-router-dom";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { Form, Modal } from "react-bootstrap";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";

export default function ViewTransactions() {
  const { sidebar } = useContext(AppContext);
  const { id } = useParams();

  const [loader, setLoader] = useState(null);

  const [transName, setTranName] = useState({
    masterName: "",
    groupType: "",
  });
  const [transData, SetTransData] = useState([]);

  const getAlltransactions = () => {
    setLoader(true);
    PostCallWithErrorResponse(
      `${ApiConfig.GET_ALL_TRANSACTION}?id=${id}`,
      JSON.stringify({})
    )
      .then((resp) => {
        setLoader(false);
        console.log(resp);
        if (resp?.json?.Success && resp?.json?.Data?.length > 0) {
          setTranName((res) => ({
            ...res,
            masterName: resp?.json?.Data[0]?.LedgerMasterName || "",
            groupType: resp?.json?.Data[0]?.GroupType || "",
          }));
          SetTransData(resp?.json?.Data);
          console.log("success");
        } else {
          console.log("No data found");
          SetTransData([]);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAlltransactions();
  }, []);

  const [allLedgers, setAllLedgers] = useState([]);

  const [createTransaction, setCreateTransaction] = useState({
    ledgerName: id,
    amount: null,
    effecttype: null,
  });
  const handleCreateTransaction = (e) => {
    const { value, name } = e.target;
    // if (name === "effecttype") {
    //   setCreateTransaction((prev) => ({
    //     ...prev,
    //     [name]: Number(value),
    //   }));
    // } else {
    setCreateTransaction((prev) => ({
      ...prev,
      [name]: Number(value),
    }));
    // }
  };
  const [showTransaction, setShowTransaction] = useState(null);
  const handleShowTransaction = () => {
    setShowTransaction(true);
    simpleGetCallWithToken(ApiConfig.GET_LEDGER_LIST)
      .then((res) => {
        if (res.Success) {
          setAllLedgers(res.Data);
        } else {
          setAllLedgers([]);
        }
      })
      .catch((err) => console.log(err));
  };
  const createTheTransaction = () => {
    console.log(createTransaction);
    PostCallWithErrorResponse(
      ApiConfig.CREATE_TRANSACTION,
      JSON.stringify({
        LedgerId: Number(id),
        Amount: createTransaction.amount,
        EffectType: createTransaction.effecttype,
      })
    )
      .then((res) => {
        console.log(res);

        if (res?.json?.Success) {
          ToastMsg("success", res?.json?.Message);
          handleCloseTransaction();
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 2000);
          getAlltransactions();
          setCreateTransaction({
            ledgerName: id,
            amount: null,
            effecttype: null,
          });
        } else {
          ToastMsg("error", res?.json?.Message);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCloseTransaction = () => {
    setShowTransaction(false);
  };

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const handleToDate = (e) => {
    setToDate(e.target.value);
  };

  const [filteredTransc, setFilteredTransc] = useState([]);
  const [filterloader, setFilterLoader] = useState(null);
  const handleSearchDateData = () => {
    console.log("from", fromDate);
    console.log("to", toDate);
    setFilterLoader(true);

    PostCallWithErrorResponse(
      `${ApiConfig.GET_ALL_TRANSACTION}?id=${id}`,
      JSON.stringify({
        FromDate: fromDate,
        ToDate: toDate,
      })
    )
      .then((resp) => {
        setFilterLoader(false);
        console.log(resp);
        if (resp?.json?.Success && resp?.json?.Data?.length > 0) {
          setTranName((res) => ({
            ...res,
            masterName: resp?.json?.Data[0]?.LedgerMasterName || "",
            groupType: resp?.json?.Data[0]?.GroupType || "",
          }));
          setFilteredTransc(resp?.json?.Data);
          console.log("success");
          setFromDate("");
          setToDate("");
        } else {
          console.log("No data found");
          setFilteredTransc([]);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="sub-header-role d-flex justify-content-between">
            {transData.length > 0 ? (
              <h6 className="mt-2 role-text">
                {transName.masterName} - {transName.groupType}
              </h6>
            ) : (
              <h6>No Records Found</h6>
            )}
            <button
              className="create-btn-sub-header px-1"
              style={{ marginRight: "0" }}
              onClick={handleShowTransaction}
            >
              + Transaction
            </button>
          </div>
          {transData.length > 0 && (
            <div className="row mt-2">
              <div className="col-2">
                <label for="datepickerfrom" class="form-label">
                  From :
                </label>
                <div class="input-group date" id="datepickerfrom">
                  <input
                    type="date"
                    class="bg-white form-control"
                    value={fromDate}
                    onChange={handleFromDate}
                  />
                  <span class="input-group-text">
                    <i class="bi bi-calendar"></i>
                  </span>
                </div>
              </div>
              <div className="col-2">
                <label for="datepickerto" class="form-label">
                  To :
                </label>
                <div class="input-group date" id="datepickerto">
                  <input
                    type="date"
                    class="bg-white form-control"
                    value={toDate}
                    onChange={handleToDate}
                  />
                  <span class="input-group-text">
                    <i class="bi bi-calendar"></i>
                  </span>
                </div>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  className="btn text-white"
                  style={{ background: "#53B7E8", marginTop: "37px" }}
                  onClick={handleSearchDateData}
                >
                  Search
                </button>
              </div>
            </div>
          )}

          {(transData.length || filteredTransc.length) > 0 && (
            <div className="table-wrapper">
              <table>
                <tr className="table-row-custom">
                  <th>Sr. No.</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Balance</th>
                </tr>
                {filterloader ? (
                  <Loader />
                ) : (filteredTransc.length || transData.length) > 0 ? (
                  (filteredTransc.length > 0 ? filteredTransc : transData).map(
                    (transaction, index) => {
                      return (
                        <tr className="table-row-custom">
                          <td>{index + 1}</td>
                          <td>
                            {transaction?.TransactionDate.split("T")[0]
                              .split("-")
                              .reverse()
                              .join("/")}
                          </td>
                          <td>{transaction?.TransactionDescription}</td>
                          <td>{transaction?.DrAmount}</td>
                          <td>{transaction?.CrAmount}</td>
                          <td>{transaction?.Balance}</td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      No transactions available
                    </td>
                  </tr>
                )}
              </table>
            </div>
          )}
        </>
      )}
      <Modal centered show={showTransaction} onHide={handleCloseTransaction}>
        <Modal.Header closeButton>
          <Modal.Title>Create Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Group className="mb-3">
            <Form.Label>Select Ledger</Form.Label>
            <Form.Select
              id="ledgerName"
              name="ledgerName"
              value={createTransaction.ledgerName}
              onChange={handleCreateTransaction}
            >
              <option selected disabled value="">
                Select Ledger
              </option>
              {allLedgers.map((item) => {
                return <option value={item.Id}>{item.Name}</option>;
              })}
            </Form.Select>
          </Form.Group> */}
          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              id="amount"
              placeholder="Enter Amount"
              value={createTransaction.amount}
              onChange={handleCreateTransaction}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Effect Type</Form.Label>
            <Form.Select
              id="effecttype"
              name="effecttype"
              value={createTransaction.effecttype}
              onChange={handleCreateTransaction}
            >
              <option selected disabled value="">
                Select Effect Type
              </option>
              <option value="1">Debit</option>
              <option value="2">Credit</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseTransaction} className="modal-cancel-btn">
            Close
          </button>
          <button
            className="AddQuestion_submit_btn"
            onClick={createTheTransaction}
          >
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
