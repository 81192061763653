import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Pagination from "../../sharedComponent/Pagination";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
  simpleGetCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import { ToastMsg } from "../TostMsg";
import Modal from "react-bootstrap/Modal";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";
import { ClipLoader } from "react-spinners";
import { json } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const CompleteTask = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [completeListData, setCompleteListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTask, setShowTask] = useState(false)

  //Confirmation popup
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [completeTask, setCompleteTask] = useState("");

  const [ShowResone, setShowResone] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const [isValided, setIsValided] = useState(false)
  const [state, setState] = useState({
    ProjectId: 0,
  })
  const [reasons, setReasons] = useState([])
  let initialReason = {
    "Reasons": [

    ],
    "TaskId": 0,
    "EmployeeId": userData?.empId,
    "IsCompleted": false
  }
  const [updateTaskWithReason, setUpdateTaskWithReason] = useState(initialReason)

  const handleClose = () => setShow(false);
  const [show, setShow] = useState(true);
  const handleCloseNo = () => {

    getReasons()
    setShowResone(true)
    setShowTask(false)
  };

  const [excelLoading, setExcelLoading] = useState(false);

  const navigate = useNavigate()


  const getReasons = () => {

    if (reasons.length > 0) return

    simpleGetCallWithErrorResponse(ApiConfig.GET_REASONS).then((res) => {

      if (res?.json?.Success) {

        setReasons(res?.json?.Data)

      }

    })

  }

  const handleUpdateTaskWithReason = () => {

    if (!updateTaskWithReason.Reasons.length) {
      ToastMsg('error', 'Please Select Atleast One Reason')

      return
    }

    updateTaskWithReason.EmployeeId = userData?.empId;

    updateTaskWithReason.TaskId = completeTask?.id

    PostCallWithErrorResponse(ApiConfig.UPADTE_TASK_WITH_REASON, JSON.stringify(updateTaskWithReason)).then((res) => {

      if (res?.json?.Success) {
        ToastMsg('success', res?.json?.Message)
        setShowResone(false)
        getListCompleteData()
        setUpdateTaskWithReason(initialReason)
      } else {
        ToastMsg('error', res?.json?.Message)
      }

    })

  }

  const getListCompleteData = () => {
    setLoading(true);
    PostCallWithErrorResponse(
      ApiConfig.GET_LIST_OF_COMPLETE_TASK,
      JSON.stringify({
        StatusId: 0,
      })
    )
      .then((res) => {
        let data = res.json.Data;
        if (res.json.Success) {
          setLoading(false);
          setCompleteListData(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const abortCompleteTask = (data) => {
    let payload = {
      TaskId: data?.TaskId,
      status: data?.status,
    };

    setBtnLoading(true)
    PostCallWithErrorResponse(
      ApiConfig.ABORT_COMPLETE_TASK,
      JSON.stringify(payload)
    ).then((res) => {
      if (res) {
        // ToastMsg("Task Marked Complete Successfully", res.json.Message);
        ToastMsg("success", res.json.Message);
        getListCompleteData();
        setShowTask(false)
      }
      setBtnLoading(false)
    });
  };

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.COMPLETETASK_DOWNLOAD_EXCEL)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getListCompleteData();
  }, []);

  const [searchValue, setSearchValue] = useState("");
  console.log("searchValue,", searchValue);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);
  console.log("Numeric", endIndex);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleCloseResone = () => {

    setShowResone(false)



    setState("")

    setIsValided(false)

  }

  const handleChange = (isChecked, id) => {


    let reason = [...updateTaskWithReason.Reasons]

    if (isChecked) {

      reason.push(Number(id))

    } else {
      reason = reason.filter((i) => i != id)


    }
    setUpdateTaskWithReason(prev => ({
      ...prev,
      Reasons: reason
    }))

  }
  const searchedDataPageCounts =
    completeListData &&
    completeListData.length &&
    completeListData.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const displayAllocationList = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr.No</th>
          <th>Created On</th>
          <th>Allocated Name</th>
          <th>Project</th>
          <th>Workflow</th>
          <th>type</th>
          <th>Task Name</th>
          <th>Activity</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Budget Hours</th>
          <th>Effort Hours</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {completeListData?.slice(startIndex, endIndex)?.map((data, index) => {
          return (
            <tr className="table-row-custom">
              <td id="sr-no-padding">{startIndex + index + 1}</td>
              <td>{data.CreatedDate}</td>
              <td>{data.AllocatedName}</td>
              <td>{data.ProjectName}</td>
              <td>{data.Workflow}</td>
              <td></td>
              <td className="break-ln-spaces">{data.TaskName}</td>
              <td className="break-ln-spaces">{data?.TaskDescription}</td>
              <td>{data.StartDate}</td>
              <td>{data.EndDate}</td>
              <td>{data.BudgetHours}</td>
              <td>{data.WorkedHours}</td>
              {(data.status === 8 && (
                <td>
                  <label className="textComplete">Completed</label>
                </td>
              )) || (
                  <td>
                    <button
                      className="btnComplete"
                      style={{ width: '100%' }}
                      onClick={() => {
                        // handleShow3();
                        if (data?.WorkflowId != 0) {
                          navigate('/dynamicComplteTask/' + data?.TaskAllocationId + "/" + data?.TaskId)
                        } else {

                          setShowTask(true)
                          setCompleteTask(data);
                        }
                      }}
                    // onClick={() => abortCompleteTask(data)}
                    >
                      Update Progress
                    </button>
                  </td>
                )}
            </tr>
          );
        })}
      </tbody>
      {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
    </table>
  );

  const displaySearchAllocationList = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr.No</th>
          <th>Created On</th>
          <th>Allocated Name</th>
          <th>Project</th>
          <th>Workflow</th>
          <th>Type</th>
          <th>Task Name</th>
          <th>Activity</th>
          <th>StartDate</th>
          <th>EndDate</th>
          <th>Budget Hours</th>
          <th>Effort Hours</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {completeListData
          ?.filter(
            (item) =>
              item.ProjectName.toLowerCase().includes(
                searchValue.toLowerCase()
              ) ||
              item.AllocatedName.toLowerCase().includes(
                searchValue.toLowerCase()
              )
          )
          ?.slice(startIndex, endIndex)
          ?.map((data, index) => {
            return (
              <tr className="table-row-custom">
                <td id="sr-no-padding">{startIndex + index + 1}</td>
                <td>{data.CreatedDate}</td>
                <td>{data.AllocatedName}</td>
                <td>{data.ProjectName}</td>
                <td>{data.Workflow}</td>
                <td></td>
                <td className="break-ln-spaces">{data.TaskName}</td>
                <td className="break-ln-spaces">{data?.TaskDescription}</td>
                <td>{data.StartDate}</td>
                <td>{data.EndDate}</td>
                <td>{data.BudgetHours}</td>
                <td>{data.WorkedHours}</td>
                {(data.status === 8 && (
                  <td>
                    <label className="textComplete">Completed</label>
                  </td>
                )) || (
                    <td>
                      <button
                        className="btnComplete"
                        style={{ width: '100%' }}
                        onClick={() => {
                          // handleShow3();
                          if (data?.WorkflowId != 0) {
                            navigate('/dynamicComplteTask/' + data?.TaskAllocationId + "/" + data?.TaskId)
                          } else {

                            setShowTask(true)
                            setCompleteTask(data);
                          }
                        }}
                      // onClick={() => abortCompleteTask(data)}
                      >
                        Update Progress
                      </button>
                    </td>
                  )}
              </tr>
            );
          })}
      </tbody>
      {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
    </table>
  );

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Inprogress Task</h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search By Project/Allocated Name"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25" selected>
                    25
                  </option>
                </select>
              </div>
            </div>
            {permissions && permissions["Complete Task Export"] && (
              <div className="import-export-btn-wrapper">
                <button>
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={ic_excel}
                      onClick={(e) => downloadExcelFile(e)}
                      alt=""
                    />
                  )}
                </button>
              </div>
            )}
          </div>
          <div className="table-wrapper-main" id="table-wrapper-af">
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : completeListData && completeListData.length > 0 ? (
              <>
                {searchValue === ""
                  ? displayAllocationList
                  : displaySearchAllocationList}
              </>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        {/* {!loading && searchedDataPageCounts.length > usersPerPage && ( */}
        <Pagination
          data={completeListData}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
        {/* )} */}
      </div>
      {/* Confirmation popup Announcement */}

      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Complete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to update this task's status to 'Complete'?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              abortCompleteTask(completeTask);
              handleClose3();
            }}
          >
            Update Progress
          </button>
        </div>
      </Modal>
      <Modal
        size="xl"
        show={showTask}
        onHide={() => setShowTask(false)}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header ">
          <Modal.Title id="example-modal-sizes-title-lg">Task Progress Update</Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShowTask(false)} />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>

        <Modal.Body>
          <div className="mainContentmodel">


            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: '#212529',
                fontWeight: "400px"
              }}
            >Have you successfully completed the <span style={{ color: '#53B7E8' }}>'{completeTask?.TaskName}' </span>task assigned for <span style={{ color: '#53B7E8' }}> {completeTask?.ProjectName}</span> on   <span style={{ color: '#53B7E8' }}>{completeTask?.EndDate}</span>?</p>





          </div>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button
            type="button"
            className="btn-width cancelBtn mx-3"
            onClick={handleCloseNo}
          >
            No
          </button>
          <button
            className="btn-width saveBtn"
            disabled={btnLoading ? true : false}
            onClick={() => abortCompleteTask(completeTask)}

          >
            {btnLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <ClipLoader color="#fff" size={18} className="me-2" />
              </div>
            ) : (
              "Yes"
            )}
          </button>
        </div>

      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={ShowResone}
        className="create-workflow-main modal-lg"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-workflow-text">
              Reason for Not Completing
            </label>

          </Modal.Title>
          <img src={Axe} alt="" onClick={handleCloseResone} />
        </Modal.Header>
        <p htmlFor="" style={{
          margin: "20px",
          marginBottom: "10px",
          marginTop: "-10px"
        }} >
          You haven't completed your tasks. Let us know why!
        </p>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="row create-workflow-topbody pb-4" style={{ maxHeight: '600px', overflow: 'scroll', scrollbarWidth: 'none' }}>
            {

              <>






                <div className="col-lg-6 mt-4">

                  <div>
                    {
                      reasons?.map((reason) => {
                        return <div>
                          <input type="checkbox"
                            checked={updateTaskWithReason?.Reasons?.includes(reason?.Id)}
                            onChange={(e) => handleChange(e.target.checked, reason?.Id)}
                          />
                          <span className="ml-4">{reason?.Reason}</span>
                        </div>
                      })
                    }
                    {
                      isValided && !state.negotiable && !state.recheckRequired && !state.recurring && !state.allocatableToBranch && <div className="text-danger">Please Select Any One</div>
                    }
                  </div>
                </div>
              </>

            }


          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer style={{
          display: "flex"
        }}>
          <div>

            {

              <button className="AddQuestion_submit_btn mx-3"
                onClick={handleUpdateTaskWithReason}
              >Submit Reason</button>
            }



          </div>
        </Modal.Footer>
      </Modal>

      {/* Confirmation popup Announcement */}
    </>
  );
};

export default CompleteTask;
