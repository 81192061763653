import React, { memo, useContext, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import { SpeedDial } from "primereact/speeddial";
import { Toast } from "primereact/toast";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import closeIcon from "../../assets/images/close_modal.svg";
import { AudioRecording } from "../../sharedComponent/audioRecorder";
import mic from "../../assets/images/chat/mic.svg";

import {
  Button,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import ic_video_call from "../../assets/images/chat/ic_video_call.svg";
import bx_phone_call from "../../assets/images/chat/bx_phone_call.svg";
import more_vertical from "../../assets/images/chat/more_vertical.svg";
import double_tick from "../../assets/images/chat/double_tick.svg";
import gray_tick from "../../assets/images/chat/gray_tick.svg";
import hr_three_dot from "../../assets/images/hr_tree_dot.svg";
import send_btn from "../../assets/images/chat/send_btn.svg";
import profile_img from "../../assets/images/profile_img.svg";
import user_pic2 from "../../assets/images/profile_img.svg";
import "../../assets/css/main.scss";
import ic_online from "../../assets/images/chat/ic_online.svg";
import ic_away from "../../assets/images/chat/ic_away.svg";
import ic_busy from "../../assets/images/chat/ic_busy.svg";
import ic_gray from "../../assets/images/chat/ic_gray.svg";
import ApiConfig, { BASE_URL_CHAT_Image } from "../../api/ApiConfig";
import { useEffect } from "react";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import close_modal_dark from "../../assets/images/close_btn.svg";
import fileIcon from "../../assets/images/fileIcon.svg";
import { useState } from "react";
import EmojiPicker from "emoji-picker-react";
import moment from "moment";
import Beatloader from "../../sharedComponent/Beatloader";
import TypingLoader from "../../sharedComponent/TypingLoader ";
import { notifyError, notifySuccess } from "../../toaster/notify";
import ReplyTo from "../../sharedComponent/ReplyTo";
import RepliesTo from "../../sharedComponent/RepliesTo";
import Calling from "../../sharedComponent/Calling";
import Multiselect from "multiselect-react-dropdown";
import Mensions from "../../sharedComponent/mesnsion/Mesnsion";
import Files from "./Files";
import MensionUsers from "../../sharedComponent/MensionUsers";
import smiley_icon from "../../assets/images/chat/smiley_icon.svg";
import no_chats from "../../assets/images/chat/chatsNone.svg";
import ChatMessage from "../../sharedComponent/ChatMessage";
import { ReactionBarSelector } from "@charkour/react-reactions";
import { emojiReactions } from "../../common";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import ChatImage from "../chatImage";

const ChatBox = ({
  setShowResults,
  selectedList,
  showResults,
  setSelectedList,
  createChatRoom,
  schannelName,
  setSchannelName,
  updatePinUnpinChat,
}) => {
  const [members, setMembers] = useState(false);
  const [addMembers, setAddMembers] = useState(false);
  const [addSubMembers, setAddSubMembers] = useState(false);

  const [newMessage, setNewMessage] = useState("");
  const [files, setFiles] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [SubchianlMemberList, setSubchianlMemberList] = useState([]);
  const fileUpload = useRef(null);
  const [message, setmessage] = useState("");
  const toast = useRef(null);
  const lastMsg = useRef(null);
  const firstMsg = useRef(null);
  const pindropdown = useRef(null);
  const msgRef = useRef([]);
  const [membersAdded, setMembersAdded] = useState([]);
  const [menstionedUsers, setMenstionedUsers] = useState([]);
  const [selectedReaction, setselectedReaction] = useState(null);
  const [showAudioErr, setShowAudioErr] = useState(false);
  const [audioPreview, setAudioPreview] = useState(false);

  const navigate = useNavigate();

  const [closeOutsideClick, setCloseOutSideClic] = useState(true);

  const handleReactionShow = (message_id) => {
    setselectedReaction(message_id);
  };
  const [emogi, setEmogi] = useState(false);
  const items = [
    {
      label: "Add",
      icon: "pi pi-pencil",
      command: () => {
        toast.current.show({
          severity: "info",
          summary: "Add",
          detail: "Data Added",
        });
      },
    },
    {
      label: "smiley",
      template: (item, options) => {
        return (
          /* custom element */
          <a
            className={options?.className}
            target={item.target}
            onClick={() => setEmogi(true)}
          >
            <img src={smiley_icon} alt=""></img>
          </a>
        );
      },
      command: () => {
        toast.current.show();
      },
    },
    {
      label: "Upload",
      icon: "pi pi-upload",
      command: () => {
        fileUpload.current.click();
      },
    },
  ];
  const currentRoute = useLocation().pathname;
  const {
    sendMessage,
    activeChat,
    userData,
    socket,
    currentSChannel,
    setActiveChat,
    teamMembers,
    page,
    conversations,
    newMessages,
    userTyping,
    editMsg,
    setEditMsg,
    setNewMessages,
    replyTo,
    setReplyTo,
    noMorePages,
    paginationLoader,
    setPage,
    loading,
    suggetions,
    membersArray,
    setUserTyping,
    setLoading,
    setPaginationLoader,
    setConversations,
    setNoMorePages,
    setRecentChats,
    recentChats,
    pinnedChats,
    setPinnedChats,
    projects,
    setProjects,
    teams,
    setTeams,
    projectIdTast,
    permissions,
    SubChannelId,
    setmsgdelete
  } = useContext(AppContext);
  console.log("activeChats-activeChat", activeChat);

  useEffect(() => {
    setmessage("");
  }, [currentSChannel])

  const [openVoiceReq, setOpenVoiceReq] = useState(false);

  useEffect(() => {
    if (page > 0) {
      firstMsg && firstMsg.current && firstMsg.current.scrollIntoView();
    } else lastMsg && lastMsg.current && lastMsg.current.scrollIntoView();
  }, [newMessages, lastMsg, conversations, userTyping]);
  useEffect(() => {
    lastMsg && lastMsg.current && lastMsg.current.scrollIntoView();
  }, [newMessages]);

  useEffect(() => {
    lastMsg && lastMsg.current && lastMsg.current.scrollIntoView();
  }, []);

  useEffect(() => {
    setFiles(false);
  }, [currentSChannel]);

  const save = () => {
    socket &&
      socket.emit("add-members", {
        schannel_id: SubChannelId,
        members: membersAdded.map((mem) => mem.id),
      });

      notifySuccess("members Aded");

  };
  function onSelect(selectedList, selectedItem) {
    setSelectedList(selectedList);
  }
  function onRemove(selectedList, removedItem) {
    setSelectedList(selectedList);
  }

  const deletMessage = (id) => { 
    setmsgdelete(true)
    socket.emit("is_deleted", {
      schannel_id: currentSChannel,
      message_id: id,
    });
  };

  useEffect(() => {
    if (currentSChannel) {
      socket && socket.emit("get-members", { schannel_id: currentSChannel });
    }
  }, [currentSChannel, socket]);

  const pinChat = () => {
    pindropdown.current && pindropdown.current.click();
    PostCallWithErrorResponse(
      ApiConfig.PIN_CHAT,
      JSON.stringify({ schannel_id: currentSChannel })
    ).then((res) => {
      if (res.json.status) {
        notifySuccess(res.json.message);
        setActiveChat({ ...activeChat, pin: true });
        updatePinUnpinChat();
        // getRecentChats();
      }
    });
  };
  const unpinChat = () => {
    pindropdown.current && pindropdown.current.click();
    PostCallWithErrorResponse(
      ApiConfig.UNPIN_CHAT,
      JSON.stringify({ schannel_id: currentSChannel })
    ).then((res) => {
      if (res.json.status) {
        notifySuccess(res.json.message);
        setActiveChat({ ...activeChat, pin: false });
        updatePinUnpinChat();
        // getRecentChats();
      }
    });
  };
  var parameter_Start_index =
    activeChat && activeChat?.last_seen && activeChat?.last_seen.indexOf(".");
  let user =
    activeChat &&
    activeChat?.em &&
    activeChat?.em
      .sort((a, b) =>
        (a.User_Id ? a.User_Id : a.EmployeeId) - b.User_Id
          ? b.User_Id
          : b.EmployeeId
      )
      .filter(
        (user) =>
          (user.User_Id ? user.User_Id : user.EmployeeId) != userData.EmpId
      )[0];

  var parameter_Start_index =
    user && user.last_seen && user.last_seen.indexOf(".");
  var last_seen =
    user &&
    user.last_seen &&
    user.last_seen.substring(0, parameter_Start_index) + ".209Z";

  const [reactions, setReactions] = useState([]);
  // GET PRIVATE CHAT LIST
  useEffect(() => {
    socket &&
      socket.on("get-conversation", (response) => {
        var data = response.result;
        var os_type = response.os_type;
        setLoading(false);
        setPaginationLoader(false);
        if (data?.length) {
          if (data[0].Team_Channel_Id == currentSChannel && os_type == "web") {
            if (data.Sender_Id !== userData.EmpId) {
              socket &&
                socket.emit("update-last-msg-read", {
                  schannel_id: data[0].Team_Channel_Id,
                  LastMsgId: data[0].Team_Message_Id,
                });
            }

            setConversations([
              ...data
                .map((msg) => {
                  let parsedReactionArr =
                    msg?.reactions && JSON?.parse(msg?.reactions);
                  return { ...msg, edit: false, reactions: parsedReactionArr };
                })
                .reverse(),
              ...conversations,
            ]);
          }

          let reactionArr = data.map(
            (ele) => ele?.reactions && JSON?.parse(ele?.reactions)
          );
        } else setNoMorePages(true);
      });

    socket &&
      socket.on("typing", (data) => {
        if (data?.userName?.includes("@")) {
          console.log(data);
          var userName = data.userName && data.userName.split("@")[0];
          userName = userName[0].toUpperCase() + userName.slice(1);
        } else {
          var userName = data.userName;
        }

        if (data.schannel_id == currentSChannel) {
          setUserTyping({
            status: true,
            userName: data?.FirstName + " " + data?.LastName,
            currentSChannel: data.schannel_id,
            EmpId: data?.EmpId,
          });
        }
      });
    socket &&
      socket.on("stoptyping", (data) => {
        if (data.schannel_id == currentSChannel) {
          setUserTyping({ status: false, userName: null });
        }
      });
    setReplyTo({});
    setEditMsg({});

    socket &&
      socket.on("add-reaction", (res) => {
        let finalConversationwithemoji =
          conversations &&
          conversations.length > 1 &&
          conversations.map((chatObj) => {
            return chatObj?.Team_Message_Id == res?.message_id
              ? {
                ...chatObj,
                reactions: [
                  ...chatObj.reactions,
                  {
                    reaction_id: res?.reaction_id,
                    reaction: res?.reaction,
                    reacted_by: res?.Sender_Id,
                  },
                ],
              }
              : chatObj;
          });

        conversations.length > 0 &&
          setConversations(finalConversationwithemoji);

        let finalNewMessagewithemojis =
          newMessages &&
          newMessages.length > 0 &&
          newMessages.map((chatObj) => {
            return chatObj?.message_id == res?.message_id
              ? {
                ...chatObj,
                reactions: [
                  ...chatObj.reactions,
                  {
                    reaction_id: res?.reaction_id,
                    reaction: res?.reaction,
                    reacted_by: res?.Sender_Id,
                  },
                ],
              }
              : chatObj;
          });

        newMessages.length > 0 && setNewMessages(finalNewMessagewithemojis);
      });

    socket &&
      socket.on("remove-reaction", (res) => {
        let finalConversationwithemoji =
          conversations &&
          conversations.length > 0 &&
          conversations.map((chatObj) => {
            let filterArr =
              chatObj &&
              chatObj?.reactions &&
              chatObj?.reactions.filter(
                (ele) => ele?.reaction_id !== res?.reaction_id
              );

            return chatObj?.Team_Message_Id == res?.message_id
              ? {
                ...chatObj,
                reactions: filterArr,
              }
              : chatObj;
          });

        conversations.length > 0 &&
          setConversations(finalConversationwithemoji);

        let finalNewMessagewithemoji =
          newMessages &&
          newMessages.length > 0 &&
          newMessages.map((chatObj) => {
            let filterArr =
              chatObj &&
              chatObj?.reactions &&
              chatObj?.reactions.filter(
                (ele) => ele?.reaction_id !== res?.reaction_id
              );
            return chatObj?.message_id == res?.message_id
              ? {
                ...chatObj,
                reactions: filterArr,
              }
              : chatObj;
          });

        newMessages.length > 0 && setNewMessages(finalNewMessagewithemoji);
      });

    return () => {
      if (socket) {
        socket.off("get-conversation");
        socket.off("typing");
        socket.off("stoptyping");
        socket.off("add-reaction");
      }
    };
  }, [conversations, socket, currentSChannel, userData, newMessages]);

  const inputRef = useRef(null);
  console.log("inputRef", inputRef);
  const joinRoom = (schannel_id) => {
    socket.emit("join-room", {
      schannel_id: schannel_id,
    });
  };

  const checkMicrophoneConnection = async () => {
    try {
      // Get the list of all media devices
      const devices = await navigator.mediaDevices.enumerateDevices();

      // Check if there is a microphone among the devices
      const micConnected = devices.some(
        (device) => device.kind === "audioinput"
      );

      if (micConnected) {
        setOpenVoiceReq(true);
      } else {
        setShowAudioErr(true);
      }
    } catch (error) {
      console.error("Error accessing media devices:", error);
    }
  };

  const [audioFile, setAudioFile] = useState({});

  const [audio, setAudio] = useState("");
  const recorderControls = useAudioRecorder();

  useEffect(() => {
    activeChat &&
      activeChat?.Team_Channel_Id &&
      activeChat?.Team_Channel_Id &&
      joinRoom(
        activeChat && activeChat?.Team_Channel_Id && activeChat?.Team_Channel_Id
      );
    let chats =
      recentChats &&
      recentChats.length > 0 &&
      recentChats.filter((chat) => {
        if (
          chat.Team_Channel_Id == activeChat?.Team_Channel_Id &&
          activeChat?.Team_Channel_Id
        ) {
          chat.unread_count = 0;
        }
        return chat;
      });
    setRecentChats(chats);

    let pinned_chats =
      pinnedChats &&
      pinnedChats.length > 0 &&
      pinnedChats.filter((chat) => {
        if (
          chat.Team_Channel_Id == activeChat?.Team_Channel_Id &&
          activeChat?.Team_Channel_Id
        ) {
          chat.unread_count = 0;
        }
        return chat;
      });
    setPinnedChats(pinned_chats);

    let filterProjectsWithUnreadCount =
      projects &&
      projects.length > 0 &&
      projects.filter((proj) => {
        if (
          proj?.channel_id == activeChat?.Team_Channel_Id &&
          activeChat?.Team_Channel_Id
        ) {
          proj.subchannel &&
            proj.subchannel.length > 0 &&
            proj.subchannel.filter((schannel) => {
              if (schannel?.Team_Channel_Id == activeChat?.Team_Channel_Id) {
                schannel.unread_count = 0;
              }
              return schannel;
            });
        }
        return proj;
      });

    setProjects(filterProjectsWithUnreadCount);

    let filterWithUnreadChat =
      teams &&
      teams.length > 0 &&
      teams.filter((proj) => {
        if (
          proj?.channel_id == activeChat?.Team_Channel_Id &&
          activeChat?.Team_Channel_Id
        ) {
          proj.subchannel &&
            proj.subchannel.length > 0 &&
            proj.subchannel.filter((schannel) => {
              if (schannel?.Team_Channel_Id == activeChat?.Team_Channel_Id) {
                schannel.unread_count = 0;
              }
              return schannel;
            });
        }
        return proj;
      });
    setTeams(filterWithUnreadChat);
  }, [activeChat]);

  const removeReaction = (reactionId, Team_Channel_Id, Team_Message_Id) => {
    socket &&
      socket.emit("remove-reaction", {
        reaction_id: reactionId,
        schannel_id: Team_Channel_Id,
        message_id: Team_Message_Id,
      });
  };
  const [prevDate, setPrevDate] = useState(null);

  const addCreateTask = (Message_Text) => {
    let currentDate = new Date().toISOString().split("T")[0];

    let body = JSON.stringify({
      EmpId: Number(userData.EmpId),
      OrgId: Number(userData.OrgId),
      TaskId: 0,
      ProjectId: Number(projectIdTast),
      TaskName: Message_Text,
      StartDate: currentDate,
      EndDate: currentDate,
      BudgetedHours: 10,
      Priorityid: 2,
      Approved: "p",
      TaskDescription: Message_Text,
    });

    PostCallWithErrorResponse(ApiConfig.CREATE_Task, body)
      .then((res) => {
        if (res?.json?.Success === true) {
          notifySuccess(res?.json?.Message);
        } else {
          notifyError(res?.json?.Message);
        }
      })
      .catch((err) => {
        console.log("error,", err);
      });
  };

  const handleCopyUrl = () => {
    simpleGetCallWithToken(ApiConfig.GITLABURL + currentSChannel)
      .then((res) => {
        // console.log("Data", res.data);
        console.log("res", res);

        if (res.success == true) {
          // notifySuccess("URL copied to clipboard!");

          // Copy to clipboard
          navigator.clipboard.writeText(res.data.webhookUrl).then(() => {
            notifySuccess("URL copied to clipboard!");
            // setComplianceList(res.Data);
          });
        }
      })
      .catch((err) => {
        console.log("err", err);

        notifyError("Failed to copy URL.");
      });
  };



  // useEffect(() => {
  //   if (project?.subchannel?.length > 0) {
  //     setCurrentSChannel(project.subchannel[1]?.Team_Channel_Id);
  //   }
  // }, [project?.subchannel]);
  // //////////////////////////////////////////////////////////////////////////////{issue fix}
  const createSubChannelMember = () => {

    let body = JSON.stringify({
      type: "Project",
      schannel_id: currentSChannel, ///whern Add User in sub Chaneel  /get-projects-android/Team_Channel_Id
      // "channel_id":id,  //// sub chaneel Create User get-projects-android /channel_id
    });
 
 
      setLoading(true);
      PostCallWithErrorResponse(ApiConfig.CREATE_SUB_CHANNEL_ADDMEMBER, body)
        .then((res) => {
          setLoading(false);
          if (res.json.status) {
            setSubchianlMemberList(res.json.data)
        
          } else
          
          notifyError(res.json.message);
        })
        .catch((err) => {
          setLoading(false);
          notifyError(err);
        });
    
  };
  return (
    <>
      <Toast ref={toast} className="toast" />
      <div className="col-lg-9 right-chat-section right-chat-custom-height-1">
        {showResults ? (
          <div className="search-input-wrapper position-relative create-sub-channel" id="search-chat-user">
            <h5><strong>Select Member</strong></h5>
            <Multiselect
              options={suggetions.map((suggetion) => {
                return {
                  id: suggetion.EmployeeId,
                  name: suggetion.FirstName + " " + suggetion.LastName,
                };
              })}
              onSelect={onSelect}
              onRemove={onRemove}
              displayValue="name"
              closeOnSelect="false"
              className=""
            />
            <div className="breakerLine"></div>
            <div className="button">
              <button
                class="ml-3 btn position-absolute"
                style={{right:"12px",bottom:"4px" }}
                onClick={() => {
                  setShowResults(false);
                  createChatRoom();
                }}
              >
                Start Chat
              </button>
            </div>
            {/* {Dark === "lightMode" ? (
           <img src={searchicon} alt="" />
         ) : (
           <img src={ic_search_dark} alt="" />
         )} */}
            {/* {filteredUsers.length ?
             <div className="suggestion-list-wrapper">

               <ul style={{ listStyleType: "none" }}>
                 {filteredUsers.map((user, index) => {
                   return (
                     <li
                       className="suggestion-item" key={"suggetion" + index}
                       style={{
                         margin: "0",
                         cursor: "pointer"
                       }}
                       onClick={() => {
                         onClick();
                         setActiveChat(user);
                         setCurrentSChannel(null);
                       }}
                     >
                       <div
                         className="user-chat-tab"
                         style={{
                           width: "100%",
                           padding: "10px 20px",
                         }}
                       >
                         <div className="d-flex">
                           <img src={user.PhotoPath ? ApiConfig.BASE_URL_FOR_IMAGES + user.PhotoPath : profile_img} alt="" style={{ borderRadius: "18px", width: "33px", height: "33px" }} />
                           <p>{user.FirstName + " " + user.LastName}</p>

                         </div>
                       </div>
                     </li>

                   )
                 })}
               </ul>
             </div>
             :
             "No User Found"

           } */}
          </div>
        ) : (
          <>
            {currentSChannel || activeChat.Team_Channel_Id ? (
              <>
                <div className="heading-chat-section">
                  <div className="left">
                    <div
                      className="left-profile-pic"
                      style={{ paddingBottom: "10px" }}
                    >
                      <Link>
                        <ChatImage
                          name={user?.FirstName}
                          lastName={user?.LastName}
                          PhotoPath={user?.PhotoPath}
                        />
                        {/* <img
                          src={
                            !currentRoute.includes("/projects") &&
                            user &&
                            user.PhotoPath
                              ? ApiConfig.BASE_URL_FOR_IMAGES + user.PhotoPath
                              : profile_img
                          }
                          alt=""
                          onError={(e) => (e.target.src = profile_img)}
                          width={"41px"}
                          className="rounded-circle"
                        /> */}
                      </Link>
                      {!currentRoute.includes("/projects") && (
                        <div className="indication-img status-img-shadow">
                          <img
                            src={
                              user &&
                              (user.user_status == "online"
                                ? ic_online
                                : user.user_status == "busy" ||
                                  user.user_status == "do not desturb"
                                  ? ic_busy
                                  : user.user_status == "away"
                                    ? ic_away
                                    : ic_gray)
                            }
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                    <div className="name d-flex flex-column">
                      <label htmlFor="" onClick={() => setMembers(true)}>
                        {currentRoute?.includes("/projects") && activeChat
                          ? activeChat?.project +
                          " >  " +
                          activeChat?.Team_Channel_Name
                          : currentRoute?.includes("/teams") && activeChat
                            ? activeChat?.project +
                            " >  " +
                            activeChat?.Team_Channel_Name
                            : activeChat?.Team_Channel_Description &&
                              activeChat?.Team_Channel_Description != undefined &&
                              activeChat?.Team_Channel_Description != null &&
                              activeChat?.Team_Channel_Description != "private"
                              ? activeChat?.Team_Channel_Description
                              : activeChat?.em?.length == 2
                                ? user && user.FirstName + " " + user.LastName
                                : user &&
                                user.FirstName +
                                " " +
                                user.LastName +
                                " " +
                                (activeChat?.em?.length - 1 > 1
                                  ? "   " +
                                  activeChat?.em?.length -
                                  1 +
                                  " & others"
                                  : "")}
                      </label>
                      <div className="lastSeen">
                        {user && (
                          <>
                            {console.log(user)}
                            <label
                              className="text1"
                              style={{ textAlign: "start", marginRight: "0px" }}
                            >
                              {user?.user_status == "offline" ? (
                                user?.last_seen && "Last Seen :"
                              ) : (
                                <>
                                  {user?.user_status}
                                  {"  "}
                                </>
                              )}
                              &nbsp; &nbsp;
                            </label>
                            <label className="text1">
                              {" " + user &&
                                user?.user_status == "offline" &&
                                user?.last_seen &&
                                moment(last_seen)?.fromNow() + " "}
                              {user?.EmployeeStatusMessage?.length > 0
                                ? user?.EmployeeStatusMessage
                                : user?.EmployeeLiveMessage}
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    {permissions && permissions["Calling Feature"] && (
                      <Link to="#" className="right-icon-chat">
                        <img
                          src={bx_phone_call}
                          alt=""
                          onClick={() => {
                            socket &&
                              socket.emit("call-initiate", {
                                schannel_id: currentSChannel,
                                os_type: "web",
                              });
                          }}
                        />
                      </Link>
                    )}
                    {/* <Link to="#" className="right-icon-chat">
                    <img src={ic_video_call} alt="" />
                  </Link> */}
                    <Dropdown className="ActionDropdown right-icon-chat">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="ActionToggle"
                        ref={pindropdown}
                      >
                        <img src={more_vertical} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="ActionMenu">
                        <ul className="actionnmenulist">
                          {!currentRoute.includes("projects") &&
                            !currentRoute.includes("teams") && (
                              <li
                                onClick={() =>
                                  activeChat?.pin && activeChat?.pin
                                    ? unpinChat()
                                    : pinChat()
                                }
                              >
                                {activeChat?.pin && activeChat?.pin
                                  ? "Unpin"
                                  : "Pin"}{" "}
                              </li>
                            )}
                          {/* <li>Hide</li> */}
                          {currentRoute.includes("projects") && (
                            <li 
                            
                            onClick={() => {
                              setAddSubMembers(true); // Open the modal or perform related action
                              createSubChannelMember(); // Call the function to create sub-channel member
                            }}
                            
                            >
                              Add Member
                            </li>
                          )} 

{currentRoute.includes("chat") && (
                            <li onClick={() => setAddMembers(true)}>
                              Add People
                            </li>
                          )}
                          {/* <li>Screen Sharing</li> */}
                          <li onClick={() => setFiles(true)}>Files</li>
                          {/* <li>Delete Chat</li> */}
                          <li onClick={() => setMembers(true)}>About</li>
                          <li onClick={handleCopyUrl}>Copy Webhook</li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {files ? (
                  <Files />
                ) : (
                  <>
                    {loading ? (
                      <div className="chatting-section">
                        <Beatloader />
                      </div>
                    ) : (
                      <div className="chatting-section text-center">
                        <div className=" mb-4" ref={firstMsg}>
                          {!noMorePages ? (
                            <button
                              type="button"
                              class="btn btn btn-light"
                              onClick={() => setPage(page + 1)}
                              style={{
                                backgroundColor: "#53b7e8",
                                color: "white",
                              }}
                            >
                              {paginationLoader ? (
                                <>
                                  <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span class="sr-only"> Loading...</span>
                                </>
                              ) : (
                                "Load More"
                              )}
                            </button>
                          ) : (
                            "No more Conversations found"
                          )}
                        </div>
                        {/* convchats */}
                        {conversations.map((msg, index) => {
                          let Mentioned_To =
                            msg.Mentioned_To &&
                            msg.Mentioned_To.split(",").map((id) => Number(id));
                          let mensioned = membersArray.filter(
                            (member) =>
                              Mentioned_To &&
                              Mentioned_To.includes(member.User_Id)
                          );
                          let repliedTo = conversations.filter(
                            (reply, index) =>
                              msg.ReplyTo != null &&
                              reply.Team_Message_Id == msg.ReplyTo
                          )[0];
                          let sernderName = msg.FirstName + " " + msg.LastName;
                          // const currentDate = getDateddmmyyyy(msg?.CreatedOn)
                          // // setPrevDate(currentDate => { return currentDate})
                          // const showDate = currentDate !== prevDate;

                          const formattedTime = new Date(
                            msg?.CreatedOn
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          });

                          return (
                            <div
                              className="message-main"
                              key={"conversation" + index}
                              id={"msg" + msg.Team_Message_Id}
                              ref={(elem) => (msgRef.current[index] = elem)}
                            >
                              {/* <span>{showDate && currentDate}</span> */}
                              {msg && msg.Sender_Id == userData.EmpId ? (
                                <>
                                  <div
                                    className="send-msg"
                                    key={"mag" + msg.Team_Message_Id}
                                    ref={(elem) =>
                                      (msgRef.current[index] = elem)
                                    }
                                    onMouseOver={() => {
                                      setselectedReaction(msg.Team_Message_Id);
                                    }}
                                    onMouseLeave={() =>
                                      setselectedReaction(null)
                                    }
                                  >
                                    <Dropdown className="ActionDropdown ml-4 editConv">
                                      <Dropdown.Toggle
                                        variant="success"
                                        id={"dropdown-basic" + index}
                                        className="ActionToggle "
                                      >
                                        <img src={hr_three_dot} alt="" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="ActionMenu">
                                        <ul className="actionnmenulist">
                                          <li
                                            onClick={() => {
                                              setReplyTo({ ...msg });
                                              inputRef.current.focus();
                                            }}
                                          >
                                            Reply
                                          </li>

                                          {moment(msg.CreatedOn).fromNow() &&
                                            (moment(msg.CreatedOn)
                                              .fromNow()
                                              .includes("seconds") ||
                                              (moment(msg.CreatedOn)
                                                .fromNow()
                                                .includes("minutes") &&
                                                Number(
                                                  moment(msg.CreatedOn)
                                                    .fromNow()
                                                    .split(" ")[0]
                                                ) <= 15)) && (
                                              <li
                                                onClick={() => {
                                                  setEditMsg(msg);
                                                }}
                                              >
                                                Edit
                                              </li>
                                            )}

                                          <li
                                            onClick={(e) => {
                                              deletMessage(msg.Team_Message_Id);
                                            }}
                                          >
                                            Delete 
                                          </li>
                                          {/* <li>Mark as unread</li> */}
                                        </ul>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                    {msg.Team_Message_Id ==
                                      editMsg.Team_Message_Id ? (
                                      <div>
                                        <textarea
                                          className="form-control col-3"
                                          type={"text"}
                                          value={
                                            editMsg.Team_Message_Text ||
                                            editMsg.message
                                          }
                                          onChange={(e) =>
                                            setEditMsg({
                                              ...editMsg,
                                              message: e.target.value,
                                              Team_Message_Text: e.target.value,
                                            })
                                          }
                                          onKeyUp={(e) => {
                                            if (
                                              e.key === "Enter" &&
                                              !e.shiftKey &&
                                              e.target.value.length
                                            ) {
                                              if (socket)
                                                socket.emit("edit-message", {
                                                  schannel_id: currentSChannel,
                                                  message_id:
                                                    editMsg.message_id ||
                                                    editMsg.Team_Message_Id,
                                                  message:
                                                    editMsg.Team_Message_Text ||
                                                    editMsg.message,
                                                });
                                              setEditMsg({
                                                message_id: null,
                                                Sender_Id: null,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                    ) : msg.ReplyTo !== null ? (
                                      <RepliesTo
                                        sernderName={sernderName}
                                        msg={msg}
                                        repliedTo={repliedTo}
                                        msgRef={msgRef}
                                      />
                                    ) : (
                                      <>
                                        <Link>
                                          <img
                                            src={
                                              msg.IsAllRead
                                                ? double_tick
                                                : gray_tick
                                            }
                                            alt="double_tick"
                                            className="ms-3"
                                          />
                                        </Link>
                                        <div>
                                          {msg.Resource_Path != null &&
                                            msg.Resource_Path.length &&
                                            (msg.Resource_Path.includes(
                                              "pdf"
                                            ) ||
                                              msg.Resource_Path.includes("xls") ||
                                              msg.Resource_Path.includes(
                                                "xlsx"
                                              ) ||
                                              msg.Resource_Path.includes("csv") ||
                                              msg.Resource_Path.includes("doc") ||
                                              msg.Resource_Path.includes(
                                                "docx"
                                              ) ||
                                              msg.Resource_Path.includes(
                                                "zip"
                                              ) ? (
                                              <a
                                                href={msg.Resource_Path}
                                                download
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <img
                                                  src={fileIcon}
                                                  className="image"
                                                  alt=""
                                                />{" "}
                                              </a>
                                            ) : (
                                              <a
                                                href={msg.Resource_Path}
                                                download
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {msg.Resource_Path.includes(
                                                  "mp3"
                                                ) ||
                                                  msg.Resource_Path.includes(
                                                    "m4a"
                                                  ) ? (
                                                  <audio
                                                    src={msg.Resource_Path}
                                                    controls
                                                  ></audio>
                                                ) : (
                                                  <img
                                                    src={msg.Resource_Path}
                                                    className="image"
                                                    alt=""
                                                  />
                                                )}
                                              </a>
                                            ))}
                                          <div className="send-msg-main">
                                            {msg.Team_Message_Text &&
                                              msg.Team_Message_Text.includes(
                                                "@"
                                              ) ? (
                                              <MensionUsers
                                                text={msg.Team_Message_Text}
                                                users={mensioned}
                                                index={index}
                                              />
                                            ) : (
                                              msg?.Team_Message_Text && (
                                                <div>
                                                  <ChatMessage
                                                    msg={msg?.Team_Message_Text}
                                                    className={"msg-text"}
                                                    style={
                                                      {
                                                        //textAlign: "left",
                                                      }
                                                    }
                                                  />
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    <div>
                                      {selectedReaction &&
                                        selectedReaction ==
                                        msg.Team_Message_Id && (
                                          <ReactionBarSelector
                                            iconSize={16}
                                            reactions={emojiReactions}
                                            onSelect={(selectedEmoji) => {
                                              const selectedReaction =
                                                emojiReactions.find(
                                                  (reaction) =>
                                                    reaction.label ===
                                                    selectedEmoji
                                                );
                                              const isSelectedEmojiPresent =
                                                msg?.reactions &&
                                                msg.reactions.length &&
                                                msg.reactions.some(
                                                  (item) =>
                                                    item.reaction ==
                                                    selectedReaction?.id
                                                );
                                              if (!isSelectedEmojiPresent) {
                                                socket &&
                                                  socket.emit("add-reaction", {
                                                    reaction:
                                                      selectedReaction?.id,
                                                    schannel_id:
                                                      msg?.Team_Channel_Id,
                                                    message_id:
                                                      msg?.Team_Message_Id,
                                                  });
                                              }
                                            }}
                                          />
                                        )}
                                    </div>
                                  </div>

                                  <div className="time-date-sms-send">
                                    <span style={{ marginRight: "10px" }}>
                                      {/* {formattedTime} */}
                                      {moment(msg.CreatedOn).fromNow()}
                                    </span>
                                    {msg.IsEdit && (
                                      <span> Edited &nbsp;&nbsp; </span>
                                    )}
                                    {/* <div> */}
                                    {msg?.reactions &&
                                      msg.reactions.length > 0 &&
                                      msg.reactions.map((item) => {
                                        let displayEmoji = emojiReactions.find(
                                          (emj) => emj?.id == item?.reaction
                                        );
                                        return (
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              removeReaction(
                                                item?.reaction_id,
                                                msg?.Team_Channel_Id,
                                                msg?.Team_Message_Id
                                              )
                                            }
                                          >
                                            {displayEmoji?.emoji &&
                                              displayEmoji?.emoji}
                                            &nbsp;
                                          </span>
                                        );
                                      })}
                                    {/* </div> */}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="receive-msg"
                                    key={"mag" + index}
                                    id={"msg" + msg.Team_Message_Id}
                                    ref={(elem) =>
                                      (msgRef.current[index] = elem)
                                    }
                                    onMouseOver={() => {
                                      setselectedReaction(msg.Team_Message_Id);
                                    }}
                                    onMouseLeave={() =>
                                      setselectedReaction(null)
                                    }
                                  >
                                    <div className="msg-with-img">
                                      {console.log(msg)}
                                      <ChatImage
                                        name={msg?.FirstName}
                                        lastName={msg?.LastName}
                                        PhotoPath={msg.PhotoPath}
                                      />
                                      {/* <img
                                        src={
                                          ApiConfig.BASE_URL_FOR_IMAGES +
                                            msg.PhotoPath || user_pic2
                                        }
                                        alt=""
                                        onError={(e) =>
                                          (e.target.src = profile_img)
                                        }
                                      /> */}
                                    </div>
                                    {msg.ReplyTo !== null ? (
                                      <RepliesTo
                                        sernderName={sernderName}
                                        msg={msg}
                                        repliedTo={repliedTo}
                                        msgRef={msgRef}
                                        users={mensioned}
                                        index={index}
                                      />
                                    ) : (
                                      <div className="d-flex align-items-center ">
                                        <div className="right-receive-msg position-relative">
                                          <div className="senderName">
                                            {sernderName}
                                          </div>
                                          <div>
                                            {msg.Resource_Path != null &&
                                              msg.Resource_Path &&
                                              (msg.Resource_Path.includes(
                                                "pdf"
                                              ) ||
                                                msg.Resource_Path.includes(
                                                  "xls"
                                                ) ||
                                                msg.Resource_Path.includes(
                                                  "doc"
                                                ) ||
                                                msg.Resource_Path.includes(
                                                  "docx"
                                                ) ||
                                                msg.Resource_Path.includes(
                                                  "zip"
                                                ) ? (
                                                <a
                                                  href={msg.Resource_Path}
                                                  download
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  <img
                                                    src={fileIcon}
                                                    className="image"
                                                    alt=""
                                                  />{" "}
                                                </a>
                                              ) : (
                                                <>
                                                  <a
                                                    href={msg.Resource_Path}
                                                    download
                                                    target="_blank"
                                                    rel="noreferrer"
                                                  >
                                                    {" "}
                                                    {msg.Resource_Path.includes(
                                                      ".mp3"
                                                    ) ||
                                                      msg.Resource_Path.includes(
                                                        ".m4a"
                                                      ) ? (
                                                      <audio
                                                        src={msg.Resource_Path}
                                                        controls
                                                      ></audio>
                                                    ) : (
                                                      <img
                                                        src={msg.Resource_Path}
                                                        className="image"
                                                        alt=""
                                                      />
                                                    )}
                                                  </a>
                                                </>
                                              ))}
                                          </div>
                                          <div>
                                            {msg.Team_Message_Text ? (
                                              <div className="receive-msg-main">
                                                <p
                                                  className="msg-text"
                                                  style={{ textAlign: "left" }}
                                                >
                                                  {" "}
                                                  {msg.Team_Message_Text.includes(
                                                    "@"
                                                  ) ? (
                                                    <MensionUsers
                                                      text={msg.Team_Message_Text}
                                                      users={mensioned}
                                                      index={index}
                                                    />
                                                  ) : (
                                                    <ChatMessage
                                                      msg={msg?.Team_Message_Text}
                                                    />
                                                  )}
                                                </p>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="emojis ">
                                            {selectedReaction &&
                                              selectedReaction ==
                                              msg.Team_Message_Id && (
                                                <ReactionBarSelector
                                                  iconSize={16}
                                                  reactions={emojiReactions}
                                                  onSelect={(selectedEmoji) => {
                                                    const selectedReaction =
                                                      emojiReactions.find(
                                                        (reaction) =>
                                                          reaction.label ===
                                                          selectedEmoji
                                                      );
                                                    const isSelectedEmojiPresent =
                                                      msg?.reactions &&
                                                      msg.reactions.length &&
                                                      msg.reactions.some(
                                                        (item) =>
                                                          item.reaction ==
                                                          selectedReaction?.id
                                                      );
                                                    if (
                                                      !isSelectedEmojiPresent
                                                    ) {
                                                      socket &&
                                                        socket.emit(
                                                          "add-reaction",
                                                          {
                                                            reaction:
                                                              selectedReaction?.id,
                                                            schannel_id:
                                                              msg?.Team_Channel_Id,
                                                            message_id:
                                                              msg?.Team_Message_Id,
                                                          }
                                                        );
                                                    }
                                                  }}
                                                />
                                              )}
                                          </div>
                                        </div>
                                        <Dropdown className="ActionDropdown mt-4">
                                          <Dropdown.Toggle
                                            variant="success"
                                            id={"dropdown-basic" + index}
                                            className="ActionToggle  ml-3"
                                          >
                                            <img src={hr_three_dot} alt="" />
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu className="ActionMenu">
                                            <ul className="actionnmenulist">
                                              <li
                                                onClick={() => {
                                                  setReplyTo({ ...msg });
                                                  inputRef.current.focus();
                                                }}
                                              >
                                                Reply
                                              </li>
                                              {msg?.Team_Message_Text && (
                                                <li
                                                  onClick={() => {
                                                    addCreateTask(
                                                      msg?.Team_Message_Text
                                                    );
                                                    // navigate(
                                                    //   "/taskList?taskName=" +
                                                    //     msg?.Team_Message_Text
                                                    // );
                                                  }}
                                                >
                                                  Create Task
                                                </li>
                                              )}
                                            </ul>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    )}

                                    {/* <Dropdown className="ActionDropdown  me-4">
                                      <Dropdown.Toggle
                                        variant="success"
                                        id={"dropdown-basic" + index}
                                        className="ActionToggle  ml-3"
                                      >
                                        <img src={hr_three_dot} alt="" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className="ActionMenu">
                                        <ul className="actionnmenulist">
                                          <li
                                            onClick={() => {
                                              setReplyTo({ ...msg });
                                              inputRef.current.focus();
                                            }}
                                          >
                                            Reply
                                          </li>
                                          {msg?.Team_Message_Text && (
                                            <li
                                              onClick={() => {
                                                addCreateTask(
                                                  msg?.Team_Message_Text
                                                );
                                                // navigate(
                                                //   "/taskList?taskName=" +
                                                //     msg?.Team_Message_Text
                                                // );
                                              }}
                                            >
                                              Create Task
                                            </li>
                                          )}
                                        </ul>
                                      </Dropdown.Menu>
                                    </Dropdown> */}
                                  </div>
                                  <div className="time-date-sms-recieve">
                                    <span style={{ marginRight: "10px" }}>
                                      {/* {formattedTime} */}
                                      {moment(msg.CreatedOn).fromNow()}
                                    </span>
                                    {msg.IsEdit && (
                                      <span className="ml-3">
                                        {" "}
                                        Edited &nbsp;&nbsp;{" "}
                                      </span>
                                    )}
                                    {msg?.reactions &&
                                      msg.reactions.length > 0 &&
                                      msg.reactions.map((item) => {
                                        let displayEmoji = emojiReactions.find(
                                          (emj) => emj?.id == item?.reaction
                                        );
                                        return (
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              removeReaction(
                                                item?.reaction_id,
                                                msg?.Team_Channel_Id,
                                                msg?.Team_Message_Id
                                              )
                                            }
                                          >
                                            {displayEmoji?.emoji &&
                                              displayEmoji?.emoji}
                                            &nbsp;
                                          </span>
                                        );
                                      })}
                                  </div>
                                </>
                              )}
                            </div>
                          );
                        })}

                        {newMessages &&
                          newMessages.length > 0 &&
                          newMessages.map((msg, index) => {
                            let Mentioned_To =
                              msg.Mentioned_To &&
                              msg.Mentioned_To.split(",").map((id) =>
                                Number(id)
                              );
                            let mensioned = membersArray.filter(
                              (member) =>
                                Mentioned_To &&
                                Mentioned_To.includes(member.User_Id)
                            );
                            let repliedTo = conversations.filter(
                              (reply) =>
                                msg.ReplyTo != null &&
                                reply.Team_Message_Id == msg.ReplyTo
                            );
                            let replied = repliedTo.length
                              ? repliedTo[0]
                              : newMessages.filter(
                                (filtMsg) =>
                                  filtMsg.Team_Message_Id == msg.message_id ||
                                  filtMsg.message_id == msg.message_id
                              )[0];
                            let sender_name = msg.sender_name;

                            const formattedTime = new Date(
                              msg?.created_at
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            });

                            return (
                              <div
                                // style={{ pointerEvents: 'none' }}
                                className="message-main"
                                key={"new Message" + index}
                                id={"msg" + msg.message_id}
                                onBlur={() => setEditMsg({})}
                                ref={(elem) => (msgRef.current[index] = elem)}
                              >
                                {/* Chat Right */}
                                {msg && msg.Sender_Id == userData.EmpId ? (
                                  <>
                                    <div
                                      className="send-msg"
                                      onMouseOver={() => {
                                        setselectedReaction(msg?.message_id);
                                      }}
                                      onMouseLeave={() =>
                                        setselectedReaction(null)
                                      }
                                    >
                                      <Dropdown className="ActionDropdown ml-4 editConv">
                                        <Dropdown.Toggle
                                          variant="success"
                                          id={"dropdown-basic" + index}
                                          className="ActionToggle "
                                        >
                                          <img src={hr_three_dot} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="ActionMenu">
                                          <ul className="actionnmenulist">
                                            <li
                                              onClick={() => {
                                                setReplyTo({ ...msg });
                                                inputRef.current.focus();
                                              }}
                                            >
                                              Reply
                                            </li>
                                            <li
                                              onClick={() => {
                                                setEditMsg(msg);
                                              }}
                                            >
                                              Edit
                                            </li>
                                            <li
                                              onClick={(e) => {
                                                deletMessage(msg?.message_id);
                                                // document
                                                //   .getElementById("mag" + index)
                                                //   .click();
                                              }}
                                            >
                                              Delete
                                            </li>
                                            {/* <li>Mark as unread</li> */}
                                          </ul>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                      {msg?.ReplyTo ? (
                                        <RepliesTo
                                          sernderName={sender_name}
                                          msg={msg}
                                          repliedTo={replied}
                                          msgRef={msgRef}
                                        />
                                      ) : (
                                        <>
                                          {msg.Sender_Id == editMsg.Sender_Id &&
                                            msg.message_id ==
                                            editMsg.message_id ? (
                                            <div>
                                              <textarea
                                                className="form-control col-3"
                                                type={"text"}
                                                value={
                                                  editMsg.Team_Message_Text ||
                                                  editMsg.message
                                                }
                                                onChange={(e) =>
                                                  setEditMsg({
                                                    ...editMsg,
                                                    message: e.target.value,
                                                    Team_Message_Text:
                                                      e.target.value,
                                                  })
                                                }
                                                onKeyUp={(e) => {
                                                  if (
                                                    e.key === "Enter" &&
                                                    !e.shiftKey &&
                                                    e.target.value.length
                                                  ) {
                                                    if (socket)
                                                      socket.emit(
                                                        "edit-message",
                                                        {
                                                          schannel_id:
                                                            currentSChannel,
                                                          message_id:
                                                            editMsg.message_id ||
                                                            editMsg.Team_Message_Id,
                                                          message:
                                                            editMsg.Team_Message_Text ||
                                                            editMsg.message,
                                                        }
                                                      );
                                                    setEditMsg({
                                                      message_id: null,
                                                      Sender_Id: null,
                                                    });
                                                  }
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <>
                                              {/* <Link> */}
                                              <img
                                                src={
                                                  msg.IsAllRead
                                                    ? double_tick
                                                    : gray_tick
                                                }
                                                alt="double_tick"
                                                className="ms-3"
                                              />
                                              {/* </Link> */}
                                              <div
                                                className="send-msg-main"
                                                onMouseOver={() => {
                                                  setselectedReaction(
                                                    msg.Team_Message_Id
                                                  );
                                                }}
                                                onMouseLeave={() =>
                                                  setselectedReaction(null)
                                                }
                                              >
                                                {msg.files &&
                                                  msg.files.map(
                                                    (file, index) => {
                                                      let path =
                                                        file.split(".com")[1];
                                                      const fileExtension =
                                                        file?.split(".")
                                                          ?.length > 1 &&
                                                        file
                                                          ?.split(".")
                                                        [
                                                          file?.split(".")
                                                            .length - 1
                                                        ].toLowerCase();

                                                      return fileExtension.includes(
                                                        "png"
                                                      ) ||
                                                        fileExtension.includes(
                                                          "jpg"
                                                        ) ||
                                                        fileExtension.includes(
                                                          "jpeg"
                                                        ) ||
                                                        fileExtension.includes(
                                                          "gif"
                                                        ) ||
                                                        fileExtension.includes(
                                                          "gif"
                                                        ) ||
                                                        msg.Resource_Path?.includes(
                                                          ".mp3"
                                                        ) ||
                                                        msg.Resource_Path?.includes(
                                                          ".m4a"
                                                        ) ? (
                                                        <a
                                                          href={file}
                                                          download
                                                          target="_blank"
                                                          rel="noreferrer"
                                                        >
                                                          {msg.Resource_Path?.includes(
                                                            ".mp3"
                                                          ) ||
                                                            msg.Resource_Path?.includes(
                                                              ".m4a"
                                                            ) ? (
                                                            <audio
                                                              src={file}
                                                              controls
                                                            ></audio>
                                                          ) : (
                                                            <img
                                                              src={file}
                                                              className="image"
                                                              key={
                                                                "image" + index
                                                              }
                                                              alt=""
                                                            />
                                                          )}
                                                        </a>
                                                      ) : (
                                                        <img
                                                          src={fileIcon}
                                                          className="image"
                                                          key={"image" + index}
                                                          alt=""
                                                        />
                                                      );
                                                    }
                                                  )}
                                                {msg.message && (
                                                  <>
                                                    <p
                                                      className="msg-text "
                                                      style={{
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      {msg?.message.includes(
                                                        "@"
                                                      ) ? (
                                                        <MensionUsers
                                                          text={msg.message}
                                                          users={mensioned}
                                                          index={index}
                                                        />
                                                      ) : (
                                                        msg?.message && (
                                                          <ChatMessage
                                                            msg={msg?.message}
                                                          />
                                                        )
                                                      )}
                                                    </p>
                                                  </>
                                                )}
                                              </div>
                                            </>
                                          )}
                                        </>
                                      )}
                                      {selectedReaction &&
                                        selectedReaction == msg?.message_id && (
                                          <ReactionBarSelector
                                            iconSize={16}
                                            reactions={emojiReactions}
                                            onSelect={(selectedEmoji) => {
                                              const selectedReaction =
                                                emojiReactions.find(
                                                  (reaction) =>
                                                    reaction.label ===
                                                    selectedEmoji
                                                );
                                              const isSelectedEmojiPresent =
                                                msg?.reactions &&
                                                msg.reactions.length &&
                                                msg.reactions.some(
                                                  (item) =>
                                                    item.reaction ==
                                                    selectedReaction?.id
                                                );
                                              if (!isSelectedEmojiPresent) {
                                                socket &&
                                                  socket.emit("add-reaction", {
                                                    reaction:
                                                      selectedReaction?.id,
                                                    schannel_id:
                                                      msg?.schannel_id,
                                                    message_id: msg?.message_id,
                                                  });
                                              }
                                            }}
                                          />
                                        )}
                                    </div>

                                    <div className="time-date-sms-send">
                                      {/* {formattedTime} */}
                                      {moment(msg.created_at).fromNow()}{" "}
                                      {msg.IsEdit && (
                                        <span> Edited &nbsp;&nbsp; </span>
                                      )}
                                      {msg?.reactions &&
                                        msg.reactions.length > 0 &&
                                        msg.reactions.map((item) => {
                                          let displayEmoji =
                                            emojiReactions.find(
                                              (emj) => emj?.id == item?.reaction
                                            );
                                          return (
                                            <span
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                removeReaction(
                                                  item?.reaction_id,
                                                  msg?.schannel_id,
                                                  msg?.message_id
                                                )
                                              }
                                            >
                                              {displayEmoji?.emoji &&
                                                displayEmoji?.emoji}
                                              &nbsp;
                                            </span>
                                          );
                                        })}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="receive-msg"
                                      ref={(elem) =>
                                        (msgRef.current[index] = elem)
                                      }
                                      onMouseOver={() => {
                                        setselectedReaction(msg?.message_id);
                                      }}
                                      onMouseLeave={() =>
                                        setselectedReaction(null)
                                      }
                                    >
                                      <div className="msg-with-img">
                                        <img src={user_pic2} alt="" />
                                      </div>
                                      {msg?.ReplyTo ? (
                                        <RepliesTo
                                          sernderName={msg?.sender_name}
                                          msg={msg}
                                          repliedTo={replied}
                                          msgRef={msgRef}
                                        />
                                      ) : (
                                        <div className="d-flex align-items-center">
                                          <div className="right-receive-msg">
                                            <span className="senderName">
                                              {msg?.sender_name}
                                            </span>
                                            {msg?.message ? (
                                              <div className="receive-msg-main">
                                                <p
                                                  className="msg-text "
                                                  style={{ textAlign: "left" }}
                                                >
                                                  {msg.message.includes("@") ? (
                                                    <MensionUsers
                                                      text={msg.message}
                                                      users={mensioned}
                                                      index={index}
                                                    />
                                                  ) : (
                                                    msg?.message && (
                                                      <ChatMessage
                                                        msg={msg?.message}
                                                      />
                                                    )
                                                  )}
                                                </p>
                                              </div>
                                            ) : null}
                                            {selectedReaction &&
                                              selectedReaction ==
                                              msg?.message_id && (
                                                <ReactionBarSelector
                                                  iconSize={16}
                                                  reactions={emojiReactions}
                                                  onSelect={(selectedEmoji) => {
                                                    const selectedReaction =
                                                      emojiReactions.find(
                                                        (reaction) =>
                                                          reaction.label ===
                                                          selectedEmoji
                                                      );
                                                    const isSelectedEmojiPresent =
                                                      msg?.reactions &&
                                                      msg.reactions.length &&
                                                      msg.reactions.some(
                                                        (item) =>
                                                          item.reaction ==
                                                          selectedReaction?.id
                                                      );
                                                    if (!isSelectedEmojiPresent) {
                                                      socket &&
                                                        socket.emit(
                                                          "add-reaction",
                                                          {
                                                            reaction:
                                                              selectedReaction?.id,
                                                            schannel_id:
                                                              msg?.schannel_id,
                                                            message_id:
                                                              msg?.message_id,
                                                          }
                                                        );
                                                    }
                                                  }}
                                                />
                                              )}
                                            {msg.files &&
                                              msg.files.map((file, index) => {
                                                let path = file.split(".com")[1];
                                                const fileExtension =
                                                  file?.split(".")?.length > 1 &&
                                                  file
                                                    ?.split(".")
                                                  [
                                                    file?.split(".").length - 1
                                                  ].toLowerCase();
                                                return (
                                                  <div>
                                                    {fileExtension == "png" &&
                                                      fileExtension == "jpg" &&
                                                      fileExtension == "jpeg" &&
                                                      fileExtension == "gif" &&
                                                      fileExtension == "svg" ? (
                                                      <a
                                                        href={
                                                          BASE_URL_CHAT_Image +
                                                          path
                                                        }
                                                        download
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        <img
                                                          src={
                                                            BASE_URL_CHAT_Image +
                                                            path
                                                          }
                                                          className="image"
                                                          key={
                                                            "file send" + index
                                                          }
                                                          alt=""
                                                        />
                                                      </a>
                                                    ) : (
                                                      <a
                                                        href={
                                                          BASE_URL_CHAT_Image +
                                                          path
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        <img
                                                          src={fileIcon}
                                                          className="image"
                                                          key={"file sen" + index}
                                                          alt=""
                                                        />
                                                      </a>
                                                    )}
                                                  </div>
                                                );
                                              })}
                                          </div>
                                          <Dropdown className="ActionDropdown  me-4">
                                            <Dropdown.Toggle
                                              variant="success"
                                              id={"dropdown-basic" + index}
                                              className="ActionToggle  ml-3"
                                            >
                                              <img src={hr_three_dot} alt="" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="ActionMenu">
                                              <ul className="actionnmenulist">
                                                <li
                                                  onClick={() => {
                                                    setReplyTo({ ...msg });
                                                    inputRef.current.focus();
                                                  }}
                                                >
                                                  Reply
                                                </li>
                                                <li>Create Task</li>
                                              </ul>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      )}
                                      {/* <Dropdown className="ActionDropdown  me-4">
                                        <Dropdown.Toggle
                                          variant="success"
                                          id={"dropdown-basic" + index}
                                          className="ActionToggle  ml-3"
                                        >
                                          <img src={hr_three_dot} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="ActionMenu">
                                          <ul className="actionnmenulist">
                                            <li
                                              onClick={() => {
                                                setReplyTo({ ...msg });
                                                inputRef.current.focus();
                                              }}
                                            >
                                              Reply
                                            </li>
                                            <li>Create Task</li>
                                          </ul>
                                        </Dropdown.Menu>
                                      </Dropdown> */}
                                    </div>

                                    <div className="time-date-sms-recieve me-5">
                                      {/* {formattedTime} */}
                                      {moment(msg.created_at).fromNow()}{" "}
                                      {msg.IsEdit && (
                                        <span> Edited &nbsp;&nbsp; </span>
                                      )}
                                      {msg?.reactions &&
                                        msg.reactions.length > 0 &&
                                        msg.reactions.map((item) => {
                                          let displayEmoji =
                                            emojiReactions.find(
                                              (emj) => emj?.id == item?.reaction
                                            );
                                          return (
                                            <span
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                removeReaction(
                                                  item?.reaction_id,
                                                  msg?.schannel_id,
                                                  msg?.message_id
                                                )
                                              }
                                            >
                                              {displayEmoji?.emoji &&
                                                displayEmoji?.emoji}
                                              &nbsp;
                                            </span>
                                          );
                                        })}
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          })}

                        {userTyping.status === true &&
                          userTyping?.EmpId != userData?.EmpId &&
                          userTyping.currentSChannel == currentSChannel && (
                            <div className="d-flex">
                              {" "}
                              <p
                                style={{ fontSize: "12px", marginRight: "7px" }}
                              >
                                {" "}
                                {userTyping?.userName + " is typing"}
                              </p>{" "}
                              <TypingLoader />{" "}
                            </div>
                          )}
                        <div ref={lastMsg}></div>
                      </div>
                    )}

                    <div class="d-flex img-preview-section">
                      {uploadedFiles.map((file, index) => {
                        return (
                          file && (
                            <div
                              className="ImgPreview"
                              key={"uploadedImage" + index}
                            >
                              {!file?.name?.includes(".jpeg") &&
                                !file?.name?.includes(".png") &&
                                !file?.name?.includes(".jpg") &&
                                !file?.type?.includes("audio") ? (
                                <img src={fileIcon} className="image" alt="" />
                              ) : (
                                <>
                                  {file?.type?.includes("audio") ? (
                                    <audio
                                      src={URL.createObjectURL(file)}
                                      controls
                                      style={{ marginLeft: "15px" }}
                                    ></audio>
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      className="me-3 image"
                                      alt=""
                                    />
                                  )}
                                </>
                              )}

                              <img
                                src={close_modal_dark}
                                className="close_icon"
                                onClick={() => {
                                  setUploadedFiles(
                                    uploadedFiles.filter(
                                      (file, fileIndex) => fileIndex != index
                                    )
                                  );
                                }}
                                alt=""
                              />
                            </div>
                          )
                        );
                      })}
                    </div>

                    <div
                      className="send_section_btn_inputs"
                      style={{ bottom: 0 }}
                    >
                      {emogi && (
                        <EmojiPicker
                          onEmojiClick={(e) => {
                            setmessage(message + e.emoji);
                          }}
                        />
                      )}
                      <div className="speed-dial">
                        {/* <SpeedDial
                          model={items}
                          direction='up'
                          onClick={() => setEmogi(false)}
                        /> */}
                        <Toast ref={toast} />
                        <SpeedDial model={items} direction="up" />
                      </div>
                      {(replyTo.Team_Message_Id || replyTo.message_id) && (
                        <ReplyTo replyTo={replyTo} msgRef={msgRef} />
                      )}
                      <div className={`text-input`}>
                        <Mensions
                          setEmogi={setEmogi}
                          replyTo={replyTo}
                          setUploadedFiles={setUploadedFiles}
                          sendMessage={sendMessage}
                          uploadedFiles={uploadedFiles}
                          message={message}
                          setMessage={setmessage}
                          menstionedUsers={menstionedUsers}
                          setMenstionedUsers={setMenstionedUsers}
                          inputRef={inputRef}
                          recentChats={recentChats}
                          setRecentChats={setRecentChats}
                          pinnedChats={pinnedChats}
                          setPinnedChats={setPinnedChats}
                          activeChat={activeChat}
                        />

                        {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Type a message here"
                        onChange={(e) => {
                          typing();
                          setmessage(e.target.value)
                        }}
                        
                        onBlur={() => stopTyping()}
                        value={message}
                      /> */}
                      </div>
                      {/* <div className="send-btn">
                        <img
                          src={mic}
                          onClick={() => {
                            checkMicrophoneConnection();
                          }}
                          alt="mic"
                        />
                      </div> */}
                      <div
                        className="send-btn"
                        onClick={(e) => {
                          if (message.length || uploadedFiles.length) {
                            setEmogi(false);
                            sendMessage(
                              message,
                              [...uploadedFiles],
                              replyTo,
                              menstionedUsers
                            );
                            setUploadedFiles([]);
                            setReplyTo({});
                            setmessage("");
                            setMenstionedUsers([]);
                          }
                        }}
                      >
                        <img src={send_btn} alt="" />
                      </div>
                    </div>
                  </>
                )}

                <input
                  type="file"
                  className="d-none"
                  ref={fileUpload}
                  multiple
                  onChange={(e) => {
                    e.preventDefault();
                    const newFiles = Array.from(e.target.files);
                    setUploadedFiles((prevFiles) => [
                      ...prevFiles,
                      ...newFiles,
                    ]);
                    if (fileUpload.current) {
                      fileUpload.current.value = null;
                    }
                  }}
                />
              </>
            ) : (
              <div className="noMsgsBlog h-100">
                <img src={no_chats} className="chatsImg" alt="" /> <br />
                <span> No Active chat found </span>
              </div>
            )}
          </>
        )}

        <Modal
          show={openVoiceReq}
          onHide={() => {
            closeOutsideClick && setOpenVoiceReq(false);
          }}
          centered
          size="md"
        >
          <Modal.Header>
            <Modal.Title>Send Voice Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="d-flex justify-content-center "
              style={{ marginBottom: "120px" }}
            >
              <AudioRecording
                submitRecording={(record) => {
                  const file = new File([record], "6367.mp3", {
                    type: "audio/mp3",
                  });
                  console.log(record, file);
                  setUploadedFiles([...uploadedFiles, file]);
                  setOpenVoiceReq(false);
                }}
                closeOutsid={() => setCloseOutSideClic(false)}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showAudioErr}
          centered
          size="md"
          onHide={() => {
            setShowAudioErr(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Microphone Not Connected!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center mb-4">
              <h5 className="text-danger text-bolder">
                Please Connect Yor Microphone!
              </h5>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={members}
          onHide={() => {
            setMembers(false);
          }}
          animation={false}
          centered
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Members</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* For Admin only */}
            <form className="row">
              {activeChat?.Team_Channel_Description &&
                activeChat?.Team_Channel_Description &&
                activeChat?.em?.length > 2 && (
                  <>
                    <div class="form-group col-lg-4">
                      <input
                        type="text"
                        value={schannelName}
                        onChange={(e) => setSchannelName(e.target.value)}
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Team Channel Name"
                      />
                    </div>

                    <button
                      onClick={() => {
                        socket &&
                          socket.emit("update-schannel-name", {
                            Team_Channel_Description: schannelName,
                            schannel_id: currentSChannel,
                          });

                        setMembers(false);
                        notifySuccess("Group Name Updated successfully");
                      }}
                      type="button"
                      class="btn btn-primary col-lg-1"
                    >
                      Update
                    </button>
                  </>
                )}
            </form>
            <div className="table-Model mb-4">
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Sr. no</th>
                    <th>Name</th>
                    <th>Teams</th>
                    <th>Branch</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {membersArray &&
                    membersArray
                      // .filter((item) => item?.User_Id != "everyone")
                      // .filter((item) => {
                      //   if (membersArray.length === 2) {
                      //     return item?.User_Id != userData?.EmpId;
                      //   } else {
                      //     return item;
                      //   }
                      // })
                      .map((member, index) => {
                        return (
                          <tr
                            className="table-row-main"
                            key={"member " + index}
                          >
                            <td>{index + 1}</td>
                            {console.log(member)}
                            <td
                              onClick={() =>
                                navigate("/Profile/" + member?.User_Id)
                              }

                            >
                              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <ChatImage
                                  PhotoPath={
                                    member.PhotoPath}
                                  name={member.FirstName}
                                  lastName={member.LastName}
                                />
                                <span >{member.FirstName + " " + member.LastName}</span>

                                {/* <span>Admin</span> */}
                              </div>
                            </td>
                            <td>{member.TeamName}</td>
                            <td>{member.LocationName}</td>
                            {/* <td>{member.last_seen != null && moment(member.last_seen).fromNow()}</td> */}
                            <td>
                              {activeChat?.Team_Channel_Name !== "General" &&
                                activeChat?.em &&
                                activeChat?.em.length > 1 ? (
                                <Dropdown
                                  className="ActionDropdown"
                                  id="Drop_ActionDropdown"
                                >
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                    className="ActionToggle"
                                    ref={pindropdown}
                                  >
                                    <img src={more_vertical} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="ActionMenu">
                                    <ul className="actionnmenulist">
                                      <li
                                        onClick={() => {
                                          socket &&
                                            socket.emit("remove-member", {
                                              schannel_id: currentSChannel,
                                              memberId: member.User_Id,
                                            });
                                        }}
                                      >
                                        Remove
                                      </li>
                                    </ul>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
              {activeChat?.Team_Channel_Name !== "General" &&
                activeChat?.Team_Channel_Description &&
                activeChat?.Team_Channel_Description &&
                activeChat?.em?.length > 2 && (
                  <button
                    onClick={() => {
                      setMembers(false);
                      setAddMembers(true);
                    }}
                  >
                    +Add Member
                  </button>
                )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={addMembers}
          onHide={() => {
            setAddMembers(false);
          }}
          animation={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Members</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row px-3">
              <div className="col-lg-12 pe-0 mb-2">
                <label for="" class="mb-2 text-grey">
                  Employee Name
                </label>
                <Multiselect
                  options={
                    currentRoute.includes("projects")
                      ? teamMembers
                        .filter(
                          (member) =>
                            !membersArray
                              .map((chanMember) => chanMember.User_Id)
                              .includes(member.User_Id)
                        )
                        .map((member) => {
                          return {
                            id: member.User_Id,
                            name: member.FirstName + " " + member.LastName,
                          };
                        })
                      : suggetions
                        .filter(
                          (member) =>
                            !membersArray
                              .map((chanMember) => chanMember.User_Id)
                              .includes(member.EmployeeId)
                        )
                        .map((member) => {
                          return {
                            id: member.EmployeeId,
                            name: member.FirstName + " " + member.LastName,
                          };
                        })
                  } // Options to display in the dropdown
                  // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  onSelect={(selectedList) => {
                    setMembersAdded(selectedList);
                  }} // Function will trigger on select event
                  onRemove={(selectedList) => {
                    setMembersAdded(selectedList);
                  }} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  closeOnSelect="false"
                />
              </div>

              {/* <div className="col-lg-4 pe-0 mb-2">
                <label for="" class="mb-2 text-grey">
                  Team
                </label>
                <select
                  class="form-select tasKCategory1"
                  aria-label="Default select example"
                >

                  <option value="">Please select Team</option>
                  { teamMembers.map(team=> {
                    return{TeamName: team.TeamName,TeamId:team.TeamId}
                  }).map(team=>{
                    return(
                  <option value={team.TeamId}>{team.TeamName}</option>

                    )
                  })
                  
                  }
                </select>
              </div> */}
              {/* <div className="col-lg-4 pe-0 mb-2">
                <label for="" class="mb-2 text-grey">
                  Branch
                </label>
                <select
                  class="form-select tasKCategory1"
                  aria-label="Default select example"
                >
                  
                </select>
                <div className="invalid-feedback">Please select Branch</div>
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer className="pe-0">
            <div className="d-flex justify-content-end align-items-center mainBtnsSub py-3 px-3">
              <button
                className="btn-width cancelBtn mx-3"
                onClick={() => {
                  setAddMembers(false);
                  setMembers(true);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-width saveBtn"
                onClick={() => {
                  save();
                  setAddMembers(false);
                }}
              >
                Add
              </button>
            </div>
          </Modal.Footer>
        </Modal> 

        {/* Sab chainal  */} 

        <Modal
          show={addSubMembers}
          onHide={() => {
            setAddSubMembers(false);
          }}
          animation={false}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Sub Members</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row px-3">
              <div className="col-lg-12 pe-0 mb-2">
                <label for="" class="mb-2 text-grey">
                  Employee Name
                </label>
                <Multiselect
                  // options={
                  //   currentRoute.includes("projects")
                  //     ? teamMembers
                  //       .filter(
                  //         (member) =>
                  //           !membersArray
                  //             .map((chanMember) => chanMember.User_Id)
                  //             .includes(member.User_Id)
                  //       )
                  //       .map((member) => {
                  //         return {
                  //           id: member.User_Id,
                  //           name: member.FirstName + " " + member.LastName,
                  //         };
                  //       })
                  //     : suggetions
                  //       .filter(
                  //         (member) =>
                  //           !membersArray
                  //             .map((chanMember) => chanMember.User_Id)
                  //             .includes(member.EmployeeId)
                  //       )
                  //       .map((member) => {
                  //         return {
                  //           id: member.EmployeeId,
                  //           name: member.FirstName + " " + member.LastName,
                  //         };
                  //       })
                  // } // Options to display in the dropdown

                 
                  options={
                    SubchianlMemberList
                    
                      .map((member) => ({
                        id: member.User_id,
                        name: `${member.FirstName} ${member.LastName}`,
                      }))
                  }
                  // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  onSelect={(selectedList) => {
                    setMembersAdded(selectedList);
                  }} // Function will trigger on select event
                  onRemove={(selectedList) => {
                    setMembersAdded(selectedList);
                  }} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  closeOnSelect="false"
                />
              </div>

              {/* <div className="col-lg-4 pe-0 mb-2">
                <label for="" class="mb-2 text-grey">
                  Team
                </label>
                <select
                  class="form-select tasKCategory1"
                  aria-label="Default select example"
                >

                  <option value="">Please select Team</option>
                  { teamMembers.map(team=> {
                    return{TeamName: team.TeamName,TeamId:team.TeamId}
                  }).map(team=>{
                    return(
                  <option value={team.TeamId}>{team.TeamName}</option>

                    )
                  })
                  
                  }
                </select>
              </div> */}
              {/* <div className="col-lg-4 pe-0 mb-2">
                <label for="" class="mb-2 text-grey">
                  Branch
                </label>
                <select
                  class="form-select tasKCategory1"
                  aria-label="Default select example"
                >
                  
                </select>
                <div className="invalid-feedback">Please select Branch</div>
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer className="pe-0">
            <div className="d-flex justify-content-end align-items-center mainBtnsSub py-3 px-3">
              <button
                className="btn-width cancelBtn mx-3"
                onClick={() => {
                  setAddSubMembers(false);
                  setMembers(true);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-width saveBtn"
                onClick={() => {
                  save();
                  setAddSubMembers(false);
                }}
              >
                Add
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default memo(ChatBox);
