import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { useParams, useSearchParams } from 'react-router-dom';
import { PostCallWithErrorResponse, putRequest, putWithAuthCall, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import { useNavigate } from "react-router-dom";
import { ToastMsg } from "../TostMsg";
import ApiConfig from "../../api/ApiConfig";

export default function Updatewhatsapp() {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const { id } = useParams();
  const naviget = useNavigate()

  const [searchParams] = useSearchParams();

  console.log("id--->", id)
  const [addwhatsApp, setAddWhatsApp] = useState({
    token: '',
    source: "",
    phone_number_id: ""
  })

  const onUpdateWhatsAppData = (e) => {
    e?.preventDefault()
    const body = {
      id: id,
      token: addwhatsApp.token,
      source: addwhatsApp.source,
      phone_number_id: addwhatsApp.phone_number_id
    }


    putRequest(userData?.CRMUrlNode + ApiConfig.GETWHATSAPPUPDATE + '/' + id, JSON.stringify(body))
      .then((res) => {
        console.log("add whatsApp Data success--->", res)
        if (res?.success) {
          ToastMsg("success", res?.message)
          naviget("/whatsappsetting")

        } else {
          ToastMsg("error", res.json?.message)
        }
      })
      .catch((err) => {
        console.log("Add whatsApp Data Error--->", err)
      })
  }

  useEffect(() => {
    if (id) {
      simpleGetCallWithErrorResponse(userData?.CRMUrlNode + ApiConfig.GETWHATSAPPDETAILS + `${id}`)
        .then((res) => {
          console.log("whatsApp Details--->", res)
          setAddWhatsApp(res.json.data)
        })
        .catch((err) => {
          console.log("whatsApp Details--->", err)

        })
    }
  }, [])
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role ">
          <h6 className="mt-2">Update WhatsApp Settings</h6>
        </div>

        <div className="WeeklyMain">
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Token :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <input
                  //   value={newPassword}
                  placeholder="TOKEN"
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    //   fontSize: "14px",
                    height: "38px",
                    borderRadius: "10px",
                  }}
                  value={addwhatsApp.token}

                  onChange={(e) => {
                    setAddWhatsApp({ ...addwhatsApp, token: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Source :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <input
                  placeholder="SOURCE"
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    //   fontSize: "14px",
                    height: "38px",
                    borderRadius: "10px",
                  }}
                  value={addwhatsApp.source}

                  onChange={(e) => {
                    setAddWhatsApp({ ...addwhatsApp, source: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Phone Number ID :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <input
                  value={addwhatsApp.phone_number_id}
                  placeholder="PHONE NUMBER ID"
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    //   fontSize: "14px",
                    height: "38px",
                    borderRadius: "10px",
                  }}
                  onChange={(e) => {
                    setAddWhatsApp({ ...addwhatsApp, phone_number_id: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="align-items-center justify-content-center d-flex mt-5 mb-3">
            <button className="custoBtnForCrmCall" onClick={onUpdateWhatsAppData}>
              <a className=" textcolor" href="WhatsappSetting">
                Update Whatsapp Token
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
