import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({
  totalPage,
  data,
  pageChangeHandler,
  usersPerPage,
  currentPage,
  searchValue,
  searchedDataPageCounts,
}) => {
  const pageCount = Math.ceil(data?.length / usersPerPage);
  const searchedPageCounts = Math.ceil(
    searchedDataPageCounts?.length / usersPerPage
  );
  return (
    <div
      className="row mt-4"
    // style={{
    //   position: "absolute",
    //   bottom: "20px",
    //   right: "25px",
    // }}
    >
      <div className="col-lg-12">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={totalPage}
          onPageChange={(page) => pageChangeHandler(page.selected)}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          forcePage={currentPage - 1}
        />
      </div>
    </div>
  );
};

export default Pagination;
