import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import { formatDate } from "../../common";

const CandidatesData = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);


  const [dataList, setDataList] = useState([])
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterSerchName, setFilterSerchName] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectTechmology, setSelectTechmology] = useState('')
  const [selectLocation, setSelectLocation] = useState('')

  const navigate = useNavigate();

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const navigetAgentDedails = (id) => {
    navigate(`/UserDetailsForm/${id}`);
  }

  // show button end

  const getAgentData = () => {
    setLoading(true)

    simpleGetCallWithToken(ApiConfig.GET_ALL_CANDITED_DATA)
      .then((response) => {
        console.log(response.results)
        setDataList(response)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)

      })
  }

  useEffect(() => {
    getAgentData()
    console.log("android technology>>>",selectTechmology)
  }, [])


  useEffect(() => {

    let filteredByTech = dataList?.candidates;
    if (selectTechmology) {
      filteredByTech = filteredByTech?.filter((item) => item?.technology === selectTechmology);
    }

    if (selectLocation) {
      filteredByTech = filteredByTech?.filter((item) => item?.location === selectLocation);
    }
  
    // Then, filter by searchValue on the filtered list (filtered by technology if selected)
    const filteredData = filteredByTech?.filter((item) => 
      item?.firstname?.toLowerCase().includes(searchValue.toLowerCase())
    );
  
    
    console.log("Filtered Data>>", filteredData);
    setFilterSerchName(filteredData);

  }, [dataList, searchValue,selectTechmology,selectLocation]);
  // show button end

  const crmCandidatesData = [
    {
      name: "shoeb",
      mobileNumber: "8007317056",
      email: "shoeb@cloudxperte.com	",
      currentProfession: "None",
      updatedDate: "10-Jun-2024",
      loation: "None",
      technology: "None",
      currentCtc: "None",
      lookingJobRole: "None",
      experience: "6",
      noticePeriod: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      mobileNumber: "8007317056",
      email: "shoeb@cloudxperte.com	",
      currentProfession: "None",
      updatedDate: "10-Jun-2024",
      loation: "None",
      technology: "None",
      currentCtc: "None",
      lookingJobRole: "None",
      experience: "6",
      noticePeriod: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      mobileNumber: "8007317056",
      email: "shoeb@cloudxperte.com	",
      currentProfession: "None",
      updatedDate: "10-Jun-2024",
      loation: "None",
      technology: "None",
      currentCtc: "None",
      lookingJobRole: "None",
      experience: "6",
      noticePeriod: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      mobileNumber: "8007317056",
      email: "shoeb@cloudxperte.com	",
      currentProfession: "None",
      updatedDate: "10-Jun-2024",
      loation: "None",
      technology: "None",
      currentCtc: "None",
      lookingJobRole: "None",
      experience: "6",
      noticePeriod: "None",
      //   viewDetails :""
    },
    {
      name: "shoeb",
      mobileNumber: "8007317056",
      email: "shoeb@cloudxperte.com	",
      currentProfession: "None",
      updatedDate: "10-Jun-2024",
      loation: "None",
      technology: "None",
      currentCtc: "None",
      lookingJobRole: "None",
      experience: "6",
      noticePeriod: "None",
      //   viewDetails :""
    },
  ];
  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role">
          <h6 className="mt-2">Candidates Data</h6>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary mb-0">
          <div className="search-input-wrapper">
            <input type="text"
              placeholder="Search here"
              onChange={(e) => {
                setSearchValue(e.target.value);
                setCurrentPage(0);
              }}
            />

            {Dark === "lightMode" ? (
              <img src={searchicon} alt="" />
            ) : (
              <img src={ic_search_dark} alt="" />
            )}
          </div>

          <div className="entries-wrapper">
            <p>Entries per page</p>
            <select
              className="form-select select-drop-icon"
              aria-label="Default select example"
              onChange={(e) => {
                setUsersPerPage(e.target.value);
                setCurrentPage(0);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value={25} selected>25</option>
            </select>
          </div>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary mb-0">
          <div className="role-table-header accoridance">
            <div className="entries-wrapper">
              <p>Select Technology</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                placeholder="Select Technology"
                onChange={(e) => {
                  setSelectTechmology(e.target.value);
                  // setCurrentPage(0);
                }}
              >
                <option value="" disabled selected>
                  Select
                </option>
                {dataList?.technologies
                  ?.filter((item) => item && item !== "null") // Filter out null or empty values
                  .map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}

              </select>
            </div>
            {/* <option value="android">Android</option>
                <option value="flutter">flutter</option>
                <option value="iOS">iOS</option>
                <option value=".net">.Net</option> */}
            <div className="entries-wrapper">
              <p>Select Location</p>

              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setSelectLocation(e.target.value);
                  // setCurrentPage(0);
                }}
              >
                <option value="" disabled selected>
                  Select
                </option>
                {dataList?.locations
                  ?.filter((item) => item && item !== "null") // Filter out null or empty values
                  .map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
          <div className="role-table-header accoridance">
            <div className="search-input-wrapper">
              <button className="sml-btn-sub-header">Submit</button>
            </div>
            <div className="search-input-wrapper">
              <button className="sml-btn-sub-header">Reset</button>
            </div>
            <div className="search-input-wrapper">
              <button className="sml-btn-sub-header">Download</button>
            </div>
          </div>
          <div className="entries-wrapper myAccordiance">
            <p>
              <b className="me-2">CANDIDATE COUNT : {dataList?.count}</b>
            </p>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">NAME</th>
                <th scope="col">MOBILE NUMBER</th>
                <th scope="col">EMAIL</th>
                <th scope="col">CURRENT PROFESSION</th>
                <th scope="col">UPDATED DATE</th>
                <th scope="col">LOCATION</th>
                <th scope="col">TECHNOLOGY</th>
                <th scope="col">CURRENT CTC</th>
                <th scope="col">LOOKING JOB ROLE</th>
                <th scope="col">EXPERIENCE</th>
                <th scope="col">NOTICE PERIOD</th>
                <th scope="col">VIEW DETAILS</th>
              </tr>
            </thead>
            <tbody>
              {
                loading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (filterSerchName?.slice(startIndex, endIndex)?.map((data, index) => (
                  <tr key={index} className="table-row-custom">
                    <td>{data.firstname} {data.lastname}</td>
                    <td>{data.phone_number}</td>
                    <td>{data.email}</td>
                    <td>{data.current_profession}</td>
                    <td>{formatDate(data.updated_at)}</td>
                    <td>{data.location}</td>
                    <td>{data.technology}</td>
                    <td>{data.currentCtc}</td>
                    <td>{data.lookingJobRole}</td>
                    <td>{data.year_of_exp}</td>
                    <td>{data.notice_period}</td>
                    <td>
                      <button className="custoBtnForCrmCall" onClick={() => navigetAgentDedails(data?.id)}>
                        <Link
                          // to="/UserDetailsForm"
                          className="custoBtnForCrmCallLink"
                        >
                          Details
                        </Link>
                      </button>
                    </td>
                  </tr>
                ))
                )
              }
            </tbody>
          </table>
          <Pagination
            data={filterSerchName}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={filterSerchName}
          />
        </div>
      </div>
    </div>
  );
};

export default CandidatesData;
