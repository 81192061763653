import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { PostCallWithErrorResponse, postWithAuthCall, putMultipartWithAuthCall, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import { values } from "lodash";
import Loader from "../Loader";


function CustomGroup() {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [defaultgroup, setDefaultgroup] = useState(true);
  const [show, setShow] = useState(false)
  const [userList, setUserList] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [groupList, setGroupList] = useState([])
  const [sourceList, setSourceList] = useState([])
  const [templateList, setTemplateList] = useState([])
  const [validated, setValidated] = useState(false);
  const [selectedValue, setSelectedValue] = useState('')
  const { id } = useParams()


  const naviget = useNavigate()

  const [groupData, setGropuData] = useState({
    name: "",
    description: "",
    fileIcon: ""
  })

  const [addPromotionData, setAddPromotionData] = useState({
    title: "",
    description: "",
    source: "",
    template: "",
    group: "",
    testNumber: "",
    date: "",
    time: "",
    attechment: "",
    isTestMessage: false,
    url: ""
  });

  const [loading,setLoading] = useState(false)

  console.log("group Data", groupData)
  console.log("addPromotionData Data", addPromotionData)
  const handleClose = () => {
    setShow(false)
    setValidated(false)
  }

  const handleChange = (selected) => {

    const takeValue = selected.map((item) => item.value)
    console.log("select user Value>>>>", takeValue)
    setSelectedOptions(takeValue);
  };

  const handleChangeGroup = (selected) => {

    const takeValue = selected.map((item) => item.value)
    console.log("select group Value>>>>", takeValue)
    setSelectedOptions1(takeValue);
  };

  const getUserList = () => {
    simpleGetCallWithErrorResponse(ApiConfig.USER_LIST)
      .then((res) => {
        if(res?.json?.success === true){
          let options = res?.json?.data?.map((name) => ({
            label: name.username,
            value: name.id,
          }));
          setUserList(options)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getDetailsById = (id) => {
    setLoading(true)
    simpleGetCallWithErrorResponse(ApiConfig.GET_PROMOTION_DETAILS_BY_ID + id)
      .then((res) => {
        setLoading(false)

        setAddPromotionData({
          ...addPromotionData,
          title: res.json?.data?.message_title,
          description: res.json?.data?.message,
          source: res.json?.data?.source_id,
          template: res.json?.data?.template_id,
          attechment: res.json?.data?.message_attachment_url,
          time: res.json?.data?.message_scheduled_time,
          date: res.json?.data?.message_scheduled_date,
          testNumber: res.json?.data?.test_number,
          isTestMessage: res.json?.data?.is_test_message,
          url: res.json?.data?.message_url,

        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getTemleteList = (id) => {
    simpleGetCallWithErrorResponse(ApiConfig.TEMPLETELIST + id + `?platform=Whatsapp`)
      .then((res) => {

        setTemplateList(res.json?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getGroupLIstList = () => {
    let groupType = defaultgroup === true ? "Default" : "Custom"
    simpleGetCallWithErrorResponse(ApiConfig.GROUPlIST + `?page=1&groupType=${groupType}&page_size=499995&role=CRM%20Admin&search=${selectedValue}`
      // `/?search=${selectedValue}&page=1&page_size=100&groupType=${groupType}&platform=SMS`
    )
      .then((res) => {
        let options = res?.json?.data?.map((name) => ({
          label: name.group_name,
          value: name.id,
        }));
        setGroupList(options)
        console.log("groupList>>>", res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getSourceList = () => {
    simpleGetCallWithErrorResponse(ApiConfig.SOURCELIST)
      .then((res) => {
        // let options = res?.json?.data?.map((name) => ({
        //   label: name.group_name,
        //   value: name.id,
        // }));
        setSourceList(res.json?.data)
        console.log("groupList>>>", res)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(()=>{
    getUserList()
    getSourceList()
  },[])
  useEffect(() => {
  
    if (id) {
      getDetailsById(id)
    }
  }, []);

  useEffect(() => {
    if (addPromotionData?.source > 0) {
      getTemleteList(addPromotionData?.source)
    }

    getGroupLIstList()
  }, [selectedValue, defaultgroup, addPromotionData?.source])

  const handleGroupSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      submitGroupData()
    }
  }
  const submitGroupData = () => {
    // e.preventDefault();
    let formData = new FormData();
    formData.append('groupName', groupData.name);
    formData.append('groupDescription', groupData.description);
    formData.append('icon', groupData.fileIcon);
    formData.append('groupMembers', selectedOptions);
    formData.append('platform', "Whatsapp");
    formData.append('role', "CRMAdmin");



    postWithAuthCall(ApiConfig.CREATE_GROUP, formData)
      .then((res) => {
        console.log("res", res)
        if (res?.success === true) {
          ToastMsg("success", res.message);
          handleClose()
        } else {
          ToastMsg("error", res.message);
        }
      })
      .catch((err) => {
        console.log(err)
      })

  }

  const onSubmitPromotionData = (e) => {
    e.preventDefault()
    let formData = new FormData();
    formData.append('messageTitle', addPromotionData.title);
    formData.append('message', addPromotionData.description);
    formData.append('attachment', addPromotionData.attechment);
    formData.append('messageGroupId', selectedOptions1);
    formData.append('scheduledDate', addPromotionData.date);
    formData.append('scheduledTime', addPromotionData.time);
    formData.append('testNumber', Number(addPromotionData.testNumber));
    formData.append('sourceId', addPromotionData.source);
    formData.append('templateId', addPromotionData.template);
    // formData.append('attachmentUrl', addPromotionData.attechment);
    formData.append('isTestMessage', addPromotionData.isTestMessage);
    formData.append('url', addPromotionData.url);

    formData.append('platform', "SMS");

    if(id){
      formData.append('messageId', id)
    }

    console.log("promotion formData>>>>", formData)
    postWithAuthCall(ApiConfig.CREATE_PROMOTION, formData)
      .then((res) => {
        if (res?.success === true) {
          ToastMsg("success", res.message);
          handleClose()
          naviget("/WhatsappPromotion")
        } else {
          ToastMsg("error", res.message);
        }
      })
      .catch((res) => {
        console.log(res)
      })


  }


  const handleDefaultGroup = (item) => {
    console.log("DefaultGroup Data>>>>>", item)
    setSelectedValue(item)
  }
  const DefaultGroup = [
    { values: "Channel", label: "Channel" },
    { values: "Source", label: "Source" },
    { values: "time", label: "Time" },
    { values: "state", label: "State" },
    { values: "City", label: "City" },

  ]

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Whatsapp Promotion</h6>
            <button className="create-btn-sub-header" variant="primary"
              onClick={() => setShow(true)}
            >
              + Custom Group
            </button>
          </div>
          <div className="innerRoleSec">
            {
              loading?(
                <>
                <Loader/>
                </>
              ):(
<Form
              onSubmit={onSubmitPromotionData}
            >
              <div className="row">

                <div className="col-lg-6 col-md-12">

                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Title
                    </label>
                    <input
                      name="firsttitle"
                      placeholder="Enter Title"
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      required
                      value={addPromotionData?.title}
                      onChange={(e) => {
                        setAddPromotionData({
                          ...addPromotionData,
                          title: e.target.value
                        })
                      }}
                    />
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Message/Description
                    </label>
                    <input
                      name="message"
                      placeholder="Enter Message"
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      required
                      value={addPromotionData?.description}
                      onChange={(e) => {
                        setAddPromotionData({
                          ...addPromotionData,
                          description: e.target.value
                        })
                      }}
                    />
                  </div>

                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Select Source
                    </label>
                    <select
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      name="Source"
                      required
                      value={addPromotionData?.source}

                      onChange={(e) => {

                        setAddPromotionData({
                          ...addPromotionData,
                          source: e.target.value
                        })
                      }}


                    >
                      <option selected>Select Source</option>
                      {
                        sourceList?.map((item) => (
                          <option value={item?.id}>{item?.source}</option>
                        ))
                      }

                      {/* <option value="2">Source 2</option>
                    <option value="3">Source 3</option> */}
                    </select>
                  </div>
                  {
                    addPromotionData.source > 0 && (
                      <div className="modalProp mb-2">
                        <label
                          htmlFor=""
                          className="enter-role-text mt-2"
                          style={{ fontSize: "14px" }}
                        >
                          Select Templates
                        </label>
                        <select
                          class="form-select tasKCategory1 select-drop-icon"
                          aria-label="Default select example"
                          name="Source"
                          required
                          value={addPromotionData?.template}

                          onChange={(e) => {
                            setAddPromotionData({
                              ...addPromotionData,
                              template: e.target.value
                            })
                          }
                          }
                        >
                          <option selected>Select Templates</option>
                          {
                            templateList?.map((item) => (
                              <option value={item?.source_id}>{item?.template_name}</option>
                            ))
                          }

                        </select>
                      </div>
                    )
                  }

                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Attachment
                    </label>
                    <input
                      type="file"
                      id="choosefile"
                      required
                      className="form-control tasKCategory1"
                      name="attachement"
                      // value={addPromotionData?.attechment}
                      accept="image/png, image/jpeg" 
                      onChange={(e) => {
                        setAddPromotionData({
                          ...addPromotionData,
                          attechment: e.target.files[0],
                        })
                      }}
                    />
                    <label>
                      {
                        typeof addPromotionData?.attechment === "string"
                          ? addPromotionData?.attechment.substring(
                            addPromotionData?.attechment.length - 15,
                            addPromotionData?.attechment.length
                          )
                          : ""
                      }
                    </label>
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Time
                    </label>
                    <input
                      name="Time"
                      placeholder="Enter Time"
                      type="time"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      required
                      value={addPromotionData?.time}

                      onChange={(e) => {
                        setAddPromotionData({
                          ...addPromotionData,
                          time: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <div className="mt-4">
                    <input
                      type="checkbox"

                      checked={addPromotionData?.isTestMessage}
                      onChange={
                        (e) => {
                          setAddPromotionData({
                            ...addPromotionData,
                            isTestMessage: e.target.checked
                          })
                        }

                      }
                    />
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px", marginLeft: "5px" }}
                    >
                      Send To Test Number Only
                    </label>

                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="modalProp mb-2 mt-3">
                    <div className="d-flex justify-content-between  w-50 ">
                      <label
                        onClick={() => setDefaultgroup(!false)}
                        htmlFor=""
                        className="enter-role-text mt-2"
                        style={{
                          fontSize: "14px",
                          color:
                            defaultgroup === !false
                              ? "var(--blue_53B7E8_LightMode)"
                              : null,
                        }}
                      >
                        Default Group
                      </label>
                      <label
                        onClick={() => {
                          setDefaultgroup(false)
                          setSelectedValue("")
                        }
                        }
                        htmlFor=""
                        className="enter-role-text mt-2"
                        style={{
                          fontSize: "14px",
                          color:
                            defaultgroup === false
                              ? "var(--blue_53B7E8_LightMode)"
                              : null,
                        }}
                      >
                        Custom Group
                      </label>
                    </div>
                    {defaultgroup ? (

                      <div className="">
                        {DefaultGroup?.map((item) => (
                          <button
                            className="imporButton"
                            style={{
                              marginLeft: "4px",
                              backgroundColor: selectedValue === item.values ? "red" : "var(--blue_53B7E8_LightMode)", // Conditional color
                              color: selectedValue === item.values ? "white" : "black", // Text color change
                            }}
                            onClick={() => handleDefaultGroup(item.values)}
                          >
                            <Link className="details-link">{item.label}</Link>
                          </button>
                        ))}


                      </div>
                    ) : null}
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Select Group
                    </label>
                    <Select
                      class="form-select tasKCategory1 select-drop-icon"
                      options={groupList}
                      required
                      onChange={handleChangeGroup}
                      isMulti
                    />

                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Test Number
                    </label>
                    <input
                      name="TestNumber"
                      placeholder="Enter Test Number"
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      required
                      value={addPromotionData?.testNumber}

                      onChange={(e) => {
                        setAddPromotionData({
                          ...addPromotionData,
                          testNumber: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Date
                    </label>
                    <input
                      name="Date"
                      placeholder="Enter Date"
                      type="date"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      required
                      value={addPromotionData?.date}

                      onChange={(e) => {
                        setAddPromotionData({
                          ...addPromotionData,
                          date: e.target.value,

                        })
                      }}
                    />
                  </div >
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      URL
                    </label>
                    <input
                      name="Date"
                      placeholder="Enter URL"
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      required
                      value={addPromotionData?.url}

                      onChange={(e) => {
                        setAddPromotionData({
                          ...addPromotionData,
                          url: e.target.value,

                        })
                      }}
                    />
                  </div >

                </div>

              </div>
              <div className="d-flex justify-content-end align-items-center mainBtnsSub">
                {/* <button
                  type="button"
                  className="btn-width cancelBtn mx-3"
                  onClick={handleClose}
                >
                  Cancel
                </button> */}
                <button
                  className="btn-width saveBtn"
                  // disabled={btnLoading ? true : false}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
              )
            }
            
          </div>
        </div>
      </div>




      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header ">
          <Modal.Title id="example-modal-sizes-title-lg">Custom Group</Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Form noValidate
          validated={validated}
          onSubmit={handleGroupSubmit}
        >
          <Modal.Body>
            <div className="mainContentmodel">
              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6">
                  <label htmlFor="" className="mb-2" style={{ fontSize: "14px" }}>
                    Group Name
                  </label>
                  <input
                    type="test"
                    placeholder="Enter Title"
                    className="form-control tasKCategory1  mb-2"
                    required
                    onChange={(e) => {
                      setGropuData({
                        ...groupData,
                        name: e.target.value
                      })
                    }}
                  />
                  <div className="invalid-feedback"> Please enter Group Name</div>
                </div>
                <div className="col-lg-6">
                  <label htmlFor="" className="mb-2" style={{ fontSize: "14px" }}>
                    Group Description
                  </label>
                  <textarea
                    type="text"
                    placeholder="Enter Message"
                    className="form-control tasKCategory1  mb-2"
                    required
                    onChange={(e) => {
                      setGropuData({
                        ...groupData,
                        description: e.target.value
                      })
                    }}
                  />
                  <div className="invalid-feedback">Please enter Group Description</div>
                </div>
                <div className="col-lg-6 ">
                  <label htmlFor="" className="mb-2 " style={{ fontSize: "14px" }}>
                    Attachment
                  </label>
                  <input
                    type="file"
                    className="form-control tasKCategory1 mb-2"
                    required
                    accept="image/*"
                    onChange={(e) => {
                      setGropuData({
                        ...groupData,
                        fileIcon: e.target.files[0]
                      })
                    }}
                  />
                  <div className="invalid-feedback">
                    Please select Attachment File
                  </div>
                </div>
                <div className="col-lg-6 smallInpt">
                  <label htmlFor="" className="mb-2 " style={{ fontSize: "14px" }}>
                    Select Group Members
                  </label>
                  <Select
                    class="form-select tasKCategory1 select-drop-icon"
                    components={makeAnimated()}
                    options={userList}
                    required
                    onChange={handleChange}
                    isMulti
                  />
                  {/* {validated?<div className="invalid-feedback">Please select Attachment File</div>:""}  */}
                </div>

              </div>
            </div>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="btn-width saveBtn"
              // disabled={btnLoading ? true : false}
              type="submit"
            >
              Add
            </button>
          </div>
        </Form>
      </Modal>


    </>
  );
}

export default CustomGroup;
