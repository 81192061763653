import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import React, { useState, useContext, useEffect } from "react";
import "../../assets/css/tasklist.scss";
import { Button, Dropdown } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Check from "../../assets/images/check.svg";
import Priority from "../../assets/images/priority.svg";
import ic_excel from "../../assets/images/ic_excel.svg";
import ic_export1 from "../../assets/images/ic_export1.svg";
import searchicon from "../../assets/images/ic_search.svg";
import optionIcon from "../../assets/images/bx_edit.svg";
import DelectAcBlc from "../../assets/images/DelectAcBlc.svg";
import { useSearchParams } from "react-router-dom";
import DelectAc from "../../assets/images/DelectAc.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import ReactPaginate from "react-paginate";
import {
  DeleteCallWithErrorResponseWithToken,
  PostCallWithErrorResponse,
  PostCallWithErrorResponseReqBody,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import { ToastMsg } from "../TostMsg";
import { toast } from "react-toastify";
import disabled_edit from "../../assets/images/disabled_edit.svg";
import Pagination from "../../sharedComponent/Pagination";
import {
  daysCount,
  getDateDashYYYYMMDD,
  getDateddmmyyyy,
  getDDateYYYYMMDD,
  sendAsFormData,
} from "../../common";
import { notifyError } from "../../toaster/notify";
import NoData from "../../sharedComponent/NoData";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import { multipartPostCallWithErrorResponse } from "../../api/ApiServices";
import { ClipLoader } from "react-spinners";
import swal from "sweetalert";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";
import Item from "antd/es/list/Item";

const TaskList = () => {
  let token = localStorage.getItem("Token");

  const [excelLoading, setExcelLoading] = useState(false);

  const empId = localStorage.getItem("EmpId");
  const OrgId = localStorage.getItem("OrgId");

  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);

  // Delete Modal
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [useDeleteTaskList, setDeleteTaskList] = useState("");

  const [sortOrder, setSortOrder] = useState("asc");
  const handleSort = () => {
    const sortedData = [...taskList].sort((a, b) => {
      if (a.status < b.status) return sortOrder === "asc" ? -1 : 1;
      if (a.status > b.status) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setTaskList(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  //States
  const [fileSave, setFileSave] = useState(null);

  const [btnLoading, setBtnLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [projectNames, setProjectNames] = useState([]);
  const [Priority, setPriority] = useState([]);
  const [addTaskPayload, setAddTaskPayload] = useState({
    ProjectId: 0,
    TaskName: "",
    StartDate: "",
    EndDate: "",
    BudgetedHours: "",
    PriorityId: 0,
    EmpId: Number(userData.EmpId),
    OrgId: Number(userData.OrgId),
    TaskDescription: "",
  });

  const [params] = useSearchParams();
  //States

  useEffect(() => {
    let taskName = params.get("taskName");

    if (taskName) {
      handleShow();
      getProjectNameApi();

      setAddTaskPayload((prev) => ({
        ...prev,
        TaskDescription: taskName,
        StartDate: new Date().toISOString(),
      }));
    }
    getTaskList();
  }, []);

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.DOWNLOAD_EXCEL_FILE)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileSave(file);

    // Create a new FormData object
    const formData = new FormData();

    // Append the selected file to the FormData object
    formData.append("formFile", file);

    // Make a POST request to the server with the FormData
    fetch(ApiConfig.POST_EXCEL_FILE_FORMAT, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        // Handle the response from the server
        swal("Successfully uploaded excel file");
        setFileSave(null);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.log(error);
        swal("Something went wrong");
        setFileSave(null);
      });
  };

  useEffect(() => {
    console.log("fileSave ===>>>", fileSave);
  }, []);

  // TASK LIST API
  const getTaskList = () => {
    setLoading(true);
    let payLoad = {
      EmpId: Number(userData.EmpId),
      Orgid: Number(userData.OrgId),
    };
    PostCallWithErrorResponse(
      ApiConfig.GET_TASK_LIST,
      JSON.stringify(payLoad)
    ).then((res) => {
      setLoading(false);
      if (res.json.Data !== null) {
        setTaskList(res.json.Data);
      }
    });
  };

  const getTaskbyIDApi = (id) => {
    setLoading(true);
    PostCallWithErrorResponse(
      ApiConfig.GET_TASK_BY_ID,
      JSON.stringify({
        EmpId: Number(userData.EmpId),
        Orgid: Number(userData.OrgId),
        TaskId: id,
      })
    ).then((res) => {
      setLoading(false);
      let taskUpdate = res.json.Data[0];
      setAddTaskPayload({
        EmpId: Number(userData.EmpId),
        OrgId: Number(userData.OrgId),
        TaskId: taskUpdate.TasKId,
        ProjectId: taskUpdate.ProjectId,
        TaskName: taskUpdate.TaskName,
        StartDate: taskUpdate.StartDate,
        EndDate: taskUpdate.EndDate,
        BudgetedHours: taskUpdate.WorkedHours,
        PriorityId: JSON.parse(taskUpdate.PriorityId)
          ? JSON.parse(taskUpdate.PriorityId)
          : "",
        Approved: taskUpdate.AssignBy,
        TaskDescription: taskUpdate.TaskDescription,
      });
    });
  };

  // PROJECT NAMES API
  const getProjectNameApi = () => {
    let payLoadData = {
      EmpId: Number(userData.EmpId),
      OrgId: Number(userData.OrgId),
    };
    PostCallWithErrorResponse(
      ApiConfig.EMP_PROJECTS_NAMES,
      JSON.stringify(payLoadData)
    ).then((res) => {
      setProjectNames(res.json.Data);
    });
  };

  // Priority Master API
  const getPriorityApi = () => {
    let payLoadData = {
      id: Number(userData.EmpId),
      OrgId: Number(userData.OrgId),
    };
    PostCallWithErrorResponse(
      ApiConfig.PRIORITY_MASTER,
      JSON.stringify(payLoadData)
    ).then((res) => {
      setPriority(res.json.Data);
    });
  };

  // ADD TASK API
  const addOrUpdateTask = async (e) => {
    try {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
      } else if (
        new Date(addTaskPayload.StartDate) > new Date(addTaskPayload.EndDate)
      ) {
        notifyError("End Date Should be Greater Than Start Date");
      } else {
        setBtnLoading(true);
        const response = await PostCallWithErrorResponse(
          ApiConfig.ADD_TASK,
          JSON.stringify(addTaskPayload)
        );
        if (response.error) {
        } else if (response.json.Success === true) {
          ToastMsg("success", response.json.Message);
          setBtnLoading(false);
          handleClose();
          handleClose1();
          getTaskList();
        } else {
          ToastMsg("error", response.json.Message);
          setBtnLoading(false);
          console.log("error response,", response);
        }
      }
    } catch (error) {
      console.log("catch error,", error);
    } finally {
      setBtnLoading(false);
    }
  };
  // Ausaf code start

  const deleteWithId = (id) => {
    console.log("Task ID ---------", id);
    let deleteId = {
      TasKId: id,
    };
    setLoading(true);
    DeleteCallWithErrorResponseWithToken(
      ApiConfig.Delete_Task_By_Id + `${id}`
      // ApiConfig.GET_TASK_LIST,
      // JSON.stringify(deleteId)
    )
      .then((res) => {
        console.log(res.json, "??>>>");
        if (res.json.Success === true) {
          setLoading(false);
          ToastMsg("success", res.json.Message);
          getTaskList();
        } else {
          console.log("Error response", res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const deleteWithId = (id) => {
  //   let deleteId = {
  //     TasKId: id,
  //   };
  //   console.log("deleteId,", deleteId)
  //   PostCallWithErrorResponse(
  //     ApiConfig.DELETE_CLIENT_BY_ID,
  //     JSON.stringify(deleteId)
  //   ).then((res) => {
  //     if (res.json.Success == true) {
  //       ToastMsg("success", res.json.Message);
  //       getTaskList();
  //     }
  //   })
  //   .catch (err => {
  //     console.log("Catch error,", err)
  //   })
  // };
  // Ausaf code end

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    taskList &&
    taskList.length &&
    taskList.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const displayTaskList = taskList
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return a.TaskStatus.localeCompare(b.TaskStatus);
      } else {
        return b.TaskStatus.localeCompare(a.TaskStatus);
      }
    })
    .slice(startIndex, endIndex)
    .map((item, index) => {
      return (
        <tr className="table-row-custom">
          <td id="sr-no-padding">{startIndex + index + 1}</td>
          <td>{item.ProjectName}</td>
          <td>{item.AssignBy}</td>
          <td className="break-ln-spaces">
            {item.TaskName === null ? item.standardTaskName : item.TaskName}
          </td>
          <td className="break-ln-spaces">{item.TaskDescription}</td>
          <td>{item.StartDate && getDateddmmyyyy(item.StartDate)}</td>
          <td>{item.EndDate && getDateddmmyyyy(item.EndDate)}</td>
          <td
            className={
              item.TaskStatus === "P"
                ? "pending-tabel-data"
                : item.status === "A"
                  ? "Approve-tabel-data"
                  : item.status === "R"
                    ? "Rejected-tabel-data"
                    : ""
            }
          >
            {" "}
            {item.TaskStatus === "Pending" ? (
              <span className="colorP">Pending </span>
            ) : item.TaskStatus === "Approved" ? (
              <span className="colorA">Approved </span>
            ) : item.TaskStatus === "Rejected" ? (
              <span className="colorR"> Rejected</span>
            ) : item.TaskStatus === "Reallocated" ? (
              <span className="colorRe">Reallocated</span>
            ) : item.TaskStatus === "Allocated" ? (
              <span className="colorAl">Allocated</span>
            ) : item.TaskStatus === "Abort" ? (
              <span className="colorAb">Abort</span>
            ) : item.TaskStatus === "Deallocated" ? (
              <span className="colorDe">Deallocated</span>
            ) : item.TaskStatus === "Hold" ? (
              <span className="colorH">Hold</span>
            ) : item.TaskStatus === "Inprogress" ? (
              <span className="colorIn">Inprogress</span>
            ) : item.TaskStatus === "Completed" ? (
              <span className="colorC">Completed</span>
            ) : (
              ""
            )}
          </td>
          <td className={`${item.Priotiy === "High" ? "status-red" : item.Priotiy === "Medium" ? "status-label-grey" : "status-green"}`}>{item.Priotiy}</td>
          {
            permissions &&
            (permissions["Edit Task List"] || permissions["Delete Task List"]) &&


            <td>
              {
                item.TaskStatus === "Pending"
                  ? permissions &&
                  permissions["Edit Task List"] && (
                    <img
                      src={optionIcon}
                      onClick={() => {
                        setAddTaskPayload({
                          TaskId: 0,
                          ProjectId: 0,
                          TaskName: "",
                          StartDate: "",
                          EndDate: "",
                          BudgetedHours: 0,
                          PriorityId: 0,
                          EmpId: Number(userData.EmpId),
                          OrgId: Number(userData.OrgId),
                          TaskDescription: "",
                        });
                        getTaskbyIDApi(item.TasKId);
                        getProjectNameApi();
                        handleShow1();
                        setValidated(false);
                      }}
                      className="mx-2 pointer"
                      alt=""
                    />
                  )
                  : permissions &&
                  permissions["Edit Task List"] && (
                    <img src={disabled_edit} className="mx-2 pointer" alt="" />
                  )
              }

              {item.TaskStatus === "Pending"
                ? permissions &&
                permissions["Delete Task List"] && (
                  <img
                    src={DelectAcBlc}
                    onClick={() => {
                      handleShow3();
                      setDeleteTaskList(item.TasKId);
                    }}
                    //onClick={() => deleteWithId(item.TasKId)}
                    className="deleteBtn"
                    alt=""
                  ></img>
                )
                : permissions &&
                permissions["Delete Task List"] && (
                  <img
                    src={DelectAc}
                    // onClick={() => deleteWithId(item.TasKId)}
                    className="deleteBtn"
                    alt=""
                  ></img>
                )}
            </td>
          }
        </tr >
      );
    });

  const displaySearchedTaskList =
    taskList &&
    taskList
      ?.filter((item) =>
        item.ProjectName?.toLowerCase().includes(
          searchValue.toLocaleLowerCase()
        )
      )
      .slice(startIndex, endIndex)
      .sort((a, b) => a - b)
      .map((item, index) => {
        return (
          <tr className="table-row-custom">
            <td id="sr-no-padding">{startIndex + index + 1}</td>
            <td>{item.ProjectName}</td>
            <td>{item.AssignBy}</td>
            <td className="break-ln-spaces">{item.TaskName}</td>
            <td className="break-ln-spaces">{item.TaskDescription}</td>
            <td>{item?.StartDate && getDateddmmyyyy(item?.StartDate)}</td>
            <td>{item?.EndDate && getDateddmmyyyy(item?.EndDate)}</td>
            <td
              className={
                item.TaskStatus === "P"
                  ? "pending-tabel-data"
                  : item.status === "A"
                    ? "Approve-tabel-data"
                    : item.status === "R"
                      ? "Rejected-tabel-data"
                      : ""
              }
            >
              {" "}
              {item.TaskStatus === "Pending" ? (
                <span className="colorP">Pending </span>
              ) : item.TaskStatus === "Approved" ? (
                <span className="colorA">Approved </span>
              ) : item.TaskStatus === "Rejected" ? (
                <span className="colorR"> Rejected</span>
              ) : item.TaskStatus === "Reallocated" ? (
                <span className="colorRe">Reallocated</span>
              ) : item.TaskStatus === "Allocated" ? (
                <span className="colorAl">Allocated</span>
              ) : item.TaskStatus === "Abort" ? (
                <span className="colorAb">Abort</span>
              ) : item.TaskStatus === "Deallocated" ? (
                <span className="colorDe">Deallocated</span>
              ) : item.TaskStatus === "Hold" ? (
                <span className="colorH">Hold</span>
              ) : item.TaskStatus === "Inprogress" ? (
                <span className="colorIn">Inprogress</span>
              ) : item.TaskStatus === "Completed" ? (
                <span className="colorC">Completed</span>
              ) : (
                ""
              )}
            </td>
            <td className={`${item.Priotiy === "High" ? "status-red" : item.Priotiy === "Medium" ? "status-label-grey" : "status-green"}`}>{item.Priotiy}</td>
            {/* <td className="status-yellow">In Progress</td> */}
            {
              permissions &&
              (permissions["Edit Task List"] || permissions["Delete Task List"]) &&
              <td>
                {item.TaskStatus === "Pending"
                  ? permissions &&
                  permissions["Edit Task List"] && (
                    <img
                      src={optionIcon}
                      onClick={() => {
                        setValidated(false);
                        setAddTaskPayload({
                          TaskId: 0,
                          ProjectId: 0,
                          TaskName: "",
                          StartDate: "",
                          EndDate: "",
                          BudgetedHours: 0,
                          PriorityId: 0,
                          EmpId: Number(userData.EmpId),
                          OrgId: Number(userData.OrgId),
                          TaskDescription: "",
                        });
                        getTaskbyIDApi(item.TasKId);
                        getProjectNameApi();
                        handleShow1();
                      }}
                      className="mx-2 pointer"
                      alt=""
                    />
                  )
                  : permissions &&
                  permissions["Edit Task List"] && (
                    <img src={disabled_edit} className="mx-2 pointer" alt="" />
                  )}

                {item.TaskStatus === "Pending"
                  ? permissions &&
                  permissions["Delete Task List"] && (
                    <img
                      src={DelectAcBlc}
                      onClick={() => handleShow3()}
                      // onClick={() => deleteWithId(item.TasKId)}
                      className="deleteBtn"
                      alt=""
                    ></img>
                  )
                  : permissions &&
                  permissions["Delete Task List"] && (
                    <img
                      src={DelectAc}
                      // onClick={() => deleteWithId(item.TasKId)}
                      className="deleteBtn"
                      alt=""
                    ></img>
                  )}
              </td>
            }
          </tr>
        );
      });

  console.log(displaySearchedTaskList);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <div className="textHeader">
              <h6 className="mt-2">Task List</h6>
            </div>
            <div className="buttonsInsider">
              {permissions && permissions["Create Task List"] && (
                <button
                  className="create-btn-sub-header"
                  variant="primary"
                  onClick={() => {
                    getProjectNameApi();
                    handleShow();
                    setAddTaskPayload({
                      ProjectId: 0,
                      TaskName: "",
                      StartDate: "",
                      EndDate: "",
                      BudgetedHours: 0,
                      PriorityId: 0,
                      EmpId: Number(userData.EmpId),
                      OrgId: Number(userData.OrgId),
                      TaskDescription: "",
                    });
                    setValidated(false);
                  }}
                >
                  + Add Task{" "}
                </button>
              )}
              {/* <button
                className="create-btn-sub-header"
                variant="primary"
              >
               Import Excel File
              </button>
              <button
                className="create-btn-sub-header"
                variant="primary"
              >
               Export Excel File
              </button> */}
            </div>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search Project "
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>

              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25" selected>
                    25
                  </option>
                </select>
              </div>
            </div>
            {permissions && permissions["Task List Export"] && (
              <div className="import-export-btn-wrapper">
                <button className="booksIcon">
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={ic_excel}
                      onClick={(e) => downloadExcelFile(e)}
                      alt=""
                    />
                  )}
                </button>
                {permissions && permissions["Task List Bulk Upload"] && (
                  <label htmlFor="selectFile" className="file-input-btn">
                    <img src={ic_export1} alt="" />
                    <input
                      type="file"
                      accept=".xlsx"
                      onChange={handleFileChange}
                    // id="selectFile"
                    />
                  </label>
                )}
              </div>
            )}
          </div>
          {/* <div className='emptyPage'>
                        <img src={emptypage} alt="" />
                    </div> */}
          <div
            className="table-wrapper"
            id="scrol-tbl"
            style={{ marginBottom: "55px" }}
          >
            {loading ? (
              <div style={{ marginTop: "150px" }}>
                <Loader />
              </div>
            ) : taskList && taskList.length > 0 ? (
              <table>
                <tr className="firstTabelRow">
                  <th>Sr. no.</th>
                  <th>Project Name</th>
                  <th>Approved By</th>
                  <th>Task Name</th>
                  <th>Activity</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th onClick={handleSort} style={{ cursor: "pointer" }}>
                    Status {sortOrder === "asc" ? "▲" : "▼"}{" "}
                  </th>
                  <th>Priority</th>
                  {
                    permissions &&
                    (permissions["Edit Task List"] || permissions["Delete Task List"]) &&
                    <th>Action</th>
                  }
                </tr>
                {searchValue === "" ? displayTaskList : displaySearchedTaskList}
              </table>
            ) : (
              <>
                <NoData />
              </>
            )}
          </div>
        </div>
        {!loading && searchedDataPageCounts.length > usersPerPage && (
          <Pagination
            data={taskList}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={searchedDataPageCounts}
          />
        )}
      </div>

      {/* Add Modal Task */}
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header ">
          <Modal.Title id="example-modal-sizes-title-lg">Add Task</Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Form noValidate validated={validated} onSubmit={addOrUpdateTask}>
          <Modal.Body>
            <div className="mainContentmodel">
              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6">
                  <label htmlFor="" className="mb-2" style={{ fontSize: "14px" }}>
                    Project
                  </label>
                  <select
                    className="form-select tasKCategory1 select-drop-icon mb-2"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        ProjectId: Number(e.target.value),
                      });
                    }}
                    value={addTaskPayload.ProjectId}
                    required
                  >
                    <option value="">Select Project</option>
                    {projectNames &&
                      projectNames.map((item) => {
                        return (
                          <option value={item.ProjectId}>
                            {item.ProjectName}
                          </option>
                        );
                      })}
                  </select>
                  <div className="invalid-feedback">Please select project</div>
                </div>
                <div className="col-lg-6">
                  <label htmlFor="" className="mb-2" style={{ fontSize: "14px" }}>
                    Task
                  </label>
                  <textarea
                    rows="1"
                    type="text"
                    value={addTaskPayload.TaskName}
                    placeholder="Enter your activity here..."
                    className="form-control tasKCategory1  mb-2"
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        TaskName: e.target.value,
                      });
                    }}
                    maxlength={65}
                    required
                  />
                  <div className="invalid-feedback">Please enter task</div>
                </div>
                <div className="col-lg-3 smallInpt">
                  <label htmlFor="" className="mb-2 " style={{ fontSize: "14px" }}>
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="form-control tasKCategory1 mb-2"
                    value={addTaskPayload.StartDate.split("T")[0]}
                    min={new Date().toISOString().split("T")[0]}
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        StartDate: new Date(e.target.value)
                          .toISOString()
                          .toString(),
                        // BudgetedHours:
                        //   daysCount(
                        //     new Date(e.target.value),
                        //     addTaskPayload.EndDate
                        //   ) * 8,
                      });
                    }}
                    required
                  />
                  <div className="invalid-feedback">
                    Please select start date
                  </div>
                </div>
                <div className="col-lg-3 smallInpt">
                  <label htmlFor="" className="mb-2 " style={{ fontSize: "14px" }}>
                    End Date
                  </label>
                  <input
                    type="date"
                    className="form-control tasKCategory1 mb-2"
                    disabled={!addTaskPayload.StartDate}
                    min={addTaskPayload.StartDate.split("T")[0]}
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        EndDate: new Date(e.target.value)
                          .toISOString()
                          .toString(),
                        // BudgetedHours:
                        //   daysCount(
                        //     addTaskPayload.StartDate,
                        //     new Date(e.target.value)
                        //   ) * 8,
                      });
                    }}
                    // value={addTaskPayload.EndDate}
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>
                <div className="col-lg-3 smallInpt">
                  <label htmlFor="" className="mb-2" style={{ fontSize: "14px" }}>
                    Budget  Hours
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    // disabledColor
                    // disabled
                    value={
                      addTaskPayload.BudgetedHours
                        ? addTaskPayload.BudgetedHours
                        : ""
                    }
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        BudgetedHours: Number(e.target.value),
                      });
                    }}
                    required
                  />
                  <div className="invalid-feedback">
                    Please enter bugdet hours
                  </div>
                </div>
                <div className="col-lg-3 mb-1">
                  <label htmlFor="" className="mb-2" style={{ fontSize: "14px" }}>
                    Priority
                  </label>
                  <select
                    className="form-select tasKCategory1 select-drop-icon mb-2"
                    style={{ height: "35px" }}
                    aria-label="Default Select Priority"
                    placeholder="Select Priority"
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        PriorityId: Number(e.target.value),
                      });
                    }}
                    required
                    name="PriorityId"
                    value={addTaskPayload.PriorityId}
                  >
                    <option value="">Please select</option>
                    <option value="1">High</option>
                    <option value="2">Medium</option>
                    <option value="3">Low</option>
                  </select>
                  <div className="invalid-feedback">Please select priority</div>
                </div>
                <div className="col-lg-6">
                  <label htmlFor="" className="mb-2 " style={{ fontSize: "14px" }}>
                    Activity
                  </label>
                  <textarea
                    className="form-control tasKCategory1"
                    style={{ border: "1px solid #53b7e8", height: "auto" }}
                    rows="4"
                    placeholder="Enter Activity..."
                    value={addTaskPayload.TaskDescription}
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        TaskDescription: e.target.value,
                      });
                    }}
                    required
                  />
                  <div className="invalid-feedback">Please enter Activity</div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="btn-width saveBtn"
              disabled={btnLoading ? true : false}
            >
              {btnLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <ClipLoader color="#fff" size={18} className="me-2" />
                </div>
              ) : (
                "Add"
              )}
            </button>
          </div>
        </Form>
      </Modal>

      {/* Delete popup Announcement */}
      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Announcement
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              deleteWithId(useDeleteTaskList);
              handleClose3();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
      {/* Delete popup Announcement */}
      {/* Edit Modal Task */}
      <Modal
        size="xl"
        show={show1}
        onHide={handleClose1}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header ">
          <Modal.Title id="example-modal-sizes-title-lg">Edit Task</Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow1(false)} />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Form noValidate validated={validated} onSubmit={addOrUpdateTask}>
          <Modal.Body>
            <div className="mainContentmodel">
              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6">
                  <label htmlFor="" className="mb-2 ">
                    Project
                  </label>
                  <select
                    className="form-select tasKCategory1 mb-2"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        ProjectId: Number(e.target.value),
                      });
                    }}
                    value={addTaskPayload.ProjectId}
                    required
                  >
                    <option value="">Select Project</option>
                    {projectNames &&
                      projectNames.map((item) => {
                        return (
                          // ProjectName
                          <option value={item.ProjectId}>
                            {item.ProjectName}
                          </option>
                        );
                      })}
                  </select>
                  <div className="invalid-feedback">Please select project</div>
                </div>
                <div className="col-lg-6">
                  <label htmlFor="" className="mb-2" >
                    Task
                  </label>
                  <input
                    rows="1"
                    type="text"
                    placeholder="Enter your activity here..."
                    className="form-control tasKCategory1 mb-2"
                    value={addTaskPayload.TaskName}
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        TaskName: e.target.value,
                      });
                    }}
                    maxlength={65}
                    required
                  />
                  <div className="invalid-feedback">Please enter task</div>
                </div>
                <div className="col-lg-3 smallInpt">
                  <label htmlFor="" className="mb-2 ">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="form-control tasKCategory1 mb-2"
                    value={addTaskPayload.StartDate}
                    min={new Date().toISOString().split("T")[0]}
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        StartDate: e.target.value,
                        BudgetedHours:
                          daysCount(
                            new Date(e.target.value),
                            addTaskPayload.EndDate
                          ) * 8,
                      });
                    }}
                    required
                  />
                  <div className="invalid-feedback">
                    Please select start data
                  </div>
                </div>
                <div className="col-lg-3 smallInpt">
                  <label htmlFor="" className="mb-2 ">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="form-control tasKCategory1 mb-2"
                    value={addTaskPayload.EndDate}
                    min={addTaskPayload.StartDate.split("T")[0]}
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        EndDate: e.target.value,
                        BudgetedHours:
                          daysCount(
                            addTaskPayload.StartDate,
                            new Date(e.target.value)
                          ) * 8,
                      });
                    }}
                    required
                  />
                  <div className="invalid-feedback">Please select end data</div>
                </div>
                <div className="col-lg-3 smallInpt">
                  <label htmlFor="" className="mb-2 ">
                    Budget Hours
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    value={addTaskPayload.BudgetedHours}
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        BudgetedHours: Number(e.target.value),
                      });
                    }}
                    required
                  />
                  <div className="invalid-feedback">
                    Please enter bugdet hours
                  </div>
                </div>
                <div className="col-lg-3 mb-1">
                  <label htmlFor="" className="mb-2 ">
                    Priority
                  </label>
                  <select
                    className="form-select tasKCategory1"
                    aria-label="Default Select Priority"
                    placeholder="Select Priority"
                    value={addTaskPayload.PriorityId}
                    name="PriorityId"
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        PriorityId: Number(e.target.value),
                      });
                    }}
                  // required
                  >
                    <option>Please select</option>
                    <option value="1">High</option>
                    <option value="2">Medium</option>
                    <option value="3">Low</option>
                  </select>
                </div>
                <div className="col-lg-6">
                  <label htmlFor="" className="mb-2">
                    Activity
                  </label>
                  <textarea
                    className="form-control tasKCategory1"
                    style={{ border: "1px solid #53b7e8", height: "auto" }}
                    rows="4"
                    placeholder="Enter Description..."
                    value={addTaskPayload.TaskDescription}
                    onChange={(e) => {
                      setAddTaskPayload({
                        ...addTaskPayload,
                        TaskDescription: e.target.value,
                      });
                    }}
                    required
                  />
                  <div className="invalid-feedback">Please enter Activity</div>
                </div>
              </div>
            </div>
          </Modal.Body>
          {/* <div className="lineSeperate"></div>
        <Modal.Footer>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button className="btn-width cancelBtn mx-4" onClick={handleClose1}>
              Cancel
            </button>
            <button className="btn-width saveBtn">Update</button>
          </div>
        </Modal.Footer> */}

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={handleClose1}
            >
              Cancel
            </button>
            <button
              className="btn-width saveBtn"
              disabled={btnLoading ? true : false}
            >
              {btnLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <ClipLoader color="#fff" size={18} className="me-2" />
                </div>
              ) : (
                "Update"
              )}
            </button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default TaskList;
