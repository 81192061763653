import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/Role.scss";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import editicon from "../../assets/images/bx_edit.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import close from "../../assets/images/close.svg";
import deleteicon from "../../assets/images/delete.svg";
import Modal from "react-bootstrap/Modal";
import { AppContext } from "../../context/AppContext";
import dropicon from "../../assets/images/circle_drop.svg";
import Pagination from "../../../src/sharedComponent/Pagination";
import { useNavigate } from "react-router-dom";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import CommonButton from "../ChatAdmin/button";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import { filter } from "lodash";

const Quotation = () => {
  const navigate = useNavigate();

  const inititialState = {
    "Id": 0,
    "Name": "",
    //"SubmissionDate": "",
    //"ApprovalDate": "",
    "ConsultantId": "",
    "IsGSTApplicable": false,
    "GSTRate": "",
    "IsTDSApplicable": false,
    "TDSSection": "",
    "TDSPercentage": "",
    "TDSAmount": "",
    "AddSubscriptions": [],
    "ModifySubscriptions": [

    ],
    "RemoveSubscriptions": [

    ]
  }

  const { sidebar, userData } = useContext(AppContext);

  const [show, setShow] = useState(false);
  const [priviwe, setpriviwe] = useState(false);

  const [consutants, setConsultants] = useState([])
  const [quotatio, setQuotation] = useState(inititialState)
  const [isValided, setIsValided] = useState(false)
  const [isApiLoad, setIsApiLoad] = useState(false)
  const [subscriptionId, setSubscriptionId] = useState('')
  const [subscriptionList, setSubscriptionList] = useState([])
  const [serviceGroups, setServiceGroups] = useState([])
  const [clients, setClients] = useState([])
  const [branches, setBranch] = useState([])
  const [services, setServices] = useState([])
  const [groupId, setGroupId] = useState('')
  const [serviceId, setServiceId] = useState('')

  const [quotationList, setQuatationList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [perPageData, setPerPageData] = useState(25)
  const [currentPage, setCurrentPage] = useState(0)
  const startIndex = currentPage * perPageData;
  const endIndex = startIndex + parseInt(perPageData);

  const [showChangeStatus, setShowChangeStatus] = useState(false)

  const [updateStatus, setUpdateStatus] = useState({
    id: '',
    quoteRevisionStatusId: ''
  })


  const getQuatationList = () => {

    setIsLoading(true)

    simpleGetCallWithErrorResponse(ApiConfig.GET_QUOTATIONS).then((res) => {

      if (res?.json?.success) {

        setQuatationList(res?.json?.data)

      }

      setIsLoading(false)

    })

  }

  useEffect(() => {

    getQuatationList()

  }, [])


  const handleChange = (key, val) => {

    setQuotation(prev => ({
      ...prev,
      [key]: val
    }))

  }


  const handleSubChange = (key, val, index) => {
    console.log(val)

    setQuotation((prev) => {
      const updatedSubscriptions = [...prev.AddSubscriptions];
      updatedSubscriptions[index] = {
        ...updatedSubscriptions[index],
        [key]: val,
      };
      return {
        ...prev,
        AddSubscriptions: updatedSubscriptions,
      };
    });

    console.log(quotatio);

  }





  const getconsultants = () => {

    if (consutants.length) return

    simpleGetCallWithErrorResponse(ApiConfig.GET_CONSULTANTS).then((res) => {

      if (res?.json?.Success) {

        setConsultants(res?.json?.Data)

      }

    })

  }


  const priveclose = () => setpriviwe(false);

  const handleShow = () => setShow(true);


  const getClient = () => {

    if (clients.length > 0) return

    simpleGetCallWithErrorResponse(ApiConfig.GET_CLIENTS_WORKFLOW).then((res) => {

      if (res?.json?.success) {

        setClients(res?.json?.data)

      }

    })

  }

  const handleShowCraetQuataion = () => {


    getServiceGroupList()
    getconsultants()

    // getSubscriptionList()
    getClient()

    handleShow()

  }

  const handleSubmit = () => {

    let isValid = true;


    if (!quotatio?.Name || !quotatio?.ConsultantId) {
      isValid = false
      setIsValided(true)
    }

    if (quotatio?.IsGSTApplicable && !quotatio?.GSTRate) {
      isValid = false
      setIsValided(true)
    }

    if (quotatio?.IsTDSApplicable && (!quotatio?.TDSSection || !quotatio.TDSAmount || !quotatio.TDSPercentage)) {
      isValid = false

      setIsValided(true)
    }


    if (!quotatio?.AddSubscriptions.length) {
      isValid = false

      ToastMsg('error', 'Please Add Atleast One Project')
    }

    quotatio.AddSubscriptions.forEach((subs) => {

      if (
        !subs?.BranchId ||
        !subs?.ClientId ||
        !subs?.Amount2 ||
        !subs?.Amount3 ||
        !subs?.Amount4 ||
        !subs?.AdvanceAmount
      ) {

        isValid = false

        setIsValided(true)

      }

    })



    if (isValid) {

      setIsApiLoad(true)

      const url = quotatio?.Id == 0 ? ApiConfig.CREATE_QUOTAION : ApiConfig.UPDATE_QUOTATION

      let body = { ...quotatio }

      if (quotatio?.Id != 0) {

        let modify = quotatio?.AddSubscriptions?.filter((subs) => subs.Id != 0)

        let newSubs = quotatio?.AddSubscriptions?.filter((subs) => subs.Id == 0)

        body.ModifySubscriptions = modify

        body.AddSubscriptions = newSubs

      }



      PostCallWithErrorResponse(url, JSON.stringify(body)).then((res) => {

        if (res?.json?.success) {

          ToastMsg('success', res?.json?.message)


          return res?.json?.data

        } else {
          ToastMsg('error', res?.json?.message)

          setIsApiLoad(false)


        }


      }).then((res) => {



        if (quotatio?.Id == 0) {


          return PostCallWithErrorResponse(ApiConfig.SEND_QUOTATION_MAIL, JSON.stringify(res?.quotationEmail))

        } else {
          setIsApiLoad(false)


          clerarFormData()

          getQuatationList()
        }


      }).then((res) => {

        if (res?.json?.status) {

          ToastMsg('success', res?.json?.message)



        } else {
          ToastMsg('error', res?.json?.message)

        }

        setIsApiLoad(false)


        clerarFormData()

        getQuatationList()

      })

    }

  }

  const clerarFormData = () => {

    setQuotation(inititialState)

    setShow(false)

    setIsValided(false)

    setServiceId('')

    setGroupId('')

    setServices([])

    setSubscriptionList([])

  }


  const getSubscriptionList = (id) => {


    simpleGetCallWithErrorResponse(ApiConfig.GET_SUBSCRIPTION_BY_SERVICE_ID + id).then((res) => {

      if (res?.json?.success) {
        setSubscriptionList(res?.json?.data)
      }

    })

  }

  const getServicesByGroup = (id) => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_SERVICES_BY_GROUP_ID + id).then((res) => {


      setServices(res?.json?.data)

    })


  }

  const addSubscriptiom = (id) => {

    setSubscriptionId('')


    let isExist = quotatio?.AddSubscriptions?.findIndex((i) => i?.subscriptionsMasterId == id)

    if (isExist != -1) {

      ToastMsg('error', 'Subscription Already Added')

      return

    }


    simpleGetCallWithErrorResponse(ApiConfig.GET_SINGLE_SUBSCRIPTION + id).then((res) => {

      if (res?.json?.success) {

        let data = res?.json?.data

        let subs = [...quotatio?.AddSubscriptions, {
          "Id": 0,
          "ServiceId": data?.serviceId,
          ServiceName: data?.serviceName,
          ClientName: data?.clientName,
          BranchName: data?.branchName,
          ClientId: quotatio?.AddSubscriptions.length > 0 ? quotatio?.AddSubscriptions[0]?.ClientId : data?.clientId,
          "BranchId": data?.branchId,
          "FinancialYearId": data?.financialYearId,
          PeriodLables: data?.periods,
          "Periods": data?.periods?.map((i) => i?.id),
          "Amount1": data?.amount1,
          "Amount2": data?.amount2,
          "Amount3": data?.amount3,
          "Amount4": data?.amount4,
          "AdvanceAmount": data?.advanceAmount,
          "Name": data?.name,
          subscriptionsMasterId: Number(id)
        }]

        ToastMsg('success', res?.json?.message)

        setQuotation(prev => ({
          ...prev,
          AddSubscriptions: subs
        }))
      } else {
        ToastMsg('error', res?.json?.message)
      }


    })



  }

  const removeSubscrip = (index, id) => {



    let subs = quotatio.AddSubscriptions.filter((_, idx) => idx != index)

    let removeSube = []

    if (id != 0) {
      removeSube = [...quotatio.RemoveSubscriptions, { id: Number(id) }]
    }
    setQuotation(prev => ({
      ...prev,
      AddSubscriptions: subs,
      RemoveSubscriptions: removeSube
    }))

  }

  const getServiceGroupList = () => {

    if (serviceGroups?.length > 0) return

    simpleGetCallWithErrorResponse(ApiConfig.GET_SERVICE_LIST).then((res) => {

      if (res?.json?.success) {

        setServiceGroups(res?.json?.data)

      }

    })

  }



  const getQuotationDetails = (id) => {

    handleShowCraetQuataion()

    simpleGetCallWithErrorResponse(ApiConfig.GET_SINGLE_QUOTATION + id).then((res) => {

      if (res?.json?.success) {

        let data = res?.json?.data


        setQuotation({
          "Id": data?.id,
          "Name": data?.name,
          ClientId: '',
          //"SubmissionDate": "",
          //"ApprovalDate": "",
          "ConsultantId": data?.consultantId,
          "IsGSTApplicable": data?.isGSTApplicable,
          "GSTRate": data?.gstRate,
          "IsTDSApplicable": data?.isTDSApplicable,
          "TDSSection": data?.tdsSection,
          "TDSPercentage": data?.tdsPercentage,
          "TDSAmount": data?.tdsAmount,
          "AddSubscriptions": data?.subscriptions?.map((data) => {
            //getPeriods(data?.serviceId)
            return {
              "Id": data?.id,
              "ServiceId": data?.serviceId,
              ServiceName: data?.serviceName,
              ClientName: data?.clientName,
              BranchName: data?.branchName,
              "BranchId": data?.branchId,
              ClientId: data?.clientId,
              "FinancialYearId": data?.financialYearId,
              PeriodLables: data?.periods,
              "Periods": data?.periods?.map((i) => i?.id),
              "Amount1": data?.amount1,
              "Amount2": data?.amount2,
              "Amount3": data?.amount3,
              "Amount4": data?.amount4,
              "AdvanceAmount": data?.advanceAmount,
              "Name": data?.serviceName,
              subscriptionsMasterId: data?.subscriptionsMasterId
            }
          }),
          "ModifySubscriptions": [

          ],
          "RemoveSubscriptions": [

          ]
        }
        );

        getServiceGroupList()
        //getSubscriptionList(data?.consultantId)
        getBranches(data?.subscriptions[0].clientId)



      }

    })

  }

  const getBranches = (id) => {

    simpleGetCallWithErrorResponse(ApiConfig.GET_BRANCH_BY_CLIENT + id + '/branches').then((res) => {

      if (res?.json?.success) {

        setBranch(res?.json?.data)

      }

    })

  }




  const quotationUpdateStatuus = () => {

    if (!updateStatus.quoteRevisionStatusId) {
      ToastMsg('error', 'please select status')
      return
    }

    PostCallWithErrorResponse(ApiConfig.UPDATE_QUATATION_STATUS, JSON.stringify(updateStatus)).then((res) => {

      if (res?.json?.success) {
        ToastMsg('success', res?.json?.message)
        getQuatationList()
        setShowChangeStatus(false)
        setUpdateStatus({
          id: '',
          quoteRevisionStatusId: ''
        })
      } else {

        ToastMsg('error', res?.json?.message)

      }

    })

  }


  const handlePriviwe = () => setpriviwe(true);
  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div

      >
        <div className="role-content">
          <div className="sub-header-role">
            <h6 className="mt-2 role-text">Quotation</h6>
            <button className="create-btn-sub-header" onClick={handleShowCraetQuataion}>
              Create
            </button>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns responsivetopbar resposiveheadder">
            <div className="role-table-header firstdiv reponsivesrc">
              <div className="search-input-wrapper">
                <input type="text" placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)} />
                <img src={searchicon} alt=""
                />
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  class="form-select tasKCategory1 remove-border"
                  aria-label="Default select example"
                  value={perPageData}
                  onChange={(e) => setPerPageData(e.target.value)}
                >
                  <option selected value={'25'}>25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div className="role-btns-wrapper">
              <button>
                <img src={importicon} alt="" />
              </button>
              <button>
                {" "}
                <img src={exporticon} alt="" />
              </button>
            </div>
          </div>
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            {
              isLoading ? <Loader /> :
                quotationList?.length == 0 ? <NoData /> :

                  <table>
                    <tr>
                      <th>Sr. no</th>
                      <th>Quotation Name</th>
                      <th>Consultant Name</th>
                      <th>Create Date</th>
                      <th>Submit Date</th>
                      <th>Quotation Status</th>
                      <th>Actions</th>
                    </tr>
                    {
                      quotationList.filter((quot) => {
                        return search.length ? quot?.name?.toLowerCase().includes(search.toLowerCase().trim()) :
                          quot
                      }).slice(startIndex, endIndex).map((quot, index) => {
                        return <tr className="table-row-custom" key={index}>
                          <td>{startIndex + index + 1}</td>
                          <td>{quot?.name}</td>
                          <td>{quot?.consultantName}</td>
                          <td>{quot?.createdDate}</td>
                          <td>{quot?.createdDate}</td>
                          <td className={`Qutationtabelselect textdanger`}
                            onClick={() => {
                              setShowChangeStatus(true)
                              setUpdateStatus(prev => ({
                                quoteRevisionStatusId: quot?.quoteRevisionStatusId,
                                id: quot?.id
                              }))
                            }}
                          >
                            <span className={
                              `
                              ${quot?.quoteRevisionStatus === 'Reject' && 'text-danger'}
                              ${quot?.quoteRevisionStatus === 'Accept' && 'colorA'}
                              ${!quot?.quoteRevisionStatus && 'colorC'}
                              ${quot?.quoteRevisionStatus === 'InProgress' && 'colorP'}
                              `
                            }>
                              {quot?.quoteRevisionStatus ? quot?.quoteRevisionStatus : quot?.status}
                            </span>

                          </td>
                          <td>
                            <img
                              src={editicon}
                              alt=""
                              onClick={() => getQuotationDetails(quot?.id)}
                              className="me-3"
                            />
                            <img src={eye_icon} alt="" onClick={handlePriviwe} />
                          </td>
                        </tr>
                      })
                    }

                  </table>
            }
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12">
            <Pagination
              data={quotationList}
              pageChangeHandler={(page) => setCurrentPage(page.selected)}
              usersPerPage={perPageData}
              currentPage={currentPage}
              searchValue={search}
            //searchedDataPageCounts={searchedDataPageCounts}
            />
          </div>
        </div>
      </div>

      {/* <<<<<<...................modal create starts here..........................>>>> */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        className="create-workflow-main modal-lg"
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              {quotatio?.Id == 0 ? "Add" : "Update"} Quotation
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={clerarFormData} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>

          <div
            style={{ maxHeight: '580px', overflowX: 'scroll', scrollbarWidth: 'none' }}>
            <div className="row create-workflow-topbody pb-4">
              <div className="col-lg-6 mt-4">
                <label htmlFor="" className="text-grey">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control tasKCategory1"
                  placeholder="Name"
                  value={quotatio.Name}
                  onChange={(e) => handleChange('Name', e.target.value)}
                />
                <p className="text-danger">{isValided && !quotatio?.Name && 'Please Enter Subscription Name'}</p>
              </div>
              <div className="col-lg-6 mt-4">
                <label htmlFor="" className="text-grey">
                  Consultant Name
                </label>
                <select
                  class="form-select tasKCategory1"
                  aria-label="Default select example"

                  value={quotatio.ConsultantId}
                  onChange={(e) => {
                    // getSubscriptionList(e.target.value)
                    handleChange('ConsultantId', Number(e.target.value))

                  }}
                >
                  <option selected value={''} disabled>Select Consultant</option>
                  {
                    consutants?.map((consultant, index) => {
                      return <option value={consultant.Id}>
                        {consultant.FirstName + ' ' + consultant?.LastName}
                      </option>
                    })
                  }
                </select>
                <p className="text-danger">{isValided && !quotatio?.ConsultantId && 'Please Select Consultant'}</p>
              </div>
            </div>
            <div className="row create-workflow-topbody pb-4">
              <div className="col-lg-4  mt-4 d-flex align-items-center">
                <label htmlFor="" className="text-grey me-5">
                  Is GST Applicable ?
                </label>
                <input type="checkbox"
                  checked={quotatio.IsGSTApplicable}
                  onChange={(e) => handleChange('IsGSTApplicable', e.target.checked)}
                />
              </div>
              <div className="col-lg-4  mt-4 d-flex align-items-center">
                <label htmlFor="" className="text-grey me-5">
                  Is TDS Applicable ?
                </label>
                <input type="checkbox"

                  checked={quotatio.IsTDSApplicable}
                  onChange={(e) => handleChange('IsTDSApplicable', e.target.checked)} />
              </div>
              {
                quotatio.IsGSTApplicable &&

                <div className="col-lg-4 position-relative mt-4">
                  <label htmlFor="" className="text-grey">
                    GST Rate
                  </label>
                  <input type="number" className="form-control tasKCategory1"
                    value={quotatio.GSTRate}
                    onChange={(e) => handleChange('GSTRate', e.target.value)}
                  />
                  <p className="text-danger">{isValided && !quotatio?.GSTRate && 'Please Select Tds Section'}</p>
                </div>
              }
            </div>
            {
              quotatio.IsTDSApplicable && <>
                <div className="row create-workflow-topbody pb-4 ">
                  <div className="col-lg-4">
                    <label htmlFor="" className="text-grey">
                      TDS Section
                    </label>
                    <select
                      class="form-select tasKCategory1"
                      aria-label="Default select example"
                      value={quotatio?.TDSSection}
                      onChange={(e) => handleChange('TDSSection', e.target.value)}
                    >
                      <option selected value={''} disabled>Select TDS</option>
                      <option value="TDS Return Salary">TDS Return Salary</option>
                      <option value="Other than Salary">Other than Salary</option>
                      <option value="Non Resident">Non Resident</option>
                      <option value="TCS Return">TCS Return</option>
                    </select>
                    <p className="text-danger">{isValided && !quotatio?.TDSSection && 'Please Select Tds Section'}</p>
                  </div>
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="text-grey">
                      TDS Amount
                    </label>
                    <input type="number" className="form-control tasKCategory1"

                      value={quotatio?.TDSAmount}
                      onChange={(e) => handleChange('TDSAmount', e.target.value)} />
                    <p className="text-danger">{isValided && !quotatio?.TDSAmount && 'Please Enter Subscription Name'}</p>
                  </div>
                  <div className="col-lg-4 ">
                    <label htmlFor="" className="text-grey">
                      TDS Percentage
                    </label>
                    <input type="number" className="form-control tasKCategory1"
                      value={quotatio?.TDSPercentage}
                      onChange={(e) => handleChange('TDSPercentage', e.target.value)}
                    />
                    <p className="text-danger">{isValided && !quotatio?.TDSPercentage && 'Please Enter Subscription Name'}</p>
                  </div>
                </div>
              </>
            }
            <Modal.Header>
              <Modal.Title>
                <label htmlFor="" className="create-role-text">
                  Subscriptions
                </label>
              </Modal.Title>

            </Modal.Header>
            <div className="hr-line-modal-1">
              <hr style={{ margin: "0" }} />
            </div>
            {/* <div className="col-lg-3 ml-6 text-end mt-3">
              <button
                className="modal-create-btn mb-2"
              // onClick={addSubscriptiom}
              >
                Add Subscription
              </button>
            </div> */}

            <div className="row create-workflow-topbody pb-4 ">

              <div className="col-lg-3 mb-3 mt-3 ml-3">
                <select
                  class="form-select tasKCategory1"
                  aria-label="Default select example"
                  value={groupId}
                  onChange={(e) => {

                    getServicesByGroup(e.target.value)

                    setGroupId(e.target.value)



                  }}
                >
                  <option selected value={''} disabled>Select Service Group</option>
                  {
                    serviceGroups?.map((group, index) => {
                      return <option value={group?.id}>{group?.name}</option>
                    })
                  }
                </select>
              </div>
              <div className="col-lg-3 mb-3 mt-3 ml-3">
                <select
                  class="form-select tasKCategory1"
                  aria-label="Default select example"
                  value={serviceId}
                  onChange={(e) => {

                    setServiceId(e.target.value)

                    getSubscriptionList(e.target.value)


                  }}
                >
                  <option selected value={''} disabled>Select Service</option>
                  {
                    services?.map((service, index) => {
                      return <option value={service.id} key={index}>
                        {service.name}

                      </option>
                    })
                  }
                </select>
              </div>
              <div className="col-lg-3 mb-3 mt-3 ml-3">
                <select
                  class="form-select tasKCategory1"
                  aria-label="Default select example"
                  value={subscriptionId}
                  onChange={(e) => {

                    setSubscriptionId(e.target.value)

                    addSubscriptiom(e.target.value)



                  }}
                >
                  <option selected value={''} disabled>Select Subscription</option>
                  {
                    subscriptionList?.map((subscription, index) => {
                      return <option value={subscription.id} key={index}>
                        {subscription.name}

                      </option>
                    })
                  }
                </select>
              </div>

            </div>

            {
              quotatio?.AddSubscriptions?.map((subs, index) => {
                return <>


                  <div className="mainFormSection box-shadow mb-3 ml-4 mr-4 ">
                    <div className=" mb-1 d-flex justify-content-between">
                      <h6 className="create-role-text">Subscription {index + 1}</h6>
                      <img src={close}
                        onClick={() => removeSubscrip(index, subs?.Id)}
                      />
                    </div>
                    <div className="hr-line-modal-1">
                      <hr style={{ margin: "0" }} />
                    </div>
                    <div className="row p-2 mt-3">
                      <div className="col-lg-6 d-flex ">
                        <p className="text-secondary">Subscription Name :</p>
                        <p className="text-dark">{subs?.Name}</p>
                      </div>
                      <div className="col-lg-6 d-flex ">
                        <p className="text-secondary">Service Name :</p>
                        <p className="text-dark">{subs?.ServiceName}</p>
                      </div>
                      <div className="col-lg-4 d-flex mt-3">
                        <p className="text-secondary">Client Name :</p>
                        <p className="text-dark">{subs?.ClientName}</p>
                      </div>
                      <div className="col-lg-4 d-flex mt-3">
                        <p className="text-secondary">Period's :</p>
                        <div className="text-dark">{
                          subs?.PeriodLables?.map((period) => {
                            return <span>{period?.name},</span>
                          })
                        }</div>
                      </div>
                      <div className="col-lg-4 d-flex mt-3">
                        <p className="text-secondary">Chart of Fees ₹ :</p>
                        <p className="text-dark">{subs?.Amount1}</p>
                      </div>
                      <div className="col-lg-6  mt-3">
                        <select
                          class="form-select tasKCategory1"
                          aria-label="Default select example"
                          value={subs?.ClientId}
                          onChange={(e) => {

                            quotatio?.AddSubscriptions?.forEach((i, index) => {

                              handleSubChange('ClientId', e.target.value, index)
                              handleSubChange('BranchId', '', index)
                            })


                            getBranches(e.target.value)



                          }}
                          disabled={index != 0}
                        >
                          <option selected value={''} disabled>Select Client</option>
                          {
                            clients?.map((client, index) => {
                              return <option value={client.id} key={index}>
                                {client.firstName}

                              </option>
                            })
                          }
                        </select>
                        <p className="text-danger">{isValided && !subs?.ClientId && 'Please Select Client'}</p>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <select
                          class="form-select tasKCategory1"
                          aria-label="Default select example"
                          value={subs?.BranchId}
                          onChange={(e) => {

                            handleSubChange('BranchId', Number(e.target.value), index)





                          }}
                        >
                          <option selected value={''} disabled>Select Branch</option>
                          {
                            branches?.map((branch, index) => {
                              return <option value={branch.id} key={index}>
                                {branch.branchName}

                              </option>
                            })
                          }
                        </select>

                        <p className="text-danger">{isValided && !subs?.BranchId && 'Please Select Branch Id'}</p>
                      </div>
                      <div className="col-lg-4 mt-3">
                        <label htmlFor="" className="text-grey">
                          Latest Avialiable Invoice ₹
                        </label>
                        <input type="number" className="form-control tasKCategory1"
                          value={subs?.Amount2}
                          onChange={(e) => handleSubChange('Amount2', e.target.value, index)} />

                        <p className="text-danger">{isValided && !subs?.Amount2 && 'Please Enter Latest Avaiable Invoice'}</p>
                      </div>
                      <div className="col-lg-4 mt-3">
                        <label htmlFor="" className="text-grey">
                          Latest Avialiable Quotation ₹
                        </label>
                        <input type="number" className="form-control tasKCategory1"
                          value={subs?.Amount3}
                          onChange={(e) => handleSubChange('Amount3', e.target.value, index)} />

                        <p className="text-danger">{isValided && !subs?.Amount3 && 'Please Enter Latest Available Quotation'}</p>
                      </div>
                      <div className="col-lg-4 mt-3">
                        <label htmlFor="" className="text-grey">
                          Negotiated Amount ₹
                        </label>
                        <input type="number" className="form-control tasKCategory1"
                          value={subs?.Amount4}
                          onChange={(e) => handleSubChange('Amount4', e.target.value, index)} />

                        <p className="text-danger">{isValided && !subs?.Amount4 && 'Please Enter Negotiated Amount'}</p>
                      </div>
                      <div className="col-lg-4 mt-3">
                        <label htmlFor="" className="text-grey">
                          Advance Amount
                        </label>
                        <input type="number" className="form-control tasKCategory1"
                          value={subs?.AdvanceAmount}
                          onChange={(e) => handleSubChange('AdvanceAmount', e.target.value, index)} />

                        <p className="text-danger">{isValided && !subs?.AdvanceAmount && 'Please Enter Advance Amount'}</p>
                      </div>

                    </div>
                  </div>
                </>
              })
            }




          </div >
        </Modal.Body>


        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn" onClick={clerarFormData}>
              Close
            </button>
            <CommonButton
              isLoading={isApiLoad}
              title={quotatio?.Id == 0 ? 'Submit' : 'Update'}
              handlerFunction={handleSubmit}
            />
          </div>
        </Modal.Footer>
      </Modal>




      {/* <<<<<<...................modal view starts here..........................>>>> */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={priviwe}
        className="create-workflow-main modal-lg "
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
          <img src={close} alt="" onClick={priveclose} />
        </Modal.Header>

        <div>
          <div className="row pb-3">
            <div className="d-flex justify-content-center">
              <div className="col-lg-10 qutation-card ">
                <div className="text-center">
                  <p style={{ fontSize: "25px", fontWeight: "600" }}>
                    Quotation
                  </p>
                  <p>Company Name (Pin Code)</p>
                </div>

                <div className="quote-headings mt-5">
                  <p>To,</p>
                  <p>Robert Jr</p>
                  <p>Date :10-1-2022</p>
                  <p>Subject : XYZ</p>
                </div>
                <div className="mt-4">
                  <p>
                    we are pleased to quote fees as below for the service
                    require by you
                  </p>
                </div>
                <div className="quote-table table-bordered">
                  <table>
                    <tr>
                      <th>Sr. Number</th>
                      <th>Description Of Services</th>
                      <th>Amount (Rs)</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>
                        <p>Three Period Service</p>
                        <p>1. Four Period Service(From Service Description)</p>
                      </td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className="text-end">Gross Amount</td>
                      <td>500</td>
                    </tr>
                  </table>
                </div>
                <div className="mt-4">
                  <p>50% of fee needs to pay in Advance.</p>
                  <p>
                    If you require further information,please feel free to
                    contact us
                  </p>
                </div>
                <div className="mt-4">
                  <h5>THANKING YOU AND ASSURING YOU OF OUR BEST SERVICES!</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      < Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showChangeStatus}
        className="create-workflow-main"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-workflow-text">
              Update Status
            </label>
          </Modal.Title>
          <img src={close} alt=""
            onClick={() => setShowChangeStatus(false)}
          />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body className="">



          <div >
            <label htmlFor="" className="text-grey">
              Quotation Status
            </label>
            <select
              class="form-select tasKCategory1"
              aria-label="Default select example"
              value={updateStatus?.quoteRevisionStatusId}
              onChange={(e) => {
                setUpdateStatus(prev => ({
                  ...prev,
                  quoteRevisionStatusId: Number(e.target.value)
                }))
              }}
            >
              <option selected disabled>Select Quotation Status</option>
              <option value={'1'}>InProgress</option>
              <option value={'2'}>Accept</option>
              <option value={'3'}>Reject</option>
            </select>
          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn"
              onClick={() => setShowChangeStatus(false)}
            >Cancel</button>

            <CommonButton
              title='Update'
              isLoading={false}
              handlerFunction={quotationUpdateStatuus}
            />
          </div>
        </Modal.Footer>
      </Modal >
    </div >
  );
};

export default Quotation;
