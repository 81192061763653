import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import { Link } from "react-router-dom";
import redbytes from "../../assets/images/custom-rb-logo.png";
import Modal from "react-bootstrap/Modal";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";
import edit from "../../assets/images/edit_officeCaller.svg";
import eye from "../../assets/images/ic_show_eyes_officecaller.svg";
import plus from "../../assets/images/plus_officecaller.svg";
import deleteicon from "../../assets/images/delete_icon_gray.svg";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Form from "react-bootstrap/Form";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import ReactPaginate from "react-paginate";

const Supplier = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [showPersonal, setShowPersonal] = useState(false);
  const [showPersonaledit, setShowPersonaledit] = useState(false);
  const [category, setCategory] = useState([]);
  const [domain, setDomain] = useState([]);

  const handleUpdate = () => {
    // Handle the update logic here
    console.log("Updated Category:", category);
    console.log("Updated Domain:", domain);
    // Close the modal after updating
    setShowPersonal(false);
  };
  // show button
  const [selectedRows, setSelectedRows] = useState(10);
      const [usersPerPage, setUsersPerPage] = useState(25);
        const [dataList, setDataList] = useState([])
        const [loading, setLoading] = useState(false)
      const [currentPage, setCurrentPage] = useState(0);
        const [searchValue, setSearchValue] = useState("");
          const [totalPages, setTotalPages] = useState(0);
        
      

    const pageChangeHandler = ({ selected }) => {
      setCurrentPage(selected);
    };

    

    const getAgentData = () => {
      setLoading(true)
      simpleGetCallWithToken(ApiConfig.CONSULTANT_AND_SUPPLIER+`?page_size=${usersPerPage}&page=${currentPage}&type=Supplier&search=${searchValue}`)
        .then((response) => {
          console.log(response)
          setDataList(response)
          setTotalPages(Math.ceil(parseInt(response.total) / usersPerPage));    
                setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    }

    useEffect(() => {
      getAgentData()
    }, [searchValue,usersPerPage,currentPage])

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <div className="textHeader">
            <h6 className="mt-2"> Supplier</h6>
          </div>
          {/* <div className="buttonsInsider">
            <button
              className="create-btn-sub-header"
              variant="primary"
              onClick={() => setShowPersonal(true)}
            >
              + Add Module
            </button>
            <button
                className="create-btn-sub-header"
                variant="primary"
              >
               Import Excel File
              </button>
              <button
                className="create-btn-sub-header"
                variant="primary"
              >
               Export Excel File
              </button>
          </div> */}
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
          <div className="role-table-header">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search "
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setUsersPerPage(e.target.value);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25" selected>25</option>
              </select>
            </div>
          </div>

          <div className="import-export-btn-wrapper mt-4">
            <div className="booksIcon">
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>

        <div id="crm-call-form">
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table>
              <thead>
                <tr className="firstTabelRow">
                  <th scope="col"> Sr.No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Service</th>
                  <th scope="col">Nationality</th>
                  {/* <th scope="col">Action</th> */}
                </tr>
              </thead>
              <tbody>
               {loading ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                dataList?.data?.map((item, index) => (
                  <tr key={index} className="table-row-custom">
                    <td>{index + 1}</td>
                    <td>{item.first_name}</td>
                    <td> {item.email}</td>
                    <td>{item.phone_number}</td>
                    <td> {item.specialization}</td>
                    <td> {item.nationality}</td>
                    {/* <td>
                      <div>
                        <img
                          // onClick={() => setShowPersonaledit(true)}
                          src={edit}
                          alt="Image 1"
                          className="cell-image"
                        />

                        <Link>
                          <img
                            src={eye}
                            alt="Image 2"
                            className="cell-image ml-1"
                          />
                        </Link>

                        <Link>
                          <img
                            src={deleteicon}
                            alt="Image 2"
                            className="cell-image ml-1"
                          />
                        </Link>
                      </div>
                    </td> */}
                  </tr>
                ))
              )
              }
              </tbody>
            </table>
          </div>
          {/* <Pagination
          data={dataList?.data}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={dataList?.data}
          /> */}
            <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={totalPages}
                          onPageChange={pageChangeHandler}
                          containerClassName={"paginationBttns"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                          forcePage={currentPage} 
                        />
        </div>
      </div>

      <Modal
        size="xl"
        show={showPersonal}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header">
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Module
          </Modal.Title>
          <div className="rightAxe" onClick={() => setShowPersonal(false)}>
            <img src={Axe} alt="" />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Form noValidate>
          <Modal.Body>
            <div className="mainContentmodel">
              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6 smallInpt">
                  <label htmlFor="" className="mb-2 text-grey">
                    Module *
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>

                <div className="col-lg-6 mb-1">
                  <label htmlFor="" className="mb-2 text-grey">
                    Icon *
                  </label>
                  <input
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    required
                  />
                </div>
              </div>
            </div>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={() => setShowPersonal(false)}
            >
              Cancel
            </button>
            <button className="btn-width saveBtn">Update</button>
          </div>
        </Form>
      </Modal>
      <Modal
        size="xl"
        show={showPersonaledit}
        aria-labelledby="modal-90w"
        className="add-task-modal-main"
        centered
      >
        <Modal.Header className="add-task-modal-header">
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Module
          </Modal.Title>
          <div className="rightAxe" onClick={() => setShowPersonaledit(false)}>
            <img src={Axe} alt="" />
          </div>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Form noValidate>
          <Modal.Body>
            <div className="mainContentmodel">
              <div className="row add-task-modal-inputs" id="popup-form-af">
                <div className="col-lg-6 smallInpt">
                  <label htmlFor="category" className="mb-2 text-grey">
                    Module *
                  </label>
                  <input
                    id="category"
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    // value={category}
                    // onChange={(e) => setCategory(e.target.value)}
                    required
                  />
                  <div className="invalid-feedback">Please select end date</div>
                </div>

                <div className="col-lg-6 mb-1">
                  <label htmlFor="domain" className="mb-2 text-grey">
                    Icon *
                  </label>
                  <input
                    id="category"
                    type="text"
                    className="form-control tasKCategory1 mb-2"
                    // value={category}
                    // onChange={(e) => setCategory(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
          </Modal.Body>

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              type="button"
              className="btn-width cancelBtn mx-3"
              onClick={() => setShowPersonaledit(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn-width saveBtn"
              onClick={handleUpdate}
            >
              Update
            </button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Supplier;
