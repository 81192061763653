import BarChart from "../../component/Barchart.js";
import Pagination from "../../sharedComponent/Pagination.js";
import React, { useContext, useEffect, useState } from "react";
import ic_excel from "../../assets/images/ic_excel.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import phone from "../../assets/images/phone.svg";
import phonetwentyfour from "../../assets/images/phonetwentyfour.svg";
import profileblue from "../../assets/images/profileblue.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Form, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { simpleGetCall, simpleGetCallWithToken } from "../../api/ApiServices.js";
import ApiConfig from "../../api/ApiConfig.js";

const Phonebookdash = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  const [deshboardData, setDeshboaedData] = useState([])
  const [chartdData, setchartData] = useState([])
  const [international, setInternational] = useState();
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterSerchName,setFilterSerchName] = useState([])

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);
  const [key, setKey] = useState("all");
  console.log("Key data >>>>>>>>>>", key);

  useEffect(()=>{
    getDeshboardData(international)
    console.log("Deshboard Data>>", international)

  },[international])

  
  // Filter the data when international, searchValue, or deshboardData changes
  useEffect(() => {
    if (deshboardData) {
      const dataToFilter = international
        ? deshboardData?.location_int_list // If international, use location_int_list
        : deshboardData?.location_list; // Otherwise, use location_list

      const filteredData = dataToFilter?.filter((item) =>
        item.loc_name.toLowerCase().includes(searchValue.toLowerCase())
      );

      console.log("Filtered Data>>", filteredData);
      setFilterSerchName(filteredData); // Set the filtered data in the state
    }
  }, [deshboardData, searchValue, international]);
  const handleInternationalData=()=>{
      setInternational(true); 
  }
  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  // const searchedDataPageCounts =
  //   deshboardData.location_list?.length &&
  //   deshboardData.location_int_list &&
  //   deshboardData.location_list.filter &&
  //   deshboardData.location_int_list .filter((item) =>
  //     item.loc_name?.toLowerCase().includes(searchValue)
  //   );

  const getDeshboardData = (data) => {  
    let url;
    if (data) {
        url = ApiConfig.INTERNATIONAL_DESHBOARD;  
    } else {
        url = ApiConfig.CALL_DESHBOARD; 
    }
    
    simpleGetCallWithToken(url) 
      .then((res) => {
        console.log("getFetchDeshboardData>>>>>", res);
        setDeshboaedData(res); 
      })
      .catch((err) => {
        console.log("Get Deshboard Error>>>", err);  
      });
};
  const getCahartdData = () => {
    simpleGetCallWithToken(ApiConfig.GET_WEEK_CALL)
      .then((res) => {
        console.log("getFetchDeshboardData>>>>>", res)
        setchartData(res)
      })
      .catch((err) => {
        console.log("Get Deshboard Error>>>", err)
      })

  }

  useEffect(() => {
    
    getCahartdData()
    
  }, [])




  const dataArray = [
    {
      id: 1,
      name: "Call",
      Count: "23232",
      img: phone,
    },
    { id: 2, name: "Users", Count: "23232", img: profileblue },
    { id: 3, name: "Candidates", Count: "23232", img: profileblue },
    { id: 4, name: "Agents", Count: "23232", img: profileblue },
    {
      id: 5,
      name: "Not Call Classification",
      Count: "23232",

      img: phone,
    },
    { id: 6, name: "Zero Call List", Count: "23232", img: phone },

    { id: 7, name: "Services Request", Count: "23232", img: phonetwentyfour },
  ];

  const dataArrayLocation = [
    { id: 1, name: "Maharashtra", Count: "23232" },
    { id: 2, name: "Karnataka", Count: "23232" },
    { id: 3, name: "Gujarat", Count: "23232" },
    { id: 1, name: "Maharashtra", Count: "23232" },
    { id: 2, name: "Karnataka", Count: "23232" },
    { id: 3, name: "Gujarat", Count: "23232" },
  ];
  const dataMap = new Map(dataArray.map((item) => [item.id, item]));

  return (
    <>
      {/* <div className="phonebook-dash-container">
        <div className="phonebook-dash-row">
          {Array.from(dataMap.values()).map((item) => (
            <div key={item.id} className="phonebook-dash-item">
              <div className="row align-items-center">
                <div className="col-6  align-items-center ">
                  <img src={item.img} />
                </div>
                <div className="col-6 align-items-center">
                  <p className="item-title">{item.name}</p>
                  <p className="item-count">{item.Count}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div className="phonebook-dash-container">
        <div className="phonebook-dash-row">
          {/* {Array.from(dataMap.values()).map((item) => ( */}
          <div className="phonebook-dash-item">
            <div className="row align-items-center">
              <div className="col-6  align-items-center ">
                <img src={phone} />
              </div>
              <div className="col-6 align-items-center">
                <p className="item-title">Call</p>
                <p className="item-count">{deshboardData?.distress_call_count}</p>
              </div>
            </div>
          </div>

          <div className="phonebook-dash-item">
            <div className="row align-items-center">
              <div className="col-6  align-items-center ">
                <img src={profileblue} />
              </div>
              <div className="col-6 align-items-center">
                <p className="item-title">Users</p>
                <p className="item-count">{deshboardData?.user_count}</p>
              </div>
            </div>
          </div>
          <div className="phonebook-dash-item">
            <div className="row align-items-center">
              <div className="col-6  align-items-center ">
                <img src={profileblue} />
              </div>
              <div className="col-6 align-items-center">
                <p className="item-title">Candidates</p>
                <p className="item-count">{deshboardData?.candidate_count}</p>
              </div>
            </div>
          </div>
          <div className="phonebook-dash-item">
            <div className="row align-items-center">
              <div className="col-6  align-items-center ">
                <img src={profileblue} />
              </div>
              <div className="col-6 align-items-center">
                <p className="item-title">Agents</p>
                <p className="item-count">{deshboardData?.agent_count}</p>
              </div>
            </div>
          </div>
          <div className="phonebook-dash-item">
            <div className="row align-items-center">
              <div className="col-6  align-items-center ">
                <img src={phone} />
              </div>
              <div className="col-6 align-items-center">
                <p className="item-title">Not Call Classification</p>
                <p className="item-count">{deshboardData?.call_classification}</p>
              </div>
            </div>
          </div>
          <div className="phonebook-dash-item">
            <div className="row align-items-center">
              <div className="col-6  align-items-center ">
                <img src={phone} />
              </div>
              <div className="col-6 align-items-center">
                <p className="item-title">Zero Call List</p>
                <p className="item-count">{deshboardData?.zero_call_list}</p>
              </div>
            </div>
          </div>
          <div className="phonebook-dash-item">
            <div className="row align-items-center">
              <div className="col-6  align-items-center ">
                <img src={phonetwentyfour} />
              </div>
              <div className="col-6 align-items-center">
                <p className="item-title">Services Request</p>
                <p className="item-count">{deshboardData?.service_request_count}</p>
              </div>
            </div>
          </div>
          {/* ))} */}
        </div>
      </div>
      <div className="WeeklyMain">
        <div className="rowstyle">
          <div>
            <p className="summarybld"> Weekly call summary bar chart</p>
          </div>
          {/* <select
            className="form_bgnd option"
          // onChange={(e) => setService(e.target.value)}
          >
            <option>Last 7 Days</option>
            <option>Weekly</option>
            <option>monthly</option>
          </select> */}
        </div>

        <BarChart data={chartdData} />
      </div>

      <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
        <div className="role-table-header">
          <div className="search-input-wrapper">
            <input
              type="text"
              placeholder="Search "
            onChange={(e) => {
              setSearchValue(e.target.value);
              setCurrentPage(0);
            }}
            />
            {Dark === "lightMode" ? (
              <img src={searchicon} alt="" />
            ) : (
              <img src={ic_search_dark} alt="" />
            )}
          </div>
          <div className="entries-wrapper">
            <p>Entries per page</p>
            <select
              className="form-select select-drop-icon"
              aria-label="Default select example"
            onChange={(e) => {
              setUsersPerPage(e.target.value);
              setCurrentPage(0);
            }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25" selected>25</option>
            </select>
          </div>
        </div>

        <div className="import-export-btn-wrapper mt-4">
          <button>
            {/* {excelLoading ? (
                  <ExcelLoader />
                ) : ( */}
            <img
              src={ic_excel}
              // onClick={(e) => downloadExcelFile(e)}
              alt=""
            />
            {/* )} */}
          </button>
        </div>
      </div>

      <div className="role-content">
        <Form noValidate>
          <div id="tabsBelow" className="mt-4 ">
            <div className="registerbutton mb-3">
              <div></div>
              <div>
                <label className="sml-btn-sub-header p-1  " >
                  <Link to="#" className="details-link " >
                    Domestic call Count: {deshboardData?.Domestic_count}
                  </Link>
                </label>
                <label className="sml-btn-sub-header resdom p-1" >
                  <Link to="#" className="details-link"   >
                    International call Count: {deshboardData?.International_count}
                  </Link>
                </label>
              </div>
            </div>
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              // className="mb-0"
              activeKey={key}
              onSelect={(k) => {
                setKey(k);  
                if (k === 'inboundCalls') {
                  setInternational(true); 
                  setSearchValue("") 
                } else {
                  setInternational(false);  
                  setSearchValue("") 

                }
              }}
            >
              <Tab eventKey="all" title="DOMESTIC">
                <div  className="table-wrapper"  id="scrol-tblNtable-wrapper-af">
                <table id="tabledash">
                  <thead >
                    <tr className="col-5 firstTabelRow">
                      <th>Location</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    //  deshboardData?.location_list &&deshboardData?.location_list
                    deshboardData?.location_list && filterSerchName?.slice(startIndex, endIndex).map((item, index) => (
                      <tr key={index}>
                        <td>{item.loc_name}</td>
                        <td>{item.loc_count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
             
                <div className="mt-5 lefttg">
                <Pagination
              data={deshboardData?.location_list} 
              pageChangeHandler={pageChangeHandler}
              usersPerPage={usersPerPage}
              currentPage={currentPage}
              searchValue={searchValue}
              searchedDataPageCounts={filterSerchName}
            />                </div>
              </Tab>

              <Tab eventKey="inboundCalls" title="INTERNATIONAL" >
              <div  className="table-wrapper"  id="scrol-tblNtable-wrapper-af">

                <table id="tabledash">
                  <thead>
                    <tr className="col-5 firstTabelRow" >
                      <th>Location</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                  {international? deshboardData?.location_int_list &&filterSerchName?.slice(startIndex, endIndex).map((item, index) => (
                      <tr key={index}>
                        <td>{item.loc_name}</td>
                        <td>{item.loc_count}</td>
                      </tr>
                    )):""}
                  </tbody>
                </table>
                </div>
                <div className="mt-5 lefttg">
                  <Pagination 
                   data={!international?deshboardData?.location_list:deshboardData?.location_int_list}
                   pageChangeHandler={pageChangeHandler}
                   usersPerPage={usersPerPage}
 
                   currentPage={currentPage}
                   searchValue={searchValue}
                   searchedDataPageCounts={filterSerchName}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Phonebookdash;
