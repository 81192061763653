import React, { useContext, useState } from "react";
import "../../assets/css/Role.scss";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import exporticon from "../../assets/images/export.svg";
import editicon from "../../assets/images/bx_edit.svg";
import eye_icon from "../../assets/images/eye_icon.svg";
import close from "../../assets/images/close.svg";
import deleteicon from "../../assets/images/delete.svg";
import Modal from "react-bootstrap/Modal";
import { AppContext } from "../../context/AppContext";
import dropicon from "../../assets/images/circle_drop.svg";
import Pagination from "../../../src/sharedComponent/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import { ToastMsg } from "../TostMsg";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";

const ShiftManagment = () => {

  const shift ={
    "Id": 0,
   // "ShiftName": "",
    "InTime": "",
    "OutTime": "",
    "GraceTime": "",
    "BreakTime": "",
    "EntryDate": "2024-12-28T07:08:19.691Z",
    "EntryBy": 2636,
    "OrgId": 1,
    "BranchId": "",
    "Location": "",
    "Latitude": "",
    "Longitude": "",
    "Timezone": "",
    "BreakStartTime": "01:30PM",
    "BreakEndTime": "02;30PM",
   "startIndex": 5,
   "count": 5,
    "sorting": "test"
  }

  const {branchId} = useParams()

  const {userData} = useState(AppContext)

  const [state,setState] = useState(shift)

  state.BranchId=Number(branchId);
  //state.OrgId=Number(userData?.OrgId)

  const [isValided,setIsValided] = useState(false)

  const handleChange=(key,val)=>{


    setState(prev=>({
      ...prev,
      [key]:val
    }))

  }

  const { sidebar } = useContext(AppContext);
  const [stepDrop, setStepDrop] = useState(false);
  const [stepDrop1, setStepDrop1] = useState(false);
  const [stepDrop2, setStepDrop2] = useState(false);

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [priviwe, setpriviwe] = useState(false);

  const handleClose = () => setShow(false);
  const handleClosed = () => setEdit(false);
  const priveclose = () => setpriviwe(false);

  const handleShow = () => setShow(true);
  const handleEdit = () => setEdit(true);

  const handlePriviwe = () => setpriviwe(true);
  const navigate = useNavigate();

  const validLatLong=(latLong)=>{
    let latLongRegex = /^-?\d+(\.\d{1,30})$/;
    return latLongRegex.test(latLong);
  }

  const handleSubmit=()=>{

    let isValid = true


    const keys = Object.keys(state)
  console.log(keys);
    
  keys.forEach((key)=>{

    
    if(key!="Id"&&!state[key]){


      console.log(key)

      isValid=false;

      setIsValided(true)

      

    }

    if((key==='Latitude'||key==='Longitude')&&!validLatLong(state[key])){
       isValid=false;
       setIsValided(true)
    }

  })


  if(isValid){

    const url = state.Id==0?ApiConfig.CREATE_SHIFT:ApiConfig.UPDATE_SHIFT

    PostCallWithErrorResponse(url,JSON.stringify(state)).then((res)=>{

      if(res?.json?.success){

        ToastMsg('success',res?.json?.message)
      //  handleClose()
       // getBankList()

      }else{

        ToastMsg('error',res?.json?.message)
      }

    })

  }

  }

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"}  id="cx-main">
      <div
       
      >
        <div className="role-content">
          <div className="sub-header-role userList33">
            {/* <h6 className="mt-2 role-text">Shift Management</h6> */}
            <h6 className="mt-2 role-text">
              <span
                className="me-1 bread-cumb-color"
                onClick={() => navigate("/clientlist")}
              >
                Client{" "}
              </span>
              <span className="me-1 back-slash"> /</span>
              <span
                className="me-1 bread-cumb-color"
                onClick={() => navigate("/entitieslist")}
              >
                Entities
              </span>
              <span className="me-1 back-slash"> /</span>
              <span
                className="me-1  bread-cumb-color"
                onClick={() => navigate("/branch_list")}
              >
                {" "}
                Branch
              </span>
              <span className="me-1 back-slash"> /</span>
              Shift Management
            </h6>
            <button className="create-btn-sub-header" onClick={handleShow}>
              Create
            </button>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns responsivetopbar resposiveheadder">
            <div className="role-table-header firstdiv reponsivesrc">
              <div className="search-input-wrapper">
                <input type="text" placeholder="Search" />
                <img src={searchicon} alt="" />
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  class="form-select select-drop-icon remove-border"
                  aria-label="Default select example"
                >
                  <option selected>10</option>
                  <option value="1">20</option>
                  <option value="2">10</option>
                  <option value="3">5</option>
                </select>
              </div>
            </div>
            <div className="role-btns-wrapper">
              <button>
                <img src={importicon} alt="" />
              </button>
              <button>
                {" "}
                <img src={exporticon} alt="" />
              </button>
            </div>
          </div>
          <div className="table-wrapper">
            <table>
              <tr>
                <th>Sr. no</th>
                <th>Branch Name</th>
                <th>Location</th>
                <th>IN Time</th>
                <th>Out Time</th>
                <th>Break Time</th>
                <th>Grace Time</th>
                <th>Actions</th>
              </tr>
              <tr className="table-row-custom">
                <td>1</td>
                <td>Redbytes ( HO )</td>
                <td>Pune</td>
                <td>10:00 am</td>
                <td>09:00 pm</td>
                <td>30 min</td>
                <td>15 min</td>
                <td>
                  <img src={editicon} alt="" onClick={handleEdit} />
                </td>
              </tr>
              <tr className="table-row-custom">
                <td>1</td>
                <td>Probytes</td>
                <td>Aurangabad</td>
                <td>09:00 am</td>
                <td>06:00 pm</td>
                <td>30 min</td>
                <td>15 min</td>
                <td>
                  <img src={editicon} alt="" onClick={handleEdit} />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12">
            <Pagination />
          </div>
        </div>
      </div>

      {/* <<<<<<...................modal create starts here..........................>>>> */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        className="create-workflow-main modal-lg"
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Create Shift
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClose} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="row create-workflow-topbody pb-4">
            {/* <div className="col-lg-6 mt-4">
              <label htmlFor="" className="label-text">
                Branch Name
              </label>
              <select
                class="form-select select-drop-icon"
                aria-label="Default select example"
                value={state.B}
              >
                <option selected>Select</option>
                <option value="Redbytes">Redbytes</option>
                <option value="Probytes">Probytes</option>
              </select>
            </div> */}
            <div className="col-lg-6 mt-4">
              <label htmlFor="" className="label-text">
                Time Zone
              </label>
              <select
                class="form-select select-drop-icon"
                aria-label="Default select example"
                value={state.Timezone}
                onChange={(e)=>handleChange("Timezone",e.target.value)}
              >
                <option selected></option>
                <option value="1">UTC +01:19</option>
                <option value="2">UTC +00:12</option>
              </select>
              <p className="text-danger">{isValided&&!state.Timezone.length&&'Please Select Timezone'}</p>
            </div>
          </div>
          <div className="row create-workflow-topbody pb-4">
            <div className="col-lg-4  mt-4">
              <label htmlFor="" className="label-text">
                Location
              </label>
              <input
                type="text"
                className="date-label-input"
                placeholder="Pune"
                value={state.Location}
                onChange={(e)=>handleChange("Location",e.target.value)}
              />
              <p className="text-danger">{isValided&&!state.Location.length&&'Please Enter location '}</p>
            </div>
            <div className="col-lg-4 mt-4">
              <label htmlFor="" className="label-text">
                Latitude
              </label>
              <input type="number" className="date-label-input"
                value={state.Latitude}
                onChange={(e)=>handleChange("Latitude",Number(e.target.value))} />
                <p className="text-danger">{isValided&&!state.Latitude&&'Please Enter Latitude '}</p>
            </div>
            <div className="col-lg-4 position-relative mt-4">
              <label htmlFor="" className="label-text">
                Longitude
              </label>
              <input type="number" className="date-label-input"
                value={state.Longitude}
                onChange={(e)=>handleChange("Longitude",Number(e.target.value))} />
                <p className="text-danger">{isValided&&!state.Longitude&&'Please Enter Longitude '}</p>
            </div>
          </div>
          <div className="row create-workflow-topbody pb-4 ">
            <div className="col-lg-6">
              <label htmlFor="" className="label-text">
                In Time
              </label>
              <input
                type="time"
                className="date-label-input"
                placeholder="10:00 am"
                value={state.InTime}
                onChange={(e)=>handleChange("InTime",e.target.value)}
              />
              <p className="text-danger">{isValided&&!state.InTime&&'Please Enter In-Time '}</p>
            </div>
            <div className="col-lg-6 ">
              <label htmlFor="" className="label-text">
                Out Time
              </label>
              <input
                type="time"
                className="date-label-input"
                placeholder="07:00 pm"
                value={state.OutTime}
                onChange={(e)=>handleChange("OutTime",e.target.value)}
              />
              <p className="text-danger">{isValided&&!state.OutTime&&'Please Enter Out Time '}</p>
            </div>
          </div>
          <div className="row create-workflow-topbody pb-4 ">
            <div className="col-lg-6 mb-4">
              <label
                htmlFor=""
                className="label-text me-3 d-flex align-items-center  p2-2"
              >
                Break Time
              </label>
              <input
                type="text"
                className="date-label-input"

                value={state.BreakTime}
                onChange={(e)=>handleChange("BreakTime",Number(e.target.value))}
              />
              <p className="text-danger">{isValided&&!state.BreakTime&&'Please Enter BreakTime '}</p>
            </div>
            <div className="col-lg-6">
              <label
                htmlFor=""
                className="label-text me-3 d-flex align-items-center"

              >
                Grace Time
              </label>
              <input
                type="number"
                className="date-label-input"
                placeholder="07:00 pm"

                value={state.GraceTime}
                onChange={(e)=>handleChange("GraceTime",Number(e.target.value))}
              />
              <p className="text-danger">{isValided&&!state.GraceTime&&'Please Enter GraceTime '}</p>
            </div>
          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="modal-create-btn "
            onClick={handleSubmit}
            >Save</button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* <<<<<<...................modal edit starts here..........................>>>> */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={edit}
        className="create-workflow-main modal-lg"
      >
        <Modal.Header>
          {/* <div className="modelhead d-flex justify-content-between">
            <p className="create-role-text">Edit Workflow</p>
            <img src={close} alt="" onClick={handleClosed} />
          </div> */}
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Edit Shift Schedule
            </label>
          </Modal.Title>
          <img src={close} alt="" onClick={handleClosed} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="row create-workflow-topbody pb-4">
            <div className="col-lg-6 mt-4">
              <label htmlFor="" className="label-text">
                Branch Name
              </label>
              <select
                class="form-select select-drop-icon"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="Redbytes">Redbytes</option>
                <option value="Probytes">Probytes</option>
              </select>
            </div>
            <div className="col-lg-6 mt-4">
              <label htmlFor="" className="label-text">
                Time Zone
              </label>
              <select
                class="form-select select-drop-icon"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="1">UTC +01:19</option>
                <option value="2">UTC +00:12</option>
              </select>
            </div>
          </div>
          <div className="row create-workflow-topbody pb-4">
            <div className="col-lg-4  mt-4">
              <label htmlFor="" className="label-text">
                Location
              </label>
              <input
                type="text"
                className="date-label-input"
                placeholder="Pune"
              />
            </div>
            <div className="col-lg-4 mt-4">
              <label htmlFor="" className="label-text">
                Latitude
              </label>
              <input type="text" className="date-label-input" />
            </div>
            <div className="col-lg-4 position-relative mt-4">
              <label htmlFor="" className="label-text">
                Longitude
              </label>
              <input type="text" className="date-label-input" />
            </div>
          </div>
          <div className="row create-workflow-topbody pb-4 ">
            <div className="col-lg-6">
              <label htmlFor="" className="label-text">
                In Time
              </label>
              <input
                type="text"
                className="date-label-input"
                placeholder="10:00 am"
              />
            </div>
            <div className="col-lg-6 ">
              <label htmlFor="" className="label-text">
                Out Time
              </label>
              <input
                type="text"
                className="date-label-input"
                placeholder="07:00 pm"
              />
            </div>
          </div>
          <div className="row create-workflow-topbody pb-4 ">
            <div className="col-lg-6 mb-4">
              <label
                htmlFor=""
                className="label-text me-3 d-flex align-items-center  p2-2"
              >
                Break Time
              </label>
              <input
                type="text"
                className="date-label-input"
                placeholder="10:00 am"
              />
            </div>
            <div className="col-lg-6">
              <label
                htmlFor=""
                className="label-text me-3 d-flex align-items-center"
              >
                Grace Time
              </label>
              <input
                type="text"
                className="date-label-input"
                placeholder="07:00 pm"
              />
            </div>
          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="modal-create-btn ">Save</button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* /////////////////////////  model previwe section ///////////////////// */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter "
        centered
        show={priviwe}
        className="create-workflow-main modal-lg"
      >
        <Modal.Header className=" create-workflow-topbody" onClick={priveclose}>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Workflow Details
            </label>
          </Modal.Title>

          <img src={close} alt="" onClick={handleClosed} />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body className=" create-workflow-topbody pri-mainbody">
          <div className="wfd-row wfd-row2 ">
            <div className="workflow-details-col">
              <label className="wfd-label mb-2">Workflow Name</label>
              <p>Testplan Workflow</p>
            </div>
            <div className="workflow-details-col">
              <label className="wfd-label mb-2">Is Retrospective ? </label>
              <p>No</p>
            </div>
            <div className="workflow-details-col">
              <label className="wfd-label mb-2">Is Sequential ?</label>
              <p>Yes</p>
            </div>
            <div className="workflow-details-col">
              <label className="wfd-label mb-2">Start Date</label>
              <p>2022-09-08</p>
            </div>
            <div className="workflow-details-col">
              <label className="wfd-label mb-2">End Date</label>
              <p>2023-09-08</p>
            </div>
          </div>
          <hr />
          <div className="row accor-main">
            <label className="col-lg-12 mt-2 mb-1">Steps</label>
          </div>
          {/* <div class="accordion " id="accordionPanelsStayOpenExample">
            <div class="accordion-item pb-3">
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  Step1
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                class="accordion-collapse collapse show acco-background"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div className="secondsft">
                  <div className="wfd-row ms-3 me-3 ps-2 pe-2  ">
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Step Name</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Role</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Disply/Role Type</label>
                      <p>Single Column</p>
                    </div>
                  </div>
                  <div className="wfd-row ms-3 me-3 ps-2">
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Description</label>
                      <p>
                        This is the description of the step 1 for planning
                        purpose
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-3 ms-3 mb-3">
                  <button className="cancel-btn">Cancel</button>
                  <button className="save-btn ">Add</button>
                </div>
                <div className="secondsft">
                  <div className="wfd-row ms-3 me-3 ps-2 pe-2 ">
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Field Name</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Description</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Field Type</label>
                      <p>Single Column</p>
                    </div>
                  </div>{" "}
                  <div className="wfd-row ms-3 me-3 ps-2 pe-2  ">
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Validation</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">
                        Validation if any
                      </label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Priority Order</label>
                      <p>Single Column</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item pb-3">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOne-collapseTwo"
                >
                  Step1
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOne-headingTwo"
              >
                <div className="secondsft">
                  <div className="wfd-row ms-3 me-3 ps-2 pe-2  ">
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Step Name</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Role</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Disply/Role Type</label>
                      <p>Single Column</p>
                    </div>
                  </div>
                  <div className="wfd-row ms-3 me-3 ps-2">
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Description</label>
                      <p>
                        This is the description of the step 1 for planning
                        purpose
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-3 ms-3 mb-3">
                  <button className="cancel-btn">Cancel</button>
                  <button className="save-btn ">Add</button>
                </div>
                <div className="secondsft">
                  <div className="wfd-row ms-3 me-3 ps-2 pe-2 ">
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Field Name</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Description</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Field Type</label>
                      <p>Single Column</p>
                    </div>
                  </div>{" "}
                  <div className="wfd-row ms-3 me-3 ps-2 pe-2  ">
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Validation</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">
                        Validation if any
                      </label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Priority Order</label>
                      <p>Single Column</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item pb-3">
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Step1
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                class="accordion-collapse collapse show acco-background"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div className="secondsft">
                  <div className="wfd-row ms-3 me-3 ps-2 pe-2  ">
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Step Name</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Role</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Disply/Role Type</label>
                      <p>Single Column</p>
                    </div>
                  </div>
                  <div className="wfd-row ms-3 me-3 ps-2">
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Description</label>
                      <p>
                        This is the description of the step 1 for planning
                        purpose
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-3 ms-3 mb-3">
                  <button className="cancel-btn">Cancel</button>
                  <button className="save-btn ">Add</button>
                </div>
                <div className="secondsft">
                  <div className="wfd-row ms-3 me-3 ps-2 pe-2 ">
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Field Name</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Description</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Field Type</label>
                      <p>Single Column</p>
                    </div>
                  </div>{" "}
                  <div className="wfd-row ms-3 me-3 ps-2 pe-2  ">
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Validation</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">
                        Validation if any
                      </label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="workflow-details-col">
                      <label className="wfd-label mb-2">Priority Order</label>
                      <p>Single Column</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="steps-main-background">
            <div className="steps-modal-wrapper">
              <ul>
                <li>Step 1</li>
              </ul>
              <div>
                <img
                  src={dropicon}
                  alt=""
                  className={stepDrop ? "" : "circle-deropdown"}
                  onClick={() => {
                    setStepDrop(!stepDrop);
                    setStepDrop1(false);
                    setStepDrop2(false);
                  }}
                />
              </div>
            </div>
            {/* drop steps */}
            {stepDrop && (
              <div>
                <div className="step-1-card mt-2">
                  <div className="row">
                    <div className="col-lg-4">
                      <label htmlFor="">Step Name</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Role</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Display Role Type</label>
                      <p>Single Column</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Description</label>
                      <p>
                        This is the description of the step 1 for planning
                        purpose
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button className="field_btn">Fields</button>
                  <button className="action_btn">Action</button>
                </div>
                <div className="step-1-card mt-3 ">
                  <div className="row">
                    <div className="col-lg-4">
                      <label htmlFor="">Field Name</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Description</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Field Type</label>
                      <p>Single Column</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Validation</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Validation if any</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Priority Order</label>
                      <p>Single Column</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="steps-main-background">
            <div className="steps-modal-wrapper">
              <ul>
                <li>Step 2</li>
              </ul>
              <div>
                <img
                  src={dropicon}
                  alt=""
                  className={stepDrop1 ? "" : "circle-deropdown"}
                  onClick={() => {
                    setStepDrop1(!stepDrop1);
                    setStepDrop2(false);
                    setStepDrop(false);
                  }}
                />
              </div>
            </div>
            {/* drop steps */}
            {stepDrop1 && (
              <div>
                <div className="step-1-card mt-2">
                  <div className="row">
                    <div className="col-lg-4">
                      <label htmlFor="">Step Name</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Role</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Display Role Type</label>
                      <p>Single Column</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Description</label>
                      <p>
                        This is the description of the step 1 for planning
                        purpose
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button className="field_btn">Fields</button>
                  <button className="action_btn">Action</button>
                </div>
                <div className="step-1-card mt-3 ">
                  <div className="row">
                    <div className="col-lg-4">
                      <label htmlFor="">Field Name</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Description</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Field Type</label>
                      <p>Single Column</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Validation</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Validation if any</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Priority Order</label>
                      <p>Single Column</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="steps-main-background">
            <div className="steps-modal-wrapper">
              <ul>
                <li>Step 3</li>
              </ul>
              <div>
                <img
                  src={dropicon}
                  alt=""
                  className={stepDrop2 ? "" : "circle-deropdown"}
                  onClick={() => {
                    setStepDrop2(!stepDrop2);
                    setStepDrop1(false);
                    setStepDrop(false);
                  }}
                />
              </div>
            </div>
            {/* drop steps */}
            {stepDrop2 && (
              <div>
                <div className="step-1-card mt-2">
                  <div className="row">
                    <div className="col-lg-4">
                      <label htmlFor="">Step Name</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Role</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Display Role Type</label>
                      <p>Single Column</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Description</label>
                      <p>
                        This is the description of the step 1 for planning
                        purpose
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button className="field_btn">Fields</button>
                  <button className="action_btn">Action</button>
                </div>
                <div className="step-1-card mt-3 ">
                  <div className="row">
                    <div className="col-lg-4">
                      <label htmlFor="">Field Name</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Description</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Field Type</label>
                      <p>Single Column</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Validation</label>
                      <p>Preparing Planning Sheet</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Validation if any</label>
                      <p>Candidate, Consultant, HR</p>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="">Priority Order</label>
                      <p>Single Column</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer>
          <div>
            <button className="modal-cancel-btn" onClick={priveclose}>
              Cancel
            </button>
            <button className="modal-create-btn ">Save</button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShiftManagment;
