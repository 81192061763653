import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import { Button, Modal } from "react-bootstrap";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";
import { useNavigate } from "react-router-dom";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import ReactPaginate from "react-paginate";
import { formatDate, } from "../../common";


const ServiceRequest = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  // show button

  // show button end

  const [modal, setModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});

  const handleClose = () => setModal(false);
  const handleShow = (details) => {
    setSelectedDetails(details);
    setModal(true);
  };

  const crmServiceRequestData = [
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "10-Jun-2024",
      message: "Do you have any openings sir",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "9-Jun-2024",
      message: "Hi, Redbytes UK Team",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "8-Jun-2024",
      message: "Hi",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "7-Jun-2024",
      message: "Hi, Edsys Team",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "6-Jun-2024",
      message: "Hello",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "5-Jun-2024",
      message: "Hi, Probytes Team",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "4-Jun-2024",
      message: "Hi, Probytes Team",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "3-Jun-2024",
      message: "Hi",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "2-Jun-2024",
      message: "Hi, Redbytes UK Team",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "1-Jun-2024",
      message: "Hello",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "31-May-2024",
      message: "Hello",
    },
  ];

  const [userList, setUserList] = useState([])
  const [filterSerchName, setFilterSerchName] = useState([])
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [previousPageUrl, setPreviousPageUrl] = useState(null);
  const [totalPages, setTotalPages] = useState(0);

  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();


  const startIndex = (currentPage - 1) * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const filteredDataForCurrentPage = filterSerchName?.slice(0, usersPerPage);

  console.log("page count2>>", filteredDataForCurrentPage)

  const navigetAgentDedails = (id) => {
    navigate(`/UserDetailsForm/${id}`);
  }


  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const getUserListData = () => {
    setLoading(true)
    simpleGetCallWithToken(ApiConfig.GET_SERVICE_REQUEST + `?page=${currentPage}`)
      .then((res) => {
        console.log("Call List Data>>>", res)
        setUserList(res?.contacts)
        // setNextPageUrl(res?.next); // Set next page URL
        // setPreviousPageUrl(res?.previous); // Set previous page URL
        setTotalPages(Math.ceil(res?.total_pages / usersPerPage));
        setLoading(false)

      })
      .catch((err) => {
        setLoading(false)

        console.log("call List Data>>>", err)
      })
  }


  useEffect(() => {
    getUserListData()
    console.log("userData>>>>", userList)
  }, [currentPage])

  useEffect(() => {
    // Otherwise, use location_list

    const filteredData = userList?.filter((item) =>
      item?.phone_number?.toLowerCase().includes(searchValue.toLowerCase())
    );

    console.log("Filtered Data>>", filteredData);
    setFilterSerchName(filteredData); // Set the filtered data in the state
    // setTotalPages(Math.ceil(filteredData.length / usersPerPage))

  }, [userList, searchValue,]);


  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Service Request</h6>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text"
                placeholder="Search here"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setCurrentPage(currentPage);
                }}
              />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setUsersPerPage(parseInt(e.target.value, 10));
                  setCurrentPage(currentPage);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25" selected>
                  25
                </option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            {
              permissions && permissions['View Service Request Export'] &&

              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            }
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">MOBILE NUMBER</th>
                <th scope="col">DATE</th>
                <th scope="col">MESSAGE</th>
                <th scope="col">VIEW DETAILS</th>
              </tr>
            </thead>
            <tbody>
              {filteredDataForCurrentPage.map((data, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{data.phone_number}</td>
                  <td>{formatDate(data?.created_at)}</td>
                  <td>{data.message}</td>
                  <td>
                    <button
                      className="custoBtnForCrmCall"
                      onClick={() => handleShow(data)}
                    >
                      Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className="row mt-7"
            style={{
              position: "absolute",
              bottom: "5px",
              right: "25px",
            }}
          >
            <div className="col-lg-12">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={totalPages} // Use totalPages for correct page count
                onPageChange={pageChangeHandler}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                forcePage={currentPage - 1} // Adjusted for zero-indexed pages
              />
            </div>
          </div>

          <Modal show={modal} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title className="SerReqModalTitle">
                Service Request Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="SerReqModalBody">
              <p className="mb-2">
                Phone Number: {selectedDetails.phone_number}
              </p>
              <p className="mb-2">Contacted on: {formatDate(selectedDetails?.created_at)}</p>
              {/* {displayTime(selectedDetails?.created_at)} */}
              <p className="mb-2">Message: {selectedDetails.message}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ServiceRequest;
