import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import BookIcon from "../../assets/images/ic_excel.svg";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import "../../assets/css/crmphonebook/Agent.scss";
import { Form, Tab, Tabs } from "react-bootstrap";
import Pagination from "../../sharedComponent/Pagination";
import NoData from "../../sharedComponent/NoData";
import { useParams } from "react-router-dom";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import { formatDate } from "../../common";


const tableaudio = {
  width: "120%",
  height: "50px",
  borderRadius: "5px",
  // backgroundColor: "#f0f0f0", 
  padding: "5px",
};

export default function CallHistory() {
  const [selectedRows, setSelectedRows] = useState(10);
    const [detailsdata,setDetailsData] = useState([])
    const [loading,setLoding] = useState(false)
      const [searchValue, setSearchValue] = useState("");
      const [usersPerPage, setUsersPerPage] = useState(25);
        const [filterSerchName, setFilterSerchName] = useState([])
        const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();
  console.log("agent Id>>>>>",id)

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
  };


  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const {} = useContext(AppContext);
  const [key, setKey] = useState("all");

  const getAgentdetails = () => {
    setLoding(true)
    const url = id
    simpleGetCallWithToken(ApiConfig.GET_CALL_HISTRORY + `${url}/`)
      .then((response) => {
        console.log("useDetails Data>>>>", response)
        setDetailsData(response?.call_history)
        setLoding(false)
      })
      .catch((err) => {
        console.log("adent Details Error>>>", err)
        setLoding(false)
      })
  }

  useEffect(()=>{
    getAgentdetails()
  },[])

    useEffect(() => {
      // Otherwise, use location_list
  
      const filteredData = detailsdata?.filter((item) =>
        item?.phone_no?.toLowerCase().includes(searchValue.toLowerCase())
      );
  
      console.log("Filtered Data>>", filteredData);
      setFilterSerchName(filteredData); 
      // Set the filtered data in the state
      // setTotalPages(Math.ceil(filteredData.length / usersPerPage))
  
    }, [detailsdata, searchValue,]);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="main-chat">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Non Call History</h6>
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb">
                  <div className="role-table-header-main">
                    <div className="search-input-wrapper mt-0 cus-mb">
                      <input type="text"
                        placeholder="Search here"
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          setCurrentPage(currentPage);
                        }}
                      />
        
                      {Dark === "lightMode" ? (
                        <img src={searchicon} alt="" />
                      ) : (
                        <img src={ic_search_dark} alt="" />
                      )}
                    </div>
                    <div className="entries-wrapper mt-0 cus-mb">
                      <p>Entries per page</p>
                      <select
                        className="form-select select-drop-icon"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setUsersPerPage(parseInt(e.target.value, 10)); // Update users per page
                          setCurrentPage(currentPage);
                        }}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25" selected>
                          25
                        </option>
                      </select>
                    </div>
                  </div>
                  {/* {
                    permissions && permissions['View Calls Export'] && */}
        
                    <div className="d-flex justify-content-between mainIcons">
        
                      <div className="booksIcon" style={{ textAlign: "center" }}>
                        <img src={BookIcon} className="innerIconsBtn" alt="" />
                      </div>
        
                    </div>
                  {/* } */}
                </div>
          <Form noValidate id="crm-call-form">
            <div id="tabsBelow" className="mt-4">
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                // className="mb-0"
                activeKey={key}
                // onSelect={(k) => setKey(k)}
                onSelect={(k) => {
                  setKey(k);
                  if (k === "inboundCalls") {
                    setCurrentPage(1);  
                  } else if (k === "outboundCalls") {
                    setCurrentPage(1);  
                  }
                }}
              >
                <Tab eventKey="all" title="All">
                  <div
                    className="table-wrapper"
                    id="scrol-tblNtable-wrapper-af"
                  >
                    <table>
                      <thead>
                        <tr className="firstTabelRow">
                          <th scope="col">Mobile Number</th>
                          <th scope="col">Call Time</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                      {loading ? (<>
                        <tr>
                          <td colSpan="9" className="text-center">
                            <Loader />
                          </td>
                        </tr>
                      </>) : (
                        filterSerchName?.length > 0 ? (
                          filterSerchName?.map((data, index) => (
                            <tr key={index} className="table-row-custom">
                              <td>{data?.phone_no}</td>
                              <td>{data?.call_start_time}</td>
                              <td>{data?.call_status}</td>
                            </tr>
                          ))
                        ) : (
                          <tr className="ErrorDataNotFound">
                            <td colSpan={9}>
                              <NoData />
                            </td>
                          </tr>
                        )
                      )
                      }
                    </tbody>
                    </table>
                    <Pagination
            data={detailsdata}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={filterSerchName}
          />
                  </div>
                </Tab>
                <Tab eventKey="inboundCalls" title="Inbound Calls">
                  <div
                    className="table-wrapper"
                    id="scrol-tblNtable-wrapper-af"
                  >
                    <table>
                      <thead>
                        <tr className="firstTabelRow">
                          <th scope="col">Name / Phone Number</th>
                          <th scope="col">Type</th>
                          <th scope="col">Call Date</th>
                          <th scope="col">Call Start Time</th>
                          <th scope="col">Call End Time</th>
                          <th scope="col">Agent Name</th>
                          <th scope="col">Call Status</th>
                          <th scope="col">Call Recording</th>
                          <th scope="col">MAKE CALL</th>
                        </tr>
                      </thead>
                      <tbody>
                      {loading ? (<>
                        <tr>
                          <td colSpan="9" className="text-center">
                            <Loader />
                          </td>
                        </tr>
                      </>) : (
                        filterSerchName?.filter(item => item.call_type === "Inbound").length > 0 ? (
                          filterSerchName?.filter(item => item.call_type === "Inbound")?.map((data, index) => (
                            <tr key={index} className="table-row-custom">
                              <td>{data.phone_no}</td>
                              <td>{data.call_type}</td>
                              <td>{formatDate(data.call_date)}</td>
                              <td>{data.call_start_time}</td>
                              <td>{data.call_end_time}</td>
                              <td>{data.agent_name ? data.agent_name : "---"}</td>
                              <td>{data.call_status}</td>
                              <td style={tableaudio}>
                                {data.call_recording_url ? (
                                  <audio controls style={tableaudio}>
                                    <source src={data.call_recording_url} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                  </audio>
                                ) : (
                                  "Not Found"
                                )}
                              </td>
                              <td>
                                <button className="custoBtnForCrmCall">Make Call</button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="ErrorDataNotFound">
                            <td colSpan={9}>
                              <NoData />
                            </td>
                          </tr>
                        )
                      )
                      }
                    </tbody>
                    </table>
                    <Pagination
            data={detailsdata.filter(item => item.call_type === "Inbound")}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={filterSerchName}
          />
                  </div>
                </Tab>
                <Tab eventKey="outboundCalls" title="Outbound Calls">
                  <Tab.Content>
                    <div
                      className="table-wrapper"
                      id="scrol-tblNtable-wrapper-af"
                    >
                      <table>
                        <thead>
                          <tr className="firstTabelRow">
                            <th scope="col">Name / Phone Number</th>
                            <th scope="col">Type</th>
                            <th scope="col">Call Date</th>
                            <th scope="col">Call Start Time</th>
                            <th scope="col">Call End Time</th>
                            <th scope="col">Agent Name</th>
                            <th scope="col">Call Status</th>
                            <th scope="col">Call Recording</th>
                            <th scope="col">MAKE CALL</th>
                          </tr>
                        </thead>
                        <tbody>
                        {

                          loading ? (<>
                            <tr>
                              <td colSpan="9" className="text-center">
                                <Loader />
                              </td>
                            </tr>
                          </>) : (filterSerchName?.filter(item => item.call_type === "Outbound").length > 0 ? (
                            filterSerchName?.filter(item => item.call_type === "Outbound")?.map((data, index) => (
                              <tr key={index} className="table-row-custom">
                                <td>{data.phone_no}</td>
                                <td>{data.call_type}</td>
                                <td>{formatDate(data.call_date)}</td>
                                <td>{data.call_start_time}</td>
                                <td>{data.call_end_time}</td>
                                <td>{data.agent_name ? data.agent_name : "---"}</td>
                                <td>{data.call_status}</td>
                                <td style={tableaudio}>
                                  {data.call_recording_url ? (
                                    <audio controls style={tableaudio}>
                                      <source src={data.call_recording_url} type="audio/mpeg" />
                                      Your browser does not support the audio element.
                                    </audio>
                                  ) : (
                                    "Not Found"
                                  )}
                                </td>
                                <td>
                                  <button className="custoBtnForCrmCall">Make Call</button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="ErrorDataNotFound">
                              <td colSpan={9}>
                                <NoData />
                              </td>
                            </tr>
                          )
                          )
                        }
                      </tbody>
                      </table>
                      <Pagination
            data={detailsdata.filter(item => item.call_type === "Outbound")}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={filterSerchName}
          />
                    </div>
                  </Tab.Content>
                </Tab>
              </Tabs>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
