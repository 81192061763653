import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";
import { PostCallWithErrorResponse, simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import ReactPaginate from "react-paginate";
import { formatDate } from "../../common";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "../TostMsg";

const ZeroCallList = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  // show button
  const [userList, setUserList] = useState([])
  const [filterSerchName, setFilterSerchName] = useState([])

  const [selectedRows, setSelectedRows] = useState(10);
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [previousPageUrl, setPreviousPageUrl] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [show,setShow] = useState(false)

  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
 let makeCallData = useRef()
  let userMobileNum=localStorage.getItem("Mobile")

  const startIndex = (currentPage - 1) * usersPerPage; // Adjust to zero-indexed pages
  const endIndex = startIndex + parseInt(usersPerPage);

  const filteredDataForCurrentPage = filterSerchName?.slice(0, usersPerPage);

  console.log("page count2>>", filteredDataForCurrentPage)

  const navigetAgentDedails = (id) => {
    navigate(`/UserDetailsForm/${id}`);
  }


  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleMakeCall=(item)=>{
    makeCallData.current = item
    console.log("makeCallData>>>>",makeCallData.current)
    setShow(true)
  }

  const handleClose=()=>{
    setShow(false)
  }


  const onMakeCall = ()=>{

    const body={
      user_no:makeCallData.current?.phone_number,
      agent_no:`+91${userMobileNum}`
    }
    console.log("make Call Body>>>",body.user_no)
    PostCallWithErrorResponse(ApiConfig.MAKE_CALL,JSON.stringify(body))
    .then((res)=>{
      if(res.json.status === 200){
        ToastMsg("success",res.json?.message)
        navigate("/CRMCalls")
        handleClose()
      }else{
        ToastMsg("error",res.json?.message)
        handleClose()

      }
      console.log("makeCall res>>>>",res)
    })
    .catch((err)=>{
      console.log("makeCall err>>>>",err)
    })

  }


  const getUserListData = () => {
    setLoading(true)
    simpleGetCallWithToken(ApiConfig.GET_ZERO_CALL_DATA + `?page=${currentPage}`)
      .then((res) => {
        console.log("Call List Data>>>", res)
        setUserList(res?.results?.zero_call_list)
        setNextPageUrl(res?.next); // Set next page URL
        setPreviousPageUrl(res?.previous); // Set previous page URL
        setTotalPages(Math.ceil(res?.count / usersPerPage));
        setLoading(false)

      })
      .catch((err) => {
        setLoading(false)

        console.log("call List Data>>>", err)
      })
  }


  useEffect(() => {
    getUserListData()
    console.log("userData>>>>", userList)
  }, [currentPage])

  useEffect(() => {
    // Otherwise, use location_list

    const filteredData = userList?.filter((item) =>
      item?.name?.toLowerCase().includes(searchValue.toLowerCase())
    );

    console.log("Filtered Data>>", filteredData);
    setFilterSerchName(filteredData); // Set the filtered data in the state
    // setTotalPages(Math.ceil(filteredData.length / usersPerPage))

  }, [userList, searchValue,]);



  return (

    <>
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Zero Call List</h6>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text"
                placeholder="Search here"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setCurrentPage(currentPage);
                }}

              />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setUsersPerPage(parseInt(e.target.value, 10)); // Update users per page
                  setCurrentPage(currentPage);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25" selected>
                  25
                </option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            {
              permissions && permissions['View Zero Call List Export'] &&
              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            }
          </div>
        </div>

        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">Sr.No</th>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Email</th>
                <th scope="col">Create Date</th>
                <th scope="col">Gender</th>
                <th scope="col">Location</th>
                <th scope="col">View Details</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <Loader />
                  </td>
                </tr>
              ) : (
                filteredDataForCurrentPage?.length > 0 &&
                filteredDataForCurrentPage?.map((data, index) => (
                  <tr key={index} className="table-row-custom">
                    <td>{index + 1}</td>
                    <td>{data.name}</td>
                    <td>{data.type}</td>
                    <td>
                      <button className="imporButton" onClick={()=>handleMakeCall(data)}>
                        <Link
                        //  to="/CRMCalls"
                          className="details-link">
                          Make Call
                        </Link>
                      </button>
                    </td>
                    <td>{data.email}</td>
                    <td>{formatDate(data.created_at)}</td>
                    <td>
                      {data.gender
                        //  ? <gender controls></gender> : "Not Found"
                      }
                    </td>

                    <td>
                      {data?.location
                        // ? (
                        //   <location controls></location>
                        // ) : (
                        //   "Not Found"
                        // )
                      }
                    </td>
                    <td>
                      <button className="imporButton" onClick={() => navigetAgentDedails(data?.id)}>
                        <Link
                          // to="/UserDetailsForm"
                          className="details-link">
                          View Details
                        </Link>
                      </button>
                    </td>
                  </tr>)
                ))}
            </tbody>
          </table>
          <div
            className="row mt-7"
            style={{
              position: "absolute",
              bottom: "5px",
              right: "25px",
            }}
          >
            <div className="col-lg-12">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={totalPages} // Use totalPages for correct page count
                onPageChange={pageChangeHandler}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                forcePage={currentPage - 1} // Adjusted for zero-indexed pages
              />
            </div>
          </div>

        </div>
      </div>
    </div>

    <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
          Make Call  Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p className="text-center m-3"> Are You Sure, You Want To Make Call ? </p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={onMakeCall}
          >
            Confirm
          </button>
        </div>

      </Modal>
    </>
  );
};

export default ZeroCallList;
